import { Outlet, Link } from "react-router-dom";
import Footer from "./Footer";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useUserSession } from "../hooks/useUserSession";
import { Button, Form, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormOptions } from "../hooks/useFormUtil";
import { parseAxiosError } from "../hooks/CelesteStatsApi";
import {
  AddIcon,
  AdminPanelIcon,
  ClearsDifficultyListIcon,
  ClearsEnjoymentListIcon,
  GoldensDifficultyListIcon,
  GoldensEnjoymentListIcon,
  HomeIcon,
  ModListIcon,
  PublicPlayersIcon,
  SettingsIcon,
  SignInIcon,
  SignOutIcon,
  SignUpIcon,
  TopGoldenListIcon,
  UserIcon,
} from "../components/Icons";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { VerticalRule } from "../components/MiscComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { CURRENT_VERSION, SITE_NAME_LONG } from "../util/Defines";
import { useLocalStorage } from "../hooks/useStorage";
import { useEffect } from "react";

export default function Layout() {
  const userSession = useUserSession(null);
  const queryClient = useQueryClient();

  const currentVersion = CURRENT_VERSION;
  const [lastSeenVersion, setLastSeenVersion] = useLocalStorage(
    "lastSeenVersion",
    CURRENT_VERSION.asString()
  );
  useEffect(() => {
    if (lastSeenVersion === currentVersion.asString()) return;
    toast.info(
      <>
        <strong>Celeste Stats</strong> has been updated to <strong>{currentVersion.asString()}!</strong>
        <br />
        <a href="/">View Changelog</a>
      </>,
      { autoClose: 10000 }
    );
    setLastSeenVersion(currentVersion.asString());
  }, []);

  function handleClickLogout() {
    userSession.logout((data) => {
      queryClient.invalidateQueries(["userInfo"]);
      console.log("Logout successful");
    });
  }

  return (
    <>
      <Navbar
        expand="lg"
        style={{
          borderBottom: "1px solid lightgray",
          position: "sticky",
          top: "0",
          zIndex: "100",
          backgroundColor: "white",
        }}
      >
        <Container>
          <Navbar.Brand href="/">{SITE_NAME_LONG}</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">
                <HomeIcon className="me-2" />
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/players">
                <PublicPlayersIcon className="me-2" />
                All Players
              </Nav.Link>
              {userSession.isLoggedIn && <VerticalRule className="mx-2" />}
              {userSession.isLoggedIn && (
                <Nav.Link as={Link} to={"/player/" + userSession.user.name}>
                  <ModListIcon className="me-2" />
                  My Mod List
                </Nav.Link>
              )}
              {userSession.isLoggedIn && (
                <Nav.Link as={Link} to={"/add-map"}>
                  <AddIcon className="me-2" />
                  Add Map
                </Nav.Link>
              )}
              {userSession.isLoggedIn && false && (
                <NavDropdown title="My Lists">
                  <NavDropdown.Item as={Link} to={"/player/" + userSession.user.name + "/top-golden-list"}>
                    <TopGoldenListIcon className="me-2" />
                    Top Golden List
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    as={Link}
                    to={"/player/" + userSession.user.name + "/list/clears/enjoyment"}
                  >
                    <ClearsEnjoymentListIcon className="me-2" />
                    Clear Enjoyment
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={"/player/" + userSession.user.name + "/list/clears/difficulty"}
                  >
                    <ClearsDifficultyListIcon className="me-2" />
                    Clear Difficulty
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    as={Link}
                    to={"/player/" + userSession.user.name + "/list/goldens/enjoyment"}
                  >
                    <GoldensEnjoymentListIcon className="me-2" />
                    Golden Enjoyment
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={"/player/" + userSession.user.name + "/list/goldens/difficulty"}
                  >
                    <GoldensDifficultyListIcon className="me-2" />
                    Golden Difficulty
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            {!userSession.isLoggedIn && (
              <>
                <NavDropdown
                  title={
                    <>
                      <SignInIcon className="me-2" />
                      Login
                    </>
                  }
                  id="login-nav-dropdown"
                  align="end"
                  className="d-none d-lg-flex"
                >
                  <LoginForm userSession={userSession} />
                </NavDropdown>
                <Nav.Link as={Link} to="/login" className="d-lg-none">
                  <SignInIcon className="me-2" />
                  Login
                </Nav.Link>
              </>
            )}
            {userSession.isLoggedIn && (
              <NavDropdown title={"Signed in as: " + userSession.user.name} id="user-nav-dropdown">
                <NavDropdown.Item onClick={handleClickLogout}>
                  <SignOutIcon className="me-2" />
                  Logout
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/settings">
                  <SettingsIcon className="me-2" />
                  Settings
                </NavDropdown.Item>
                <NavDropdown.Item disabled>
                  Version: <code>{currentVersion.asString()}</code>
                </NavDropdown.Item>
                {userSession.user.is_admin === 1 && (
                  <>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={Link} to="/admin">
                      <AdminPanelIcon className="me-2" />
                      Admin Panel
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/test">
                      <FontAwesomeIcon icon={faQuestion} className="me-2" />
                      Test Page
                    </NavDropdown.Item>
                  </>
                )}
              </NavDropdown>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div id="main-outlet" className="py-2">
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

function LoginForm({ userSession }) {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const onSubmit = handleSubmit((data) => {
    userSession.login(
      data.username,
      data.password,
      (data) => {
        toast.success("Login successful");
      },
      (error) => {
        let message = parseAxiosError(error).message;
        setError("api", { message: message });
      }
    );
  });

  console.log("errors: ", errors);

  return (
    <Form onSubmit={onSubmit} className="mx-3" style={{ minWidth: "250px" }}>
      <Form.Group controlId="login-form-username" className="mb-3 mt-1">
        <Form.Control
          type="text"
          placeholder="Username"
          {...register("username", FormOptions.Username)}
          isInvalid={errors.username !== undefined}
        />
      </Form.Group>
      <Form.Group controlId="login-form-password" className="">
        <Form.Control
          type="password"
          placeholder="Password"
          {...register("password", FormOptions.Password)}
          isInvalid={errors.password !== undefined}
        />
        <Form.Text className="text-muted">
          <Link to="/password-recovery" className="ms-auto text-muted hover-underline">
            forgor password ☠
          </Link>
        </Form.Text>
      </Form.Group>
      {errors.api && <Form.Text className="text-danger">{errors.api.message}</Form.Text>}
      <Stack direction="horizontal" gap={3} className="my-2">
        <Button
          variant="primary"
          type="submit"
          onClick={() => {
            clearErrors("api");
          }}
        >
          <SignInIcon className="me-2" />
          Login
        </Button>
        <Link to="/sign-up" className="ms-auto">
          <SignUpIcon className="me-2" />
          Sign-Up
        </Link>
      </Stack>
    </Form>
  );
}
