import { useParams } from "react-router";
import { useUserSession } from "../../hooks/useUserSession";
import { Button, Container, Form } from "react-bootstrap";
import {
  parseAxiosError,
  submitPasswordRecovery,
  submitPasswordRecoveryVerify,
} from "../../hooks/CelesteStatsApi";
import { toast } from "react-toastify";
import { EditIcon, InfoIcon, PasswordIcon, SuccessIcon, UserIcon } from "../../components/Icons";
import { useState } from "react";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { FormOptions } from "../../hooks/useFormUtil";

const responseGridStyle = {
  display: "grid",
  grid: "1fr 1fr / 1fr",
  gap: "5px",
  placeItems: "center",
  fontSize: "175%",
};

export function PasswordRecoveryPage() {
  const { code } = useParams();
  const userSession = useUserSession();

  const [pastRecovery, setPastRecovery] = useState(false);
  const { mutate: doRecovery } = useMutation({
    mutationFn: (name) => submitPasswordRecovery(name),
    onSuccess: (data) => {
      setPastRecovery(true);
    },
    onError: (error) => {
      toast.error("Error: " + parseAxiosError(error).message);
    },
  });

  const recoveryForm = useForm({
    defaultValues: {
      name: "",
    },
  });
  const errors = recoveryForm.formState.errors;
  const onSubmit = recoveryForm.handleSubmit((data) => {
    doRecovery(data.name);
  });

  if (userSession.isLoggedIn) {
    return (
      <Container>
        <h1>Password Recovery</h1>
        <p className="text-danger">You are already logged in!</p>
      </Container>
    );
  }
  if (code !== undefined) {
    return <PasswordRecoveryVerifyPage code={code} />;
  }
  if (pastRecovery) {
    return (
      <Container>
        <div className="text-success my-3" style={responseGridStyle}>
          <SuccessIcon size="huge" />
          <span style={{ fontSize: "75%", textAlign: "center" }}>
            Please check your email for a recovery link.
            <br />
            (If you don't see it, check your spam folder!)
          </span>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <h1>Password Recovery</h1>
      <Form onSubmit={onSubmit} style={{ maxWidth: "400px" }}>
        <Form.Group className="mb-2">
          <Form.Text className="text-muted">
            Enter your username or email address and you'll receive a recovery link in your email that you can
            use to set a new password.
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>
            <UserIcon className="me-2" />
            Username or Email
          </Form.Label>
          <Form.Control
            type="text"
            {...recoveryForm.register("name", {
              required: "Username or Email is required",
              minLength: { value: 3, message: "Username or Email is too short" },
            })}
            isInvalid={errors.name !== undefined}
          />
          {errors.name && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}
        </Form.Group>
        <Button variant="primary" type="submit" className="mb-2">
          <InfoIcon className="me-2" />
          Request Recovery
        </Button>
      </Form>
    </Container>
  );
}

function PasswordRecoveryVerifyPage({ code }) {
  const [pastVerify, setPastRecovery] = useState(false);
  const { mutate: setNewPassword } = useMutation({
    mutationFn: (newPassword) => submitPasswordRecoveryVerify(code, newPassword),
    onSuccess: (data) => {
      setPastRecovery(true);
    },
    onError: (error) => {
      toast.error("Error: " + parseAxiosError(error).message);
    },
  });

  const verifyForm = useForm({
    defaultValues: {
      name: "",
      password: "",
      confirm_password: "",
    },
  });
  const errors = verifyForm.formState.errors;
  const onSubmit = verifyForm.handleSubmit((data) => {
    setNewPassword(data.password);
  });
  const password = verifyForm.watch("password");
  const validateConfirmPassword = (value) => {
    if (value !== password) {
      return "Passwords do not match";
    }
    return true;
  };

  if (pastVerify) {
    return (
      <Container>
        <div className="text-success my-3" style={responseGridStyle}>
          <SuccessIcon size="huge" />
          <span style={{ fontSize: "75%", textAlign: "center" }}>
            New password set! Click <a href="/login">here</a> to login.
          </span>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <h1>Password Recovery</h1>
      <Form onSubmit={onSubmit} style={{ maxWidth: "400px" }}>
        <Form.Group className="mb-2">
          <Form.Text className="text-muted">
            After setting a new password, you can immediately login with the new password.
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>
            <PasswordIcon className="me-2" />
            Password
          </Form.Label>
          <Form.Control
            type="password"
            {...verifyForm.register("password", { ...FormOptions.Password })}
            isInvalid={errors.password !== undefined}
          />
          {errors.password && <Form.Text className="text-danger">{errors.password.message}</Form.Text>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="confirm_password">
          <Form.Label>
            <PasswordIcon className="me-2" />
            Confirm Password
          </Form.Label>
          <Form.Control
            type="password"
            {...verifyForm.register("confirm_password", {
              ...FormOptions.Password,
              validate: validateConfirmPassword,
            })}
            isInvalid={errors.confirm_password !== undefined}
            autoComplete="off"
          />
          {errors.confirm_password && (
            <Form.Text className="text-danger">{errors.confirm_password.message}</Form.Text>
          )}
        </Form.Group>
        <Button variant="primary" type="submit" className="mb-2">
          <EditIcon className="me-2" />
          Set New Password
        </Button>
      </Form>
    </Container>
  );
}
