import { useRef, useState } from "react";
import "../../css/Player.css";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Stack,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { clearDurationToSeconds, fetchCctCurrentMapData, getMapName } from "./StatsUtil";
import { Typeahead } from "react-bootstrap-typeahead";
import { useUserSession } from "../../hooks/useUserSession";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addPlayerMapData, getPlayerCampaignList, parseAxiosError } from "../../hooks/CelesteStatsApi";
import { toast } from "react-toastify";
import { AddCampaignModal } from "./AddCampaign";
import { AddIcon } from "../../components/Icons";
import { Controller, useForm } from "react-hook-form";
import { FormOptions } from "../../hooks/useFormUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faInfoCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function AddMapDataPage() {
  document.title = "Add Map - Celeste Stats";
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const userSession = useUserSession();

  const openAddCampaignModalRef = useRef(null);
  const onCloseAddCampaign = (cancelled, campaignName) => {
    if (!cancelled) {
      queryClient.invalidateQueries(["campaignList", userSession.user?.name]);
    }
  };

  if (!userSession.isLoggedIn) {
    return (
      <Container>
        <h1 className="text-danger">Login to add maps!</h1>
      </Container>
    );
  }

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col sm={3}></Col>
        <Col sm={6}>
          <Stack direction="horizontal" gap={3}>
            <h2>Add Map</h2>
            <Link to="/bulk-add-maps">Bulk Add Maps</Link>
          </Stack>
          <MapDetailForm
            userSession={userSession}
            hasAddCampaign={true}
            openAddCampaignModalRef={openAddCampaignModalRef}
          />
        </Col>
        <Col sm={3}></Col>
      </Row>
      <AddCampaignModal
        openRef={openAddCampaignModalRef}
        onClose={onCloseAddCampaign}
        userName={userSession.user.name}
      />
    </Container>
  );
}

export function MapDetailForm({ userSession, hasAddCampaign, openAddCampaignModalRef }) {
  const queryClient = useQueryClient();

  const campaignListQuery = useQuery({
    queryKey: ["campaignList", userSession.user?.name],
    queryFn: () => getPlayerCampaignList(userSession.user?.name),
    enabled: userSession.isLoggedIn,
    onError: (error) => {
      toast.error("Error loading campaign list: " + parseAxiosError(error).message);
    },
  });

  const { mutate: addMap } = useMutation({
    mutationFn: (map) => addPlayerMapData(map),
    onSuccess: (data, map) => {
      toast.success("Map '" + getMapName(map) + "' added");

      queryClient.invalidateQueries(["playerMapList", userSession.user?.name]);
      queryClient.invalidateQueries(["playerMapRanks", userSession.user?.name]);
      if (map.campaign_id !== null) {
        queryClient.invalidateQueries(["campaignInfo", map.campaign_id, userSession.user?.name]);
        queryClient.invalidateQueries(["campaignMapList", map.campaign_id]);
        queryClient.invalidateQueries(["campaignMapRanks", map.campaign_id, userSession.user?.name]);
      }
    },
    onError: (error) => {
      toast.error("Error adding map: " + parseAxiosError(error).message);
    },
  });

  const setCctData = (data) => {
    if (data === null) {
      toast.error("No CCT data found");
      return;
    }

    setValue("name", data.chapterName);
    setValue("side_name", data.sideName);
    setValue("cleared", data.completed);
    setValue("clear_duration", data.totalTime);
    setValue("clear_deaths", data.totalDeaths);
    setValue("cleared_on", data.completed ? new Date().toISOString().split("T")[0] : null);
  };
  const onCctError = (error) => {
    toast.error("Error fetching CCT data: " + error.message);
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: { campaign: [] },
  });
  const onSubmit = handleSubmit((data) => {
    const map = {
      ...data,
      campaign_id: data.campaign.length > 0 ? data.campaign[0].id : null,
      clear_duration: clearDurationToSeconds(data.clear_duration),
    };
    addMap(map);
  });
  console.log("errors", errors);

  const campaignOptions = campaignListQuery.data || [];
  campaignOptions.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Form onSubmit={onSubmit}>
      <fieldset disabled={campaignListQuery.isError}>
        <ImportMapDataFromCctButton onSucccess={setCctData} onError={onCctError} />
        <Form.Group className="mb-3">
          <Form.Label>Campaign Name</Form.Label>
          <InputGroup>
            <Controller
              control={control}
              name="campaign"
              render={({ field }) => (
                <Typeahead
                  id="campaign"
                  name="campaign"
                  selected={field.value}
                  labelKey={(o) => o.name}
                  options={campaignOptions}
                  placeholder="Choose a campaign..."
                  onChange={(selection) => field.onChange(selection)}
                />
              )}
            />
            {hasAddCampaign && (
              <Button variant="primary" onClick={() => openAddCampaignModalRef.current()} className="">
                <AddIcon /> Add Campaign
              </Button>
            )}
          </InputGroup>
        </Form.Group>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="mapName">
              <Form.Label>Map Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                {...register("name", { required: true })}
                isInvalid={errors.name !== undefined}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="sideName">
              <Form.Label>Side Name</Form.Label>
              <Form.Control type="text" name="side_name" {...register("side_name")} />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3" controlId="url">
          <Form.Label>URL</Form.Label>
          <Form.Control type="text" name="url" {...register("url")} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="cleared">
          <Form.Check type="checkbox" label="Cleared" name="cleared" {...register("cleared")} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="proofUrl">
          <Form.Label>Proof URL</Form.Label>
          <Form.Control type="text" name="proof_url" {...register("proof_url")} />
        </Form.Group>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="clearDuration">
              <Form.Label>Clear Duration</Form.Label>
              <Form.Control
                type="text"
                placeholder="(hh:)mm:ss"
                name="clear_duration"
                {...register("clear_duration", { ...FormOptions.ClearTime })}
                isInvalid={errors.clear_duration !== undefined}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="clearDeaths">
              <Form.Label>Clear Deaths</Form.Label>
              <Form.Control
                type="text"
                name="clear_deaths"
                {...register("clear_deaths", { ...FormOptions.ClearDeaths })}
                isInvalid={errors.clear_deaths !== undefined}
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3" controlId="clearedOn">
          <Form.Label>Cleared On</Form.Label>
          <Form.Control type="date" name="cleared_on" {...register("cleared_on")} />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="difficulty">
              <Form.Label>Difficulty</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="difficulty"
                  {...register("difficulty", { ...FormOptions.Difficulty })}
                  isInvalid={errors.difficulty !== undefined}
                />
                <InputGroup.Text>/ 100</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="enjoyment">
              <Form.Label>Enjoyment</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="enjoyment"
                  {...register("enjoyment", { ...FormOptions.Enjoyment })}
                  isInvalid={errors.enjoyment !== undefined}
                />
                <InputGroup.Text>/ 10</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3" controlId="notes">
          <Form.Label>Notes</Form.Label>
          <Form.Control as="textarea" rows={3} name="notes" {...register("notes")} />
        </Form.Group>

        <Button variant="primary" type="submit">
          <AddIcon /> Create Map
        </Button>
      </fieldset>
    </Form>
  );
}

export function ImportMapDataFromCctButton({ onSucccess, onError, disabled }) {
  const [isLoading, setIsLoading] = useState(false);

  const fetchCctData = () => {
    setIsLoading(true);
    fetchCctCurrentMapData()
      .then(onSucccess)
      .catch(onError)
      .finally(() => setIsLoading(false));
  };

  return (
    <Stack direction="horizontal" gap={2} className="mb-3">
      <Button
        variant={isLoading ? "primary" : "primary"}
        disabled={isLoading || disabled}
        onClick={fetchCctData}
        size="sm"
      >
        Fetch Last Played Map Data from CCT
        <FontAwesomeIcon className="ms-2" icon={isLoading ? faSpinner : faBolt} spin={isLoading} />
      </Button>
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip>
            Requires CCT version 2.5.1 or newer to be enabled and Celeste to be open right now.
            <br />
            If not currently in a map, the stats from the last played map will be taken.
          </Tooltip>
        }
      >
        <FontAwesomeIcon color="lightgray" icon={faInfoCircle} />
      </OverlayTrigger>
    </Stack>
  );
}
