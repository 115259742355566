import { useMutation, useQuery } from "react-query";
import { useUserSession } from "../../hooks/useUserSession";
import {
  checkUsername,
  parseAxiosError,
  submitRegistration,
  submitVerification,
} from "../../hooks/CelesteStatsApi";
import { useNavigate, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { Alert, Button, Container, Form } from "react-bootstrap";
import {
  CrossIcon,
  EmailIcon,
  PasswordIcon,
  SignUpIcon,
  SuccessIcon,
  UserIcon,
} from "../../components/Icons";
import { FormOptions } from "../../hooks/useFormUtil";
import { useDebouncedCallback } from "use-debounce";
import { toast } from "react-toastify";
import { useState } from "react";
import { Link } from "react-router-dom";
import Prompt from "../../components/Prompt";
import { ErrorContainer, LoadingContainer } from "../../components/MiscComponents";
import useServerSettings from "../../data-hooks/useServerSettings";

const responseGridStyle = {
  display: "grid",
  grid: "1fr 1fr / 1fr",
  gap: "5px",
  placeItems: "center",
  fontSize: "175%",
};

export function RegistrationPage() {
  const userSession = useUserSession();
  const { code } = useParams();

  const [pastRegistration, setPastRegistration] = useState(false);

  const serverSettingsQuery = useServerSettings();

  const { mutate: doRegister } = useMutation({
    mutationFn: (registerData) => submitRegistration(registerData),
    onSuccess: (data) => {
      setPastRegistration(true);
    },
    onError: (error) => {
      toast.error("Registration failed: " + parseAxiosError(error).message);
    },
  });
  const { mutateAsync: checkName } = useMutation({
    mutationFn: (name) => checkUsername(name),
  });

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      password: "",
      confirm_password: "",
      email: "",
      is_listed_publicly: true,
    },
  });
  const onSubmit = handleSubmit((data) => {
    doRegister({
      ...data,
      code: code,
    });
  });
  const password = watch("password");
  const validateConfirmPassword = (value) => {
    return password === value || "Passwords do not match";
  };
  const validateUsername = (value) => {
    nameCheckDebounced(value);
    return errors.name === undefined || errors.name.type !== "validate" || "Name already taken!";
  };
  const nameCheckDebounced = useDebouncedCallback((name) => {
    checkName(name)
      .then((data) => clearErrors("name"))
      .catch((error) => setError("name", { message: parseAxiosError(error).message, type: "validate" }));
  }, 500);

  if (userSession.isLoggedIn) {
    return (
      <Container>
        <h1>Registration</h1>
        <p className="text-danger">You are already logged in!</p>
      </Container>
    );
  }
  if (pastRegistration) {
    return (
      <Container>
        <div className="text-success my-3" style={responseGridStyle}>
          <SuccessIcon size="huge" />
          <span style={{ fontSize: "75%", textAlign: "center" }}>
            Registration successful. Please check your email for a verification link.
            <br />
            (If you don't see it, check your spam folder!)
          </span>
        </div>
      </Container>
    );
  }

  let registrationDisabled = false;
  if (serverSettingsQuery.isSuccess) {
    registrationDisabled = !serverSettingsQuery.data.settings.registration_enabled;
  }

  return (
    <Container>
      {registrationDisabled ? (
        <Alert variant="danger">Registrations are currently disabled. Please come back later!</Alert>
      ) : null}
      <h1>Registration</h1>
      <Form onSubmit={onSubmit} style={{ maxWidth: "400px" }}>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>
            <UserIcon className="me-2" />
            Username
          </Form.Label>
          <Form.Control
            type="text"
            {...register("name", { ...FormOptions.Username, validate: validateUsername })}
            isInvalid={errors.name !== undefined}
            autoComplete="off"
          />
          {errors.name && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>
            <EmailIcon className="me-2" />
            Email
          </Form.Label>
          <Form.Control
            type="email"
            {...register("email", { ...FormOptions.Email })}
            isInvalid={errors.email !== undefined}
            autoComplete="off"
          />
          {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>
            <PasswordIcon className="me-2" />
            Password
          </Form.Label>
          <Form.Control
            type="password"
            {...register("password", { ...FormOptions.Password })}
            isInvalid={errors.password !== undefined}
          />
          {errors.password && <Form.Text className="text-danger">{errors.password.message}</Form.Text>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="confirm_password">
          <Form.Label>
            <PasswordIcon className="me-2" />
            Confirm Password
          </Form.Label>
          <Form.Control
            type="password"
            {...register("confirm_password", {
              ...FormOptions.Password,
              validate: validateConfirmPassword,
            })}
            isInvalid={errors.confirm_password !== undefined}
            autoComplete="off"
          />
          {errors.confirm_password && (
            <Form.Text className="text-danger">{errors.confirm_password.message}</Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="is_listed_publicly">
          <Form.Check
            type="checkbox"
            label="Add me to the public player list"
            {...register("is_listed_publicly")}
          />
          <Form.Text className="text-muted">
            Your list will be visible on the <Link to="/players">All Players</Link> page. You can change this
            at any time!
          </Form.Text>
        </Form.Group>
        <Button variant="primary" type="submit" disabled={!isValid || registrationDisabled} className="mb-2">
          <SignUpIcon className="me-2" />
          Register
        </Button>
      </Form>
      <Prompt when={isDirty} message="If you leave changes will be lost. Continue?" />
    </Container>
  );
}

export function VerifyPage() {
  const { code } = useParams();
  const navigate = useNavigate();

  const verifyQuery = useQuery({
    queryKey: ["verify", code],
    queryFn: () => submitVerification(code),
    enabled: code !== undefined,
    onSuccess: (data) => {
      setTimeout(() => {
        navigate("/player/" + data.name);
      }, 5000);
    },
  });

  if (code === undefined) {
    return <ErrorContainer centered title="Invalid Verification Code" />;
  }

  if (verifyQuery.isLoading) {
    return <LoadingContainer centered title="Verifying account..." />;
  } else if (verifyQuery.isError) {
    let message = parseAxiosError(verifyQuery.error).message;
    return (
      <Container>
        <div className="text-danger my-3" style={responseGridStyle}>
          <CrossIcon size="huge" />
          <span style={{ fontSize: "75%" }}>Verification failed: {message}</span>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div className="text-success my-3" style={responseGridStyle}>
        <SuccessIcon size="huge" />
        <span style={{ fontSize: "75%" }}>
          Account verified. You'll be redirected to your modlist and can now sign-in!
        </span>
      </div>
    </Container>
  );
}
