import {
  faArchway,
  faBaby,
  faBolt,
  faInfoCircle,
  faSpinner,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { forwardRef, useState } from "react";
import { Button, Col, Container, Dropdown, Form, InputGroup, Nav, Row, Stack, Tab } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { SettingsIcon } from "../../components/Icons";
import "../../css/Test.css";
import { faRaspberryPi } from "@fortawesome/free-brands-svg-icons";
import { useQuery } from "react-query";
import {
  axiosRequest,
  getPlayerMapData,
  getPlayerMapGoldenStats,
  parseAxiosError,
} from "../../hooks/CelesteStatsApi";
import { toast } from "react-toastify";
import { Spinner } from "../../components/MiscComponents";
import { GoldenRunsDropdown } from "../../components/GoldenRunsDropdown";
import { TabContainer } from "../../components/TabContainer";
import axios, { all } from "axios";

export function TestPage({}) {
  const navigate = useNavigate();
  const { tab } = useParams();
  const [activeTab, setActiveTab] = useState(tab ?? "cct");
  const handleSelect = (key) => {
    setActiveTab(key);
    if (key === "cct") {
      navigate("/test", { replace: true });
    } else {
      navigate("/test/" + key, { replace: true });
    }
  };

  const tabs = [
    { key: "cct", title: "CCT Testing", icon: faWrench, component: <CctTest /> },
    { key: "dropdown", title: "Dropdown Test", icon: faBolt, component: <DropdownTest /> },
    {
      key: "goldenruns",
      title: "Golden Runs Comp.",
      icon: faRaspberryPi,
      component: <GoldenRunDropdownTest />,
    },
    {
      key: "gbapi-1",
      title: "GB Old",
      icon: faArchway,
      component: <GBApiTest endpoint={"campaign"} />,
    },
    {
      key: "gbapi-2",
      title: "GB New",
      icon: faArchway,
      component: <GBApiTest endpoint={"golden-list"} />,
    },
  ];

  return (
    <Container>
      <TabContainer tabs={tabs} activeTab={activeTab} handleSelect={handleSelect} />
    </Container>
  );
}

function CctTest({}) {
  const [isLoading, setIsLoading] = useState(false);
  const [path, setPath] = useState("");
  const [data, setData] = useState("");

  const doFetch = async () => {
    setIsLoading(true);
    let responseText = "";

    //try network request, might not work
    try {
      const response = await fetch("http://localhost:32270/cct/" + path);
      responseText = await response.text();

      //parse as JSON, then stringify with indentation
      const parsed = JSON.parse(responseText);
      responseText = JSON.stringify(parsed, null, 2);
    } catch (e) {
      responseText = e.message;
    }

    setIsLoading(false);
    setData(responseText);
  };

  return (
    <>
      <h1>CCT Test</h1>
      <Form.Label>Fetch data from the CCT DebugRC API</Form.Label>
      <InputGroup className="mb-3">
        <InputGroup.Text>http://localhost:32270/cct/</InputGroup.Text>
        <Form.Control type="text" value={path} onChange={(e) => setPath(e.target.value)} />
      </InputGroup>

      <Button className="mb-3" onClick={doFetch} variant={isLoading ? "secondary" : "primary"}>
        Fetch
        <FontAwesomeIcon icon={isLoading ? faSpinner : faBolt} spin={isLoading} className="ms-2" />
      </Button>

      <br />

      <h2>Results</h2>
      <pre
        style={{
          whiteSpace: "break-spaces",
          backgroundColor: "lightgray",
          padding: "10px",
          border: "1px solid black",
          borderRadius: "5px",
        }}
      >
        <code>{data}</code>
      </pre>
    </>
  );
}

function DropdownTest() {
  const onSelect = (e) => {
    alert(e.target.innerText);
  };

  const CustomItem1 = ({ children, ...props }) => {
    return (
      <Dropdown.Item {...props}>
        <SettingsIcon className="me-2" />
        {children}
      </Dropdown.Item>
    );
  };

  const CustomItem2 = ({ icon, className, children, ...props }) => {
    return (
      <a className={className + " colored-dropdown-item"} role="button" tabIndex={0} {...props}>
        <FontAwesomeIcon icon={icon} className="me-2" />
        {children}
      </a>
    );
  };

  const CustomBigItem = ({ children, className, ...props }) => {
    const onClick = (e) => {
      e.stopPropagation();
      alert("Big Item clicked");
    };

    return (
      <Stack role="button" className={className + " colored-dropdown-item"} {...props} onClick={onClick}>
        <Stack direction="horizontal" gap={2}>
          <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
          {"Big Item test | "}
          {children}
        </Stack>
        <Stack direction="horizontal" gap={2}>
          <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
          {"Big Item test | "}
          {children}
        </Stack>
      </Stack>
    );
  };

  const CustomDropdownToggle = forwardRef(({ children, ...props }, ref) => (
    <span style={{ border: "1px solid black", borderRadius: "5px" }} ref={ref} {...props}>
      This is a pretty long text
      {children}
    </span>
  ));
  const CustomDropdownToggle2 = ({ children, ...props }) => (
    <span style={{ border: "1px solid black", borderRadius: "5px" }} {...props}>
      This is a pretty long text
      {children}
    </span>
  );

  return (
    <>
      <h1>Dropdown Test</h1>
      <Dropdown>
        <Dropdown.Toggle variant="primary">Dropdown Button</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={onSelect}>Action</Dropdown.Item>
          <Dropdown.Item onClick={onSelect}>Another action</Dropdown.Item>
          <Dropdown.Item as={CustomItem1} onClick={onSelect}>
            Custom Item
          </Dropdown.Item>
          <Dropdown.Item as={CustomItem2} className="primary" icon={faBaby} onClick={onSelect}>
            Primary Item
          </Dropdown.Item>
          <Dropdown.Item as={CustomItem2} className="secondary" icon={faBaby} onClick={onSelect}>
            Secondary Item
          </Dropdown.Item>
          <Dropdown.Item as={CustomItem2} className="success" icon={faBaby} onClick={onSelect}>
            Success Item
          </Dropdown.Item>
          <Dropdown.Item as={CustomItem2} className="info" icon={faBaby} onClick={onSelect}>
            Info Item
          </Dropdown.Item>
          <Dropdown.Item as={CustomItem2} className="warning" icon={faBaby} onClick={onSelect}>
            Warning Item
          </Dropdown.Item>
          <Dropdown.Item as={CustomItem2} className="danger" icon={faBaby} onClick={onSelect}>
            Danger Item
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className="mt-5">
        <Dropdown.Toggle variant="primary">Big Dropdown</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as={CustomBigItem} className="">
            Big Item
          </Dropdown.Item>
          <Dropdown.Item as={CustomBigItem} className="secondary">
            Another Big Item
          </Dropdown.Item>
          <Dropdown.Item as={CustomBigItem} className="danger">
            Big Item with a lot more content
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className="mt-5">
        <Dropdown.Toggle as={CustomDropdownToggle} variant="primary" />
        <Dropdown.Menu>
          <Dropdown.Item as={CustomBigItem} className="">
            Big Item
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

function GoldenRunDropdownTest() {
  const [playerName, setPlayerName] = useState("viddie");
  const [mapId, setMapId] = useState("49");

  const mapDataQuery = useQuery({
    queryKey: ["mapData", playerName, mapId],
    queryFn: () => getPlayerMapData(playerName, mapId),
    onError: (error) => {
      toast.error("Couldn't fetch map data: " + parseAxiosError(error).message);
    },
  });
  const goldenRunsListQuery = useQuery({
    queryKey: ["goldenRunsList", mapId],
    queryFn: () => getPlayerMapGoldenStats(mapId),
  });

  const isLoading = mapDataQuery.isLoading || goldenRunsListQuery.isLoading;
  const isError = mapDataQuery.isError || goldenRunsListQuery.isError;

  const playerNameInput = (
    <Form.Control
      type="text"
      placeholder="Player Name"
      value={playerName}
      onChange={(e) => setPlayerName(e.target.value)}
    />
  );
  const mapIdInput = (
    <Form.Control
      type="text"
      placeholder="Map ID"
      value={mapId}
      onChange={(e) => setMapId(e.target.value)}
      className="mt-2"
    />
  );

  if (isLoading) {
    return (
      <>
        <h2 className="mb-5">Golden Runs Dropdown</h2>
        <hr />
        {playerNameInput}
        {mapIdInput}
        <h2>Loading...</h2>
        <Spinner />
      </>
    );
  } else if (isError) {
    let error = mapDataQuery.isError ? mapDataQuery.error : goldenRunsListQuery.error;
    let parsedError = parseAxiosError(error);
    return (
      <>
        <h2 className="mb-5">Golden Runs Dropdown</h2>
        <hr />
        {playerNameInput}
        {mapIdInput}
        <h2>Error: {parsedError.message}</h2>
      </>
    );
  } else if (goldenRunsListQuery.data.length === 0) {
    return (
      <>
        <h2 className="mb-5">Golden Runs Dropdown</h2>
        <hr />
        {playerNameInput}
        {mapIdInput}
        <h2>No Golden Runs</h2>
      </>
    );
  }

  return (
    <>
      <h2>Golden Runs Dropdown</h2>
      <GoldenRunsDropdown goldens={goldenRunsListQuery.data} />
      <hr />
      {playerNameInput}
      {mapIdInput}
      <h2>Map Data</h2>
      <code>
        <pre>{JSON.stringify(mapDataQuery.data, null, 2)}</pre>
      </code>
      <h2>Golden Runs</h2>
      <code>
        <pre>{JSON.stringify(goldenRunsListQuery.data, null, 2)}</pre>
      </code>
    </>
  );
}

function GBApiTest({ endpoint }) {
  const endpointUrl = "https://stats.vi-home.de/gb_api/api/" + endpoint + ".php";

  const allSubmissionsQuery = useQuery({
    queryKey: ["gb_allSubmissions", endpoint],
    queryFn: () =>
      axios
        .get(endpointUrl, {
          params: {
            //id: 8,
            all: true,
          },
        })
        .then((res) => res.data),
    onError: (error) => {
      toast.error("Failed to get GB API data!");
    },
  });

  if (!allSubmissionsQuery.isSuccess) {
    return (
      <h1>
        GB Api - Loading <Spinner />
      </h1>
    );
  }

  const allSubmissions = allSubmissionsQuery.data;

  let campaignCount = 0,
    mapCount = 0,
    challengeCount = 0,
    submissionCount = 0;
  allSubmissions.forEach((campaign) => {
    campaignCount++;
    campaign.maps.forEach((map) => {
      mapCount++;
      map.challenges.forEach((challenge) => {
        challengeCount++;
        submissionCount += challenge.submissions.length;
      });
    });
  });

  return (
    <div>
      <h1>GB Api</h1>
      <p className="text-muted">
        Testing to fetch all campaigns, maps, challenges and submissions from the GB API.
      </p>
      <p>
        <b>Campaigns: {campaignCount}</b>
        <br />
        <b>Maps: {mapCount}</b>
        <br />
        <b>Challenges: {challengeCount}</b>
        <br />
        <b>Submissions: {submissionCount}</b>
      </p>
      <ul>
        {allSubmissions.map((campaign) => (
          <CampaignEntry key={campaign.id} campaign={campaign} />
        ))}
      </ul>
    </div>
  );
}

function CampaignEntry({ campaign }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const contentClass = campaign.maps.length > 0 ? "gb-api-has-content" : "";
  return (
    <li>
      <span className={contentClass} onClick={toggle}>
        {campaign.name}
      </span>
      {isOpen && (
        <ul>
          {campaign.maps.map((map) => (
            <MapEntry key={map.id} map={map} />
          ))}
        </ul>
      )}
    </li>
  );
}

function MapEntry({ map }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const contentClass = map.challenges.length > 0 ? "gb-api-has-content" : "";
  const sideName = map.side === null ? "" : " [" + map.side + "]";
  return (
    <li>
      <span className={contentClass} onClick={toggle}>
        {map.name}
        {sideName}
      </span>
      {isOpen && (
        <ul>
          {map.challenges.map((challenge) => (
            <ChallengeEntry key={challenge.id} challenge={challenge} />
          ))}
        </ul>
      )}
    </li>
  );
}

function ChallengeEntry({ challenge }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const contentClass = challenge.submissions.length > 0 ? "gb-api-has-content" : "";
  const fcAddition = challenge.requires_fc ? "[FC]" : challenge.has_fc ? "[C/FC]" : "[C]";
  const subtierAddition =
    challenge.difficulty.subtier !== null ? " [" + challenge.difficulty.subtier + "]" : "";

  return (
    <li>
      <span className={contentClass} onClick={toggle}>
        {challenge.objective.name} {fcAddition} - {challenge.difficulty.name}
        {subtierAddition}
      </span>
      {isOpen && (
        <ul>
          {challenge.submissions.map((submission) => (
            <SubmissionEntry key={submission.id} submission={submission} />
          ))}
        </ul>
      )}
    </li>
  );
}

function SubmissionEntry({ submission }) {
  return (
    <li>
      <a href={submission.proof_url}>{submission.player.name}</a>
    </li>
  );
}
