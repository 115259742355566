import { useEffect, useState } from "react";
import "../../css/CelesteStats.css";
import "../../css/Index.css";
import { formatClearDuration, numberWithCommas } from "./StatsUtil";
import { Col, Container, Table, Row, Placeholder, Stack } from "react-bootstrap";
import { DeathsIcon, TimeIcon } from "../../components/Icons";
import { useQuery } from "react-query";
import { getIndexData } from "../../hooks/CelesteStatsApi";
import Markdown from "react-markdown";

import notableFeatures from "../../assets/index-mds/notable-features.md";

export default function IndexPage() {
  document.title = "Celeste Stats";

  return (
    <Container>
      <div id="index-container">
        <NotableFeatures />
        <OverallStatsItem />
        <ChangelogItem />
      </div>
    </Container>
  );
}

function ChangelogItem({}) {
  const [changelog, setChangelog] = useState([]);

  const importAllPosts = (r) => {
    return r.keys().map(r);
  };
  let markdownFiles = importAllPosts(require.context("../../assets/index-mds/changelog", false, /\.md$/))
    .sort((a, b) => {
      //always sort the "dev" changelog to the top. this contains the most recent changes, not in a full release yet.
      if (a.includes("dev")) {
        return 1;
      } else if (b.includes("dev")) {
        return -1;
      } else {
        //perform normal sort
        return a.localeCompare(b);
      }
    })
    .reverse();

  useEffect(() => {
    Promise.all(
      markdownFiles.map((file) =>
        fetch(file)
          .then((response) => response.text())
          .then((text) => text)
      )
    ).then((texts) => setChangelog(texts));
  }, []);

  return (
    <div className="index-item">
      <h2>Changelog</h2>
      {changelog.map((item, index) => {
        if (item === "") return null;
        return (
          <>
            <hr key={index * 10000 + 1} />
            <Markdown key={index} className="changelog-item" components={{ h1: "h4" }}>
              {item}
            </Markdown>
          </>
        );
      })}
    </div>
  );
}

function OverallStatsItem({}) {
  const indexDataQuery = useQuery({
    queryKey: "indexData",
    queryFn: () => getIndexData(),
  });
  let bg = "";
  let styleGrid = {
    gridTemplateColumns: "minmax(180px, auto) minmax(100px, auto)",
    gap: "5px 10px",
    placeContent: "center",
    fontSize: "110%",
  };
  let displayContents = {
    display: "contents",
  };

  return (
    <div className="index-item">
      <h3 className="text-center">Global Stats</h3>
      <div className="d-grid" style={styleGrid}>
        <div style={displayContents}>
          <b>Player Count</b>
          <span>
            {!indexDataQuery.isLoading ? (
              indexDataQuery.data.player_count
            ) : (
              <Placeholder as="span" animation="glow">
                <Placeholder xs={1} bg={bg} />
              </Placeholder>
            )}
          </span>
        </div>
        <hr style={{ gridColumn: "1 / span 2", width: "100%" }} className="my-0" />
        <div style={displayContents}>
          <b>Clear Count</b>
          <span>
            {!indexDataQuery.isLoading ? (
              indexDataQuery.data.clear_count
            ) : (
              <Placeholder as="span" animation="glow">
                <Placeholder xs={4} bg={bg} />
              </Placeholder>
            )}
          </span>
        </div>
        <hr style={{ gridColumn: "1 / span 2", width: "100%" }} className="my-0" />
        <div style={displayContents}>
          <b>Total Clear Time</b>
          <Stack direction="horizontal" gap={1}>
            {!indexDataQuery.isLoading ? (
              formatClearDuration(indexDataQuery.data.total_clear_duration)
            ) : (
              <Placeholder as="span" animation="glow">
                <Placeholder xs={9} bg={bg} />
              </Placeholder>
            )}
            <TimeIcon />
          </Stack>
        </div>
        <hr style={{ gridColumn: "1 / span 2", width: "100%" }} className="my-0" />
        <div style={displayContents}>
          <b>Total Clear Deaths</b>
          <Stack direction="horizontal" gap={1}>
            {!indexDataQuery.isLoading ? (
              numberWithCommas(indexDataQuery.data.total_clear_deaths)
            ) : (
              <Placeholder as="span" animation="glow">
                <Placeholder xs={8} bg={bg} />
              </Placeholder>
            )}
            <DeathsIcon />
          </Stack>
        </div>
        <hr style={{ gridColumn: "1 / span 2", width: "100%" }} className="my-0" />
        <div style={displayContents}>
          <b>Golden Count</b>
          <span>
            {!indexDataQuery.isLoading ? (
              indexDataQuery.data.golden_count
            ) : (
              <Placeholder as="span" animation="glow">
                <Placeholder xs={4} bg={bg} />
              </Placeholder>
            )}
          </span>
        </div>
        <hr style={{ gridColumn: "1 / span 2", width: "100%" }} className="my-0" />
        <div style={displayContents}>
          <b>Total Golden Time</b>
          <Stack direction="horizontal" gap={1}>
            {!indexDataQuery.isLoading ? (
              formatClearDuration(indexDataQuery.data.total_golden_duration)
            ) : (
              <Placeholder as="span" animation="glow">
                <Placeholder xs={10} bg={bg} />
              </Placeholder>
            )}
            <TimeIcon />
          </Stack>
        </div>
        <hr style={{ gridColumn: "1 / span 2", width: "100%" }} className="my-0" />
        <div style={displayContents}>
          <b>Total Golden Deaths</b>
          <Stack direction="horizontal" gap={1}>
            {!indexDataQuery.isLoading ? (
              numberWithCommas(indexDataQuery.data.total_golden_deaths)
            ) : (
              <Placeholder as="span" animation="glow">
                <Placeholder xs={8} bg={bg} />
              </Placeholder>
            )}
            <DeathsIcon />
          </Stack>
        </div>
      </div>
    </div>
  );
}

function NotableFeatures({}) {
  const [markdown, setMarkdown] = useState("");

  const CustomImage = ({ src, alt, title }) => (
    <img className="markdown-image" src={src} alt={alt} title={title} />
  );

  useEffect(() => {
    fetch(notableFeatures)
      .then((response) => response.text())
      .then((text) => setMarkdown(text));
  });

  return (
    <div className="index-item">
      <h3 className="text-center">Cool Features</h3>
      <Markdown components={{ img: CustomImage }}>{markdown}</Markdown>
    </div>
  );
}
