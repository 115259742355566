import "../css/TicTacToe.css";
import React, { useState } from "react";

function evaluateBoard(boardData) {
  //Return 'X' if X won, 'O' if O won, or true if tie, or false if game is not over
  //Check rows
  for (let i = 0; i < 3; i++) {
    if (
      boardData[i][0] === boardData[i][1] &&
      boardData[i][1] === boardData[i][2] &&
      boardData[i][0] !== null
    ) {
      return boardData[i][0];
    }
  }

  //Check columns
  for (let i = 0; i < 3; i++) {
    if (
      boardData[0][i] === boardData[1][i] &&
      boardData[1][i] === boardData[2][i] &&
      boardData[0][i] !== null
    ) {
      return boardData[0][i];
    }
  }

  //Check diagonals
  if (
    boardData[0][0] === boardData[1][1] &&
    boardData[1][1] === boardData[2][2] &&
    boardData[0][0] !== null
  ) {
    return boardData[0][0];
  }
  if (
    boardData[0][2] === boardData[1][1] &&
    boardData[1][1] === boardData[2][0] &&
    boardData[0][2] !== null
  ) {
    return boardData[0][2];
  }

  //Check if board is full
  let boardFull = true;
  for (let i = 0; i < 3; i++) {
    for (let j = 0; j < 3; j++) {
      if (boardData[i][j] === null) {
        boardFull = false;
      }
    }
  }
  return boardFull;
}

function Square({ rowData, squareIndex, onClick, currentTurnSign, gameOver }) {
  let squareText = rowData[squareIndex];
  if (squareText === null) {
    squareText = "";
  }

  function handleClick() {
    onClick(squareIndex);
  }

  function handleMouseEnter(event) {
    if (squareText === "" && !gameOver) {
      event.target.innerHTML = currentTurnSign;
    }
  }
  function handleMouseLeave(event) {
    event.target.innerHTML = squareText;
  }

  return (
    <button
      className="square"
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {squareText}
    </button>
  );
}

function BoardRow({ boardData, clickedSquare, rowIndex, currentTurnSign, gameOver }) {
  function onClick(squareIndex) {
    clickedSquare(rowIndex, squareIndex);
  }

  console.log("Board:", boardData, "Row Index:", rowIndex);
  let rowData = boardData[rowIndex];

  return (
    <div className="board-row">
      <Square
        rowData={rowData}
        squareIndex={0}
        onClick={onClick}
        currentTurnSign={currentTurnSign}
        gameOver={gameOver}
      />
      <Square
        rowData={rowData}
        squareIndex={1}
        onClick={onClick}
        currentTurnSign={currentTurnSign}
        gameOver={gameOver}
      />
      <Square
        rowData={rowData}
        squareIndex={2}
        onClick={onClick}
        currentTurnSign={currentTurnSign}
        gameOver={gameOver}
      />
    </div>
  );
}

function Board() {
  let [boardData, setBoardData] = useState([
    [null, null, null],
    [null, null, null],
    [null, null, null],
  ]);
  let [currentTurnSign, setCurrentTurnSign] = useState("X");

  function clickedSquare(rowIndex, squareIndex) {
    let squareText = boardData[rowIndex][squareIndex];
    if (squareText !== null || evaluateBoard(boardData) !== false) {
      return;
    }

    let newBoardData = boardData.slice();
    newBoardData[rowIndex][squareIndex] = currentTurnSign;
    setBoardData(newBoardData);

    if (currentTurnSign === "X") {
      setCurrentTurnSign("O");
    } else {
      setCurrentTurnSign("X");
    }
  }

  function handleResetBoardClicked() {
    setBoardData([
      [null, null, null],
      [null, null, null],
      [null, null, null],
    ]);
    setCurrentTurnSign("X");
  }

  let gameEval = evaluateBoard(boardData);
  console.log("Game eval:", gameEval);
  let statusMessage =
    gameEval === false
      ? "Next player: " + currentTurnSign
      : gameEval === true
      ? "It's a draw!"
      : "Winner: " + gameEval;
  let gameOver = gameEval !== false ? true : false;

  return (
    <div className="board">
      <BoardRow
        boardData={boardData}
        rowIndex={0}
        clickedSquare={clickedSquare}
        currentTurnSign={currentTurnSign}
        gameOver={gameOver}
      />
      <BoardRow
        boardData={boardData}
        rowIndex={1}
        clickedSquare={clickedSquare}
        currentTurnSign={currentTurnSign}
        gameOver={gameOver}
      />
      <BoardRow
        boardData={boardData}
        rowIndex={2}
        clickedSquare={clickedSquare}
        currentTurnSign={currentTurnSign}
        gameOver={gameOver}
      />
      <div className="status">{statusMessage}</div>
      {gameOver ? <button onClick={handleResetBoardClicked}>Reset Game</button> : null}
    </div>
  );
}

export default function TicTacToe() {
  return (
    <div className="app">
      <h1 className="title">Tic Tac Toe</h1>
      <Board />
    </div>
  );
}
