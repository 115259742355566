import {
  Container,
  Row,
  Col,
  Stack,
  Table,
  Form,
  Button,
  InputGroup,
  FormControl,
  Tab,
  Nav,
} from "react-bootstrap";
import { useState } from "react";
import { faTrash, faSave, faAdd, faCog, faUser, faRegistered } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUserSession } from "../../hooks/useUserSession";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  adminPanelAddUser,
  adminPanelDeleteUser,
  adminPanelGetUserList,
  adminPanelUpdateUser,
  parseAxiosError,
  setServerSettings,
} from "../../hooks/CelesteStatsApi";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { TabContainer } from "../../components/TabContainer";
import { useForm } from "react-hook-form";
import useServerSettings from "../../data-hooks/useServerSettings";
import { AdminPanelIcon, EditIcon, SignUpIcon } from "../../components/Icons";

export default function AdminPanel() {
  const userSession = useUserSession();
  const navigate = useNavigate();
  const { tab } = useParams();
  const [activeTab, setActiveTab] = useState(tab ?? "settings");
  const handleSelect = (key) => {
    setActiveTab(key);
    if (key === "settings") {
      navigate("/admin", { replace: true });
    } else {
      navigate("/admin/" + key, { replace: true });
    }
  };

  if (!userSession.isLoggedIn || !userSession.user.is_admin) {
    return (
      <Container fluid>
        <Row>
          <Col></Col>
          <Col sm={9}>
            <h1 className="text-center text-danger">Not Allowed</h1>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    );
  }

  const userEditTable = <UserEditTable userSession={userSession} />;
  const tabs = [
    {
      key: "settings",
      title: "Settings",
      icon: faCog,
      component: <ServerSettingsPanel />,
    },
    { key: "users", title: "Users", icon: faUser, component: userEditTable },
  ];

  return (
    <Container>
      <TabContainer tabs={tabs} activeTab={activeTab} handleSelect={handleSelect} />
    </Container>
  );
}

function UserEditTable({ userSession }) {
  const queryClient = useQueryClient();
  const [newUserName, setNewUserName] = useState("");
  const userListQuery = useQuery({
    queryKey: ["adminPanelUserList"],
    queryFn: () => adminPanelGetUserList(),
    onSuccess: (data) => {
      setUserList(data);
    },
  });
  const [userList, setUserList] = useState(userListQuery.isSuccess ? userListQuery.data : []);

  const { mutate: addUser } = useMutation({
    mutationFn: (user) => adminPanelAddUser(user),
    onSuccess: (data, user) => {
      toast.success("User '" + user.name + "' added");
      queryClient.invalidateQueries(["adminPanelUserList"]);
      setNewUserName("");
    },
    onError: (error) => {
      toast.error("Error adding user: " + parseAxiosError(error).message);
    },
  });

  function handleAddUser() {
    let newUser = {
      name: newUserName,
      password: "unsafepassword",
      email: "zyx@asdf.xyz",
      is_admin: 0,
      is_verified: 0,
    };

    if (newUserName === "" || newUserName === null) {
      toast.error("Username cannot be empty");
      return;
    }
    if (newUserName.length < 3) {
      toast.error("Username must be at least 3 characters long");
      return;
    }

    addUser(newUser);
  }

  if (userListQuery.isLoading) {
    return (
      <>
        <h4>Loading User List...</h4>
      </>
    );
  }

  return (
    <>
      <h1>User List</h1>
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Username"
          aria-label="Username"
          value={newUserName}
          onChange={(e) => setNewUserName(e.target.value)}
        />
        <Button variant="primary" onClick={handleAddUser}>
          <FontAwesomeIcon icon={faAdd} /> Create User
        </Button>
      </InputGroup>
      <Table striped hover bordered>
        <thead>
          <tr>
            <th className="text-center">ID</th>
            <th className="text-center">Username</th>
            <th className="text-center">New Password</th>
            <th className="text-center">Email</th>
            <th className="text-center">Verified</th>
            <th className="text-center">Verify Code</th>
            <th className="text-center">Is Admin</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {userList.map((user, index) => (
            <UserEditRow key={index} user={user} userSession={userSession} />
          ))}
        </tbody>
      </Table>
    </>
  );
}

function UserEditRow({ user, userSession }) {
  const queryClient = useQueryClient();

  user.password = "";
  let [userData, setUserData] = useState(user);

  const { mutate: saveUser } = useMutation({
    mutationFn: (user) => adminPanelUpdateUser(user),
    onSuccess: (data, user) => {
      toast.success("User '" + user.name + "' saved");
      if (userSession.user.id === user.id) {
        userSession.checkSessionQuery.refetch();
      }
    },
    onError: (error) => {
      toast.error("Error saving user: " + parseAxiosError(error).message);
    },
  });
  const { mutate: deleteUser } = useMutation({
    mutationFn: (userId) => adminPanelDeleteUser(userId),
    onSuccess: (data, userId) => {
      toast.success("User deleted");
      //Set query data
      queryClient.setQueryData(["adminPanelUserList"], (oldData) => {
        return oldData.filter((user) => user.id !== userId);
      });
    },
    onError: (error) => {
      toast.error("Error deleting user: " + parseAxiosError(error).message);
    },
  });

  function handleChange(e) {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  }
  function handleCheckboxChange(e) {
    setUserData({ ...userData, [e.target.name]: e.target.checked });
  }

  function handleSave() {
    saveUser(userData);
  }
  function handleDelete() {
    deleteUser(userData.id);
  }

  let tdStyle = { textAlign: "center", verticalAlign: "middle" };
  let isTempUser = userData.id === null ? true : false;

  return (
    <tr>
      <td style={tdStyle}>{userData.id}</td>
      <td style={tdStyle}>
        <Form>
          <Form.Control id="name" name="name" type="text" value={userData.name} onChange={handleChange} />
        </Form>
      </td>
      <td style={tdStyle}>
        <Form>
          <Form.Control
            id="password"
            name="password"
            type="password"
            value={userData.password}
            onChange={handleChange}
          />
        </Form>
      </td>
      <td style={tdStyle}>
        <Form>
          <Form.Control id="email" name="email" type="text" value={userData.email} onChange={handleChange} />
        </Form>
      </td>
      <td style={tdStyle}>
        <Form>
          <Form.Check
            id="is_verified"
            name="is_verified"
            type="checkbox"
            checked={userData.is_verified}
            onChange={handleCheckboxChange}
            disabled={isTempUser}
          />
        </Form>
      </td>
      <td style={tdStyle}>{userData.verify_code}</td>
      <td style={tdStyle}>
        <Form>
          <Form.Check
            id="is_admin"
            name="is_admin"
            type="checkbox"
            checked={userData.is_admin}
            onChange={handleCheckboxChange}
            disabled={isTempUser}
          />
        </Form>
      </td>
      <td style={tdStyle}>
        <Stack direction="horizontal" gap={3} style={{ justifyContent: "center" }}>
          <Button variant="primary" onClick={handleSave}>
            <FontAwesomeIcon icon={faSave} />
          </Button>
          <Button variant="outline-danger" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Stack>
      </td>
    </tr>
  );
}

function ServerSettingsPanel() {
  const queryClient = useQueryClient();
  const serverSettings = useServerSettings();

  const { mutate: saveServerSettings } = useMutation({
    mutationFn: (settings) => setServerSettings(settings),
    onSuccess: (data) => {
      toast.success("Settings saved!");
      queryClient.invalidateQueries(["serverSettings"]);
    },
    onError: (error) => {
      toast.error("Saving server settings failed: " + parseAxiosError(error).message);
    },
  });

  const form = useForm({
    defaultValues: {
      registration_enabled: serverSettings.data?.settings.registration_enabled,
      maintenance_enabled: serverSettings.data?.settings.maintenance_enabled,
    },
  });
  const onSubmit = form.handleSubmit((data) => {
    saveServerSettings(data);
  });

  return (
    <>
      <h1>Server Settings</h1>
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3" controlId="registrationEnabled">
          <Form.Check
            type="checkbox"
            label={
              <>
                Enable Registration <SignUpIcon />
              </>
            }
            {...form.register("registration_enabled")}
            defaultChecked={serverSettings.data?.settings.registration_enabled}
          />
          <Form.Text className="text-muted">
            If enabled, users can register new accounts. If disabled, only admins can create new accounts.
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="maintenanceEnabled">
          <Form.Check
            type="checkbox"
            label={
              <>
                Maintenance Mode <AdminPanelIcon />
              </>
            }
            {...form.register("maintenance_enabled")}
            defaultChecked={serverSettings.data?.settings.maintenance_enabled}
          />
          <Form.Text className="text-muted">
            If enabled, the site will be read-only and changes can only be made by admins.
          </Form.Text>
        </Form.Group>
        <Button variant="primary" type="submit">
          Save
        </Button>
      </Form>
    </>
  );
}
