import { useEffect, useRef, useState } from "react";
import { Container, Modal, Stack, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  DifficultyDisplay,
  EnjoymentDisplay,
  ErrorContainer,
  LoadingContainer,
} from "../../components/MiscComponents";
import { sortListByProperties, numberWithCommas, getMapNameFromGoldenRun } from "./StatsUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { formatClearDuration } from "./StatsUtil";
import { Popover, Button, OverlayTrigger } from "react-bootstrap";
import { PlayerProfileNavbar } from "../../components/MiscComponents";
import { TimeIcon } from "../../components/Icons";

import { useQuery, useQueryClient } from "react-query";
import { getTopGoldenList, parseAxiosError } from "../../hooks/CelesteStatsApi";
import { PlayerGeneralStats } from "./Player";
import { useModal } from "../../hooks/useModal";
import { QuickEditMap } from "./QuickEditMap";
import { useUserSession } from "../../hooks/useUserSession";

const groups = [
  { name: "Tier 0", tierValues: [20, 19, 18], isRanked: true, baseColor: "#f874c6" },
  { name: "Tier 1", tierValues: [17, 16, 15], isRanked: true, baseColor: "#ff7b67" },
  { name: "Tier 2", tierValues: [14, 13, 12], isRanked: true, baseColor: "#ffc874" },
  { name: "Tier 3", tierValues: [11, 10, 9, 8], isRanked: true, baseColor: "#ffec87" },
  { name: "Tier 4", tierValues: [7], isRanked: false, baseColor: "#c9ff8d" },
  { name: "Tier 5", tierValues: [6], isRanked: false, baseColor: "#93e69e" },
  { name: "Tier 6", tierValues: [5], isRanked: false, baseColor: "#8fdeff" },
  { name: "Tier 7", tierValues: [4], isRanked: false, baseColor: "#96a6ff" },
  { name: "Standard List", tierValues: [3], isRanked: null, baseColor: "white" },
  { name: "No List", tierValues: [2], isRanked: null, baseColor: "white" },
  { name: "Undetermined", tierValues: [1], isRanked: null, baseColor: "white" },
];

export default function TopGoldenListPage() {
  const { playerName } = useParams();
  const userSession = useUserSession();
  const topGoldenListQuery = useQuery({
    queryKey: ["topGoldenList", playerName],
    queryFn: () => getTopGoldenList(playerName),
  });

  const modalRefs = {
    openEditModal: useRef(),
  };

  let title = playerName + "'s Top Golden List";
  document.title = title + " - Celeste Stats";

  if (topGoldenListQuery.isLoading) {
    return (
      <Container fluid>
        <Container>
          <PlayerGeneralStats
            playerName={playerName}
            activePage={"/player/" + playerName + "/top-golden-list"}
          />
        </Container>
        <LoadingContainer fluid centered />
      </Container>
    );
  } else if (topGoldenListQuery.isError) {
    let errorDetails = parseAxiosError(topGoldenListQuery.error);
    return (
      <Container fluid>
        <Container>
          <PlayerGeneralStats
            playerName={playerName}
            activePage={"/player/" + playerName + "/top-golden-list"}
          />
        </Container>
        <ErrorContainer message={errorDetails.message} fluid centered />
      </Container>
    );
  }

  console.log("Top Golden List -> ", topGoldenListQuery.data);

  //Group the objects of the topGoldenList by the following pattern:
  // { tier: 0, maps: [ {map1}, {map2}, ... ] }
  // where each tier can have multiple tier values
  // every map in the maps array has a tier value as property called "tier_sort"
  let groupedTopGoldenList = [];
  for (let group of groups) {
    let goldens = [];
    for (let tierValue of group.tierValues) {
      let mapsWithTierValue = topGoldenListQuery.data.filter((map) => map.tier_sort === tierValue);
      goldens = goldens.concat(mapsWithTierValue);
    }
    groupedTopGoldenList.push({
      tier: group.name,
      goldens: goldens,
      isRanked: group.isRanked,
      baseColor: group.baseColor,
    });
  }

  console.log("Grouped Top Golden List -> ", groupedTopGoldenList);

  return (
    <>
      <Container>
        <PlayerGeneralStats
          playerName={playerName}
          activePage={"/player/" + playerName + "/top-golden-list"}
        />
      </Container>
      <Container fluid className="pe-5">
        <div className="d-flex flex-row me-3">
          {groupedTopGoldenList.map((group) => (
            <TopGoldenListGroup
              key={group.tier}
              group={group}
              openEditModalRef={modalRefs.openEditModal}
              isOwnPage={userSession.isOwnPage(playerName)}
            />
          ))}
        </div>
      </Container>
      <ModalContainer playerName={playerName} modalRefs={modalRefs} />
    </>
  );
}

function TopGoldenListGroup({ group, openEditModalRef, isOwnPage }) {
  const headerStyle = {
    backgroundColor: group.baseColor,
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    borderTop: "1px solid black",
    alignSelf: "flex-start",
  };
  const tableStyle = {
    backgroundColor: group.baseColor + "!important",
    textAlign: "center",
    fontSize: "130%",
  };
  const borderStyle = {
    borderBottom: "2px solid black",
  };

  sortListByProperties(group.goldens, [["tier_sort", "DESC"], ["clear_duration", "DESC"], "map_name"]);

  return (
    <div className="mb-5 d-flex flex-column" style={headerStyle}>
      <TopGoldenListGroupHeader group={group} />
      <table style={tableStyle} className="px-2">
        <thead style={borderStyle}>
          <tr>
            <th style={{ minWidth: "100px", borderRight: "1px solid black" }}>Map</th>
            <th style={{ minWidth: "130px", borderRight: "1px solid black" }}>
              <div className="d-flex justify-content-center">
                <TimeIcon size="medium-small" />
              </div>
            </th>
            <th style={{ minWidth: "80px" }}>Video</th>
          </tr>
        </thead>
        <tbody>
          {group.goldens.map((golden) => (
            <TopGoldenListRow
              key={golden.id}
              golden={golden}
              openEditModalRef={openEditModalRef}
              isOwnPage={isOwnPage}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function TopGoldenListGroupHeader({ group }) {
  const borderStyle = {
    borderBottom: "2px solid black",
  };
  return (
    <div style={borderStyle}>
      <h2 className="mx-5 my-3" style={{ whiteSpace: "nowrap", textAlign: "center" }}>
        {group.isRanked !== null ? (
          <span>
            {group.tier} [{group.isRanked ? "Ranked" : "Unranked"}]
          </span>
        ) : (
          <span>{group.tier}</span>
        )}
      </h2>
    </div>
  );
}

function TopGoldenListRow({ golden, openEditModalRef, isOwnPage }) {
  const openEditModal = () => {
    document.body.click();
    openEditModalRef.current(golden.map_id);
  };

  const rowStyle = {
    backgroundColor: golden.tier_color,
    borderBottom: "1px solid black",
  };
  const cellStyle = {
    borderLeft: "1px solid black",
  };

  let mapName = getMapNameFromGoldenRun(golden);
  if (golden.description !== null) {
    mapName += " (" + golden.description + ")";
  }
  if (golden.is_fc && !golden.is_special) {
    mapName += " [FC]";
  }
  if (!golden.is_fc && golden.is_special) {
    mapName += " [SB]";
  }
  if (golden.is_fc && golden.is_special) {
    mapName += " [FC+SB]";
  }

  let dateString = golden.cleared_on !== null ? new Date(golden.cleared_on).toLocaleDateString() : "-";
  let deathsString = golden.clear_deaths !== null ? numberWithCommas(golden.clear_deaths) : "-";
  let timeString = golden.clear_duration !== null ? formatClearDuration(golden.clear_duration) : "-";

  const NamePopover = (
    <Popover id="basic-popover" style={{ maxWidth: "300px" }}>
      <Popover.Body>
        <div className="d-flex flex-column">
          <div>
            {golden.campaign_name ? (
              <>
                <span className="fw-bold">Campaign: </span>
                <Link to={"/player/" + golden.user_name + "/campaign/" + golden.campaign_id}>
                  {golden.campaign_name}
                </Link>
              </>
            ) : null}
          </div>
          <div>
            <span className="fw-bold">Map: </span>
            <Link to={"/player/" + golden.user_name + "/map/" + golden.map_id}>
              {getMapNameFromGoldenRun(golden)}
            </Link>{" "}
            {isOwnPage && (
              <Link style={{ color: "lightgray" }} onClick={openEditModal}>
                <FontAwesomeIcon icon={faEdit} />
              </Link>
            )}
          </div>
          <div>
            <span className="fw-bold">Cleared on: </span>
            {dateString}
          </div>
          <div>
            <span className="fw-bold">Time: </span>
            {timeString}
          </div>
          <div>
            <span className="fw-bold">Deaths: </span>
            {deathsString}
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  const proofElement =
    golden.proof_url !== null ? (
      <a href={golden.proof_url} style={{ textDecoration: "none" }}>
        ▶
      </a>
    ) : (
      "-"
    );

  return (
    <tr style={rowStyle}>
      <td style={{ whiteSpace: "nowrap", textAlign: "left" }} className="px-2">
        <OverlayTrigger trigger="click" placement="auto" overlay={NamePopover} rootClose>
          <Button className="p-0" variant="link">
            {mapName}
          </Button>
        </OverlayTrigger>
      </td>
      <td style={cellStyle} className="text-left ps-2">
        {formatClearDuration(golden.clear_duration)}
      </td>
      <td style={cellStyle}>{proofElement}</td>
    </tr>
  );
}

function ModalContainer({ playerName, modalRefs }) {
  const queryClient = useQueryClient();
  const editModal = useModal(null, (cancelled, mapData) => {
    queryClient.invalidateQueries(["topGoldenList", playerName]);
    if (cancelled) return;
  });

  modalRefs.openEditModal.current = editModal.open;

  return (
    <>
      <Modal show={editModal.isVisible} onHide={editModal.cancel} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Quick Edit Map</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuickEditMap
            playerName={playerName}
            id={editModal.data}
            onSave={(mapData) => editModal.close(false, mapData)}
            onCancel={editModal.cancel}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
