import { Button, Container, Stack } from "react-bootstrap";
import { useParams } from "react-router";
import { PlayerGeneralStats } from "./Player";
import { useUserSession } from "../../hooks/useUserSession";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteCampaign, getPlayerCampaignList, parseAxiosError } from "../../hooks/CelesteStatsApi";
import { ErrorContainer, LoadingContainer } from "../../components/MiscComponents";
import {
  AddIcon,
  ClearIcon,
  DeathsIcon,
  DeleteIcon,
  GoldberryIcon,
  TextWithIcon,
  TimeIcon,
} from "../../components/Icons";
import { useRef } from "react";
import { AddCampaignModal } from "./AddCampaign";
import { formatClearDuration, numberWithCommas, sortListByProperties } from "./StatsUtil";
import { CustomModal, useModal } from "../../hooks/useModal";
import "../../css/CampaignList.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function CampaignListPage() {
  const { playerName } = useParams();
  const userSession = useUserSession();
  const isOwnPage = userSession.isOwnPage(playerName);

  const campaignListQuery = useQuery({
    queryKey: ["campaignList", playerName],
    queryFn: () => getPlayerCampaignList(playerName),
  });

  if (campaignListQuery.isLoading) {
    return (
      <>
        <Container>
          <PlayerGeneralStats playerName={playerName} activePage={"/player/" + playerName + "/campaigns"} />
        </Container>
        <LoadingContainer title="Campaign List" />
      </>
    );
  } else if (campaignListQuery.isError) {
    let error = parseAxiosError(campaignListQuery.error);
    return (
      <>
        <Container>
          <PlayerGeneralStats playerName={playerName} activePage={"/player/" + playerName + "/campaigns"} />
        </Container>
        <ErrorContainer title="Campaign List" message={error.message} />
      </>
    );
  }

  return (
    <Container>
      <PlayerGeneralStats playerName={playerName} activePage={"/player/" + playerName + "/campaigns"} />
      <CampaignList playerName={playerName} isOwnPage={isOwnPage} campaignList={campaignListQuery.data} />
    </Container>
  );
}

function CampaignList({ playerName, isOwnPage, campaignList }) {
  const openAddCampaignModal = useRef();
  const queryClient = useQueryClient();

  const { mutate: deleteFullCampaign } = useMutation({
    mutationFn: (campaignId) => deleteCampaign(campaignId),
    onSuccess: (data, campaignId) => {
      toast.success("Campaign deleted");
      queryClient.invalidateQueries(["campaignList", playerName]);
    },
  });

  sortListByProperties(campaignList, ["name"]);

  function handleCampaignAddModalClose(cancelled, data) {
    if (!cancelled) {
      queryClient.invalidateQueries(["campaignList", playerName]);
    }
  }

  const deleteModal = useModal(null, (cancelled) => {
    if (!cancelled) {
      //Delete campaign
      console.log("Deleting campaign: ", deleteModal.data);
      deleteFullCampaign(deleteModal.data.id);
    }
  });

  return (
    <>
      <Stack direction="horizontal" className="mb-3">
        <h2 className="mb-0">Campaigns</h2>
        {isOwnPage && (
          <Button variant="primary" onClick={() => openAddCampaignModal.current()} className="ms-auto">
            <AddIcon /> Add Campaign
          </Button>
        )}
      </Stack>
      {campaignList.map((campaign) => (
        <CampaignEntry
          key={campaign.id}
          playerName={playerName}
          campaign={campaign}
          isOwnPage={isOwnPage}
          onDeleteCampaign={deleteModal.open}
        />
      ))}
      <AddCampaignModal
        openRef={openAddCampaignModal}
        userName={playerName}
        onClose={handleCampaignAddModalClose}
      />
      <CustomModal
        modalHook={deleteModal}
        options={{ title: "Delete Campaign", acceptText: "Delete", acceptVariant: "danger" }}
      >
        <p>
          Are you sure you want to delete campaign <b>'{deleteModal.data?.name}'</b> and all of it's{" "}
          <b>'{deleteModal.data?.map_count}'</b> attached map(s)?
        </p>
      </CustomModal>
    </>
  );
}

function CampaignEntry({ playerName, campaign, isOwnPage, onDeleteCampaign }) {
  const totalTimeStr = formatClearDuration(
    (campaign.map_clear_duration ?? 0) + (campaign.golden_clear_duration ?? 0)
  );
  const deathsStr = numberWithCommas((campaign.map_clear_deaths ?? 0) + (campaign.golden_clear_deaths ?? 0));

  return (
    <Link
      to={"/player/" + playerName + "/campaign/" + campaign.id}
      style={{ color: "inherit", textDecoration: "none" }}
    >
      <div className="campaign-list-campaign-info-grid mb-2">
        <h5 style={{ gridArea: "name" }} className="mb-0">
          <u>{campaign.name}</u>
        </h5>
        <div style={{ gridArea: "info1", justifySelf: "start" }}>
          <Stack direction="horizontal" gap={1}>
            <TextWithIcon>
              <ClearIcon />{" "}
              <b>
                {campaign.map_clears}/{campaign.map_count}
              </b>
            </TextWithIcon>
            <TextWithIcon className="ms-2">
              <GoldberryIcon />{" "}
              <b>
                {campaign.golden_clears ?? 0}/{campaign.map_count}
              </b>
            </TextWithIcon>
          </Stack>
        </div>
        <div style={{ gridArea: "info2", justifySelf: "end" }}>
          <Stack direction="horizontal" gap={1}>
            <TextWithIcon className="ms-2">
              <DeathsIcon /> <b>{deathsStr}</b>
            </TextWithIcon>
            <TextWithIcon className="ms-2">
              <TimeIcon /> <b>{totalTimeStr}</b>
            </TextWithIcon>
          </Stack>
        </div>
        {isOwnPage && (
          <Button
            style={{ gridArea: "actions" }}
            variant="outline-danger"
            onClick={(e) => {
              e.preventDefault();
              onDeleteCampaign(campaign);
            }}
            className="ms-auto"
          >
            <DeleteIcon />
          </Button>
        )}
      </div>
    </Link>
  );
}
