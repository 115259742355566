import axios from "axios";
import { asNullableApiValue } from "../util/ApiUtils";

//#region API Data Strcutures
export const DataStructures = {
  MapData: {
    id: { nullable: false },
    campaign_id: {},
    name: { nullable: false },
    side_name: {},
    url: {},
    cleared: { isBool: true },
    clear_duration: { allowZero: true },
    clear_deaths: { allowZero: true },
    cleared_on: {},
    difficulty: { allowZero: true },
    enjoyment: { allowZero: true },
    notes: {},
    proof_url: {},
    sort_major: { allowZero: true },
    sort_minor: { allowZero: true },
    sort_order: { allowZero: true },
  },
  GoldenRun: {
    id: { nullable: false },
    map_id: { nullable: false },
    is_fc: { isBool: true },
    is_special: { isBool: true },
    description: {},
    cleared: { isBool: true },
    clear_duration: { allowZero: true },
    clear_deaths: { allowZero: true },
    cleared_on: {},
    sheet_difficulty_id: {},
    difficulty: { allowZero: true },
    enjoyment: { allowZero: true },
    notes: {},
    proof_url: {},
  },
  CampaignInfo: {
    id: { nullable: false },
    name: { nullable: false },
    url: {},
    sort_major_name: {},
    sort_major_labels: {},
    sort_major_accent_colors: {},
    sort_minor_name: {},
    sort_minor_labels: {},
    sort_minor_accent_colors: {},
  },
  CampaignInfoAdd: {
    name: { nullable: false },
  },
  AdminPanelUser: {
    id: { nullable: false },
    name: { nullable: false },
    password: { nullable: false },
    email: { nullable: false },
    is_verified: { isBool: true },
    verify_code: {},
    is_admin: { isBool: true },
  },
  AdminPanelUserUpdate: {
    id: { nullable: false },
    name: { nullable: false },
    new_password: { propName: "password" },
    email: { nullable: false },
    is_verified: { isBool: true },
    verify_code: {},
    is_admin: { isBool: true },
  },
  UserAccountSettingsUpdate: {
    password: {},
    name: { nullable: false },
    email: { nullable: false },
    new_password: {},
  },
  UserPreferencesUpdate: {
    is_listed_publicly: { isBool: true },
  },
  Registration: {
    name: { nullable: false },
    password: { nullable: false },
    email: { nullable: false },
    is_listed_publicly: { isBool: true },
    code: {},
  },
  ServerSettings: {
    registration_enabled: { isBool: true },
    maintenance_enabled: { isBool: true },
  },
};
//#endregion

//#region Axios Stuff
const BASE_URL = "https://stats.vi-home.de/";
const DEFAULT_AXIOS_OPTIONS = {
  withCredentials: true,
};

export function axiosRequest(url, method, options) {
  return axios
    .request({
      url: BASE_URL + url,
      method: method,
      ...DEFAULT_AXIOS_OPTIONS,
      ...options,
    })
    .then((response) => response.data.data);
}

export function parseAxiosError(axiosError) {
  if (axiosError.response) {
    return {
      code: axiosError.response.data.errorCode,
      message: axiosError.response.data.errorMessage,
    };
  } else {
    return {
      code: axiosError.status,
      message: axiosError.statusText,
    };
  }
}
export function getFirstAxiosError(queries) {
  for (let query of queries) {
    if (query.isError) {
      return parseAxiosError(query.error);
    }
  }
  return null;
}
export function anyLoading(queries) {
  for (let query of queries) {
    if (query.isLoading) {
      return true;
    }
  }
  return false;
}
//#endregion

//#region Index API
export function getIndexData() {
  return axiosRequest("api/index-data", "GET");
}
//#endregion

//#region User Session API
export function loginUser(username, password) {
  const formData = new FormData();
  formData.append("user_name", username);
  formData.append("password", password);

  return axiosRequest("api/login", "POST", {
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
export function logoutUser() {
  return axiosRequest("api/logout", "GET");
}
export function getUserSessionData() {
  return axiosRequest("api/user-session", "GET");
}
export function getUserAccountData() {
  return axiosRequest("api/user/self", "GET");
}
export function updateUserAccountSettings(userData) {
  const formData = makeFormData(userData, DataStructures.UserAccountSettingsUpdate);
  return axiosRequest("api/user/self", "POST", {
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
export function updateUserPreferences(preferences) {
  const formData = makeFormData(preferences, DataStructures.UserPreferencesUpdate);
  return axiosRequest("api/user/self?preferences=1", "POST", {
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
export function deleteUserAccount(userData) {
  return axiosRequest("api/user/self", "DELETE", {
    params: {
      confirm_name: userData.confirm_name,
      password: userData.password,
    },
    headers: { "Content-Type": "multipart/form-data" },
  });
}
//#endregion

//#region Player API
export function getPlayerGeneralInfo(playerName) {
  return axiosRequest("api/player/general-stats", "GET", {
    params: {
      player_name: playerName,
    },
  });
}
export function getPlayerMapRanks(playerName, campaignId = undefined) {
  return axiosRequest("api/player/map-ranks", "GET", {
    params: {
      player_name: playerName,
      campaign_id: campaignId,
    },
  });
}
export function deleteMap(mapId) {
  return axiosRequest("api/player/map-data/delete", "GET", {
    params: {
      id: mapId,
    },
  });
}

export function getPublicPlayersList() {
  return axiosRequest("api/player/public-list", "GET");
}
//#endregion

//#region Map Data / List API
export function getPlayerMapData(playerName, mapId = undefined) {
  return axiosRequest("api/player/map-data/get", "GET", {
    params: {
      player_name: playerName,
      id: mapId,
    },
  });
}
export function getTopGoldenList(playerName) {
  return axiosRequest("api/player/top-golden-list", "GET", {
    params: {
      player_name: playerName,
      standard_list: "include",
    },
  });
}
export function addPlayerMapData(map) {
  const formData = makeFormData(map, DataStructures.MapData);
  return axiosRequest("api/player/map-data/add", "POST", {
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
//#endregion

//#region Quick Edit Map API
export function getPlayerCampaignList(playerName) {
  return axiosRequest("api/player/campaign-list", "GET", {
    params: {
      player_name: playerName,
    },
  });
}

export function updatePlayerMapData(map) {
  const formData = makeFormData(map, DataStructures.MapData);

  return axiosRequest("api/player/map-data/update", "POST", {
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function getPlayerMapGoldenStats(mapId) {
  return axiosRequest("api/player/golden-stats", "GET", {
    params: {
      map_id: mapId,
    },
  });
}

export function getPlayerAllGoldenStats(playerName) {
  return axiosRequest("api/player/golden-stats", "GET", {
    params: {
      player_name: playerName,
    },
  });
}

export function deletePlayerMapGoldenRun(runId) {
  return axiosRequest("api/player/golden-stats", "DELETE", {
    params: {
      id: runId,
    },
  });
}
export function createPlayerMapGoldenRun(mapId) {
  const formData = new FormData();
  formData.append("map_id", mapId);

  return axiosRequest("api/player/golden-stats", "POST", {
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
export function createPlayerMapFullGoldenRun(golden) {
  const formData = makeFormData(golden, DataStructures.GoldenRun);
  return axiosRequest("api/player/golden-stats", "POST", {
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
export function updatePlayerMapGoldenRun(run) {
  const formData = makeFormData(run, DataStructures.GoldenRun);

  return axiosRequest("api/player/golden-stats", "POST", {
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
//#endregion

//#region Campaign API
export function getCampaignInfo(campaignId, playerName) {
  return axiosRequest("api/campaign/info", "GET", {
    params: {
      id: campaignId,
      player_name: playerName,
    },
  });
}
export function addCampaign(campaignInfo) {
  const formData = makeFormData(campaignInfo, DataStructures.CampaignInfoAdd);
  return axiosRequest("api/campaign/manage", "POST", {
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
export function updateCampaignInfo(campaignInfo) {
  const formData = makeFormData(campaignInfo, DataStructures.CampaignInfo);
  return axiosRequest("api/campaign/manage", "POST", {
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
export function deleteCampaign(campaignId) {
  return axiosRequest("api/campaign/manage", "DELETE", {
    params: {
      id: campaignId,
    },
  });
}
export function getCampaignMapList(campaignId) {
  return axiosRequest("api/campaign/map-list", "GET", {
    params: {
      id: campaignId,
    },
  });
}
export function cloneCampaignToOwnAccount(campaignId) {
  const formData = new FormData();
  formData.append("id", campaignId);
  return axiosRequest("api/campaign/clone", "POST", {
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
//#endregion

//#region Admin Panel API
export function adminPanelGetUserList() {
  return axiosRequest("api/admin/user", "GET");
}
export function adminPanelAddUser(user) {
  const formData = makeFormData(user, DataStructures.AdminPanelUser);
  return axiosRequest("api/admin/user", "POST", {
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
export function adminPanelUpdateUser(user) {
  const formData = makeFormData(user, DataStructures.AdminPanelUserUpdate);
  return axiosRequest("api/admin/user", "POST", {
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
export function adminPanelDeleteUser(userId) {
  return axiosRequest("api/admin/user", "DELETE", {
    params: {
      id: userId,
    },
  });
}
//#endregion

//#region Form Data Helper
export function makeFormData(object, properties) {
  const formData = new FormData();
  for (let property in properties) {
    if (properties.hasOwnProperty(property) === false) continue;

    //Each property can have the fields: nullable, allowZero, isBool
    let config = properties[property];
    let allowZero = config.allowZero ?? false;
    let nullable = config.nullable ?? true;
    let isBool = config.isBool ?? false;
    let propName = config.propName ?? property;

    let value = object[propName];
    if (value === undefined) {
      // console.log("Value for property '" + propName + "' is undefined!");
      continue;
    }

    if (!nullable && (value === null || value === undefined)) {
      console.error("Property '" + propName + "' is not nullable but has a null value!");
    }

    if (isBool) {
      formData.append(property, value ? 1 : 0);
    } else {
      if (nullable) {
        formData.append(property, asNullableApiValue(value, allowZero));
      } else {
        formData.append(property, value);
      }
    }
  }
  return formData;
}
//#endregion

//#region Registration API
export function submitRegistration(registrationData) {
  const formData = makeFormData(registrationData, DataStructures.Registration);
  return axiosRequest("api/user/register", "POST", {
    params: {
      action: "register",
    },
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
export function submitVerification(verifyCode) {
  return axiosRequest("api/user/register", "GET", {
    params: {
      action: "verify",
      code: verifyCode,
    },
  });
}
export function checkUsername(username) {
  return axiosRequest("api/user/check-username", "GET", {
    params: {
      name: username,
    },
  });
}

export function submitPasswordRecovery(name) {
  return axiosRequest("api/user/password-recovery", "GET", {
    params: {
      action: "recover",
      name: name,
    },
  });
}
export function submitPasswordRecoveryVerify(code, newPassword) {
  return axiosRequest("api/user/password-recovery", "GET", {
    params: {
      action: "verify",
      code: code,
      new_password: newPassword,
    },
  });
}
//#endregion

//#region Misc API
export function getServerSettings() {
  return axiosRequest("api/server_settings", "GET");
}

export function setServerSettings(settings) {
  const formData = makeFormData(settings, DataStructures.ServerSettings);
  return axiosRequest("api/server_settings", "POST", {
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
//#endregion
