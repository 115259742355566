import { Container } from "react-bootstrap";
import useServerSettings from "../data-hooks/useServerSettings";
import "../css/Footer.css";
import { SITE_NAME_LONG } from "../util/Defines";

export default function Footer() {
  const serverSettingsQuery = useServerSettings();

  if (serverSettingsQuery.isLoading || serverSettingsQuery.isError) return null;

  const serverSettings = serverSettingsQuery.data?.settings;

  if (!serverSettings.maintenance_enabled) return null;

  return (
    <Container fluid className="footer">
      <div className="text-center">{SITE_NAME_LONG} is currently READ-ONLY for maintenance.</div>
    </Container>
  );
}
