export function apiCall(url, method, body = null, callback) {
  fetch(url, {
    method: method,
    credentials: "include",
    body: body,
  })
    .then((response) => response.json())
    .then(callback);
}

export function asNullableApiValue(value, allowZero = false) {
  if (value === null || value === undefined || value === "" || (!allowZero && value === 0)) return null;

  return value;
}

export function getFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    let value = object[key];
    if (value !== undefined) {
      formData.append(key, value);
    }
  });
  return formData;
}
