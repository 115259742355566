import { useEffect, useState } from "react";
import { Form, Button, Row, Col, Stack, Accordion, Alert, InputGroup } from "react-bootstrap";
import {
  formatClearDuration,
  clearDurationToSeconds,
  numberWithCommas,
  getMapName,
  fetchCctCurrentMapData,
} from "./StatsUtil";
import { Typeahead } from "react-bootstrap-typeahead";

import { Link } from "react-router-dom";
import { SheetDifficultyBadge, SheetDifficultyIdSelect, Spinner } from "../../components/MiscComponents";
import {
  AddIcon,
  CrossIcon,
  DeathsIcon,
  DeleteIcon,
  ExternalLinkIcon,
  GoldberryIcon,
  SaveIcon,
  TimeIcon,
} from "../../components/Icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createPlayerMapGoldenRun,
  deletePlayerMapGoldenRun,
  getPlayerCampaignList,
  getPlayerMapData,
  getPlayerMapGoldenStats,
  parseAxiosError,
  updatePlayerMapData,
  updatePlayerMapGoldenRun,
} from "../../hooks/CelesteStatsApi";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { FormOptions } from "../../hooks/useFormUtil";
import { faBold, faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImportMapDataFromCctButton } from "./AddMap";

const SHEET_DIFFICULTIES = [
  { id: 0, name: "None", sort: -1, subtier: null, badgeColor: null, badgeForeground: "white" },
  { id: 1, name: "Tier 0", sort: 0, subtier: "high", badgeColor: "#f874c6", badgeForeground: "white" },
  { id: 2, name: "Tier 0", sort: 1, subtier: "mid", badgeColor: "#f874c6", badgeForeground: "white" },
  { id: 3, name: "Tier 0", sort: 2, subtier: "low", badgeColor: "#f874c6", badgeForeground: "white" },
  { id: 4, name: "Tier 1", sort: 3, subtier: "high", badgeColor: "#ff7b67", badgeForeground: "white" },
  { id: 5, name: "Tier 1", sort: 4, subtier: "mid", badgeColor: "#ff7b67", badgeForeground: "white" },
  { id: 6, name: "Tier 1", sort: 5, subtier: "low", badgeColor: "#ff7b67", badgeForeground: "white" },
  { id: 7, name: "Tier 2", sort: 6, subtier: "high", badgeColor: "#ffc874", badgeForeground: "white" },
  { id: 8, name: "Tier 2", sort: 7, subtier: "mid", badgeColor: "#ffc874", badgeForeground: "white" },
  { id: 9, name: "Tier 2", sort: 8, subtier: "low", badgeColor: "#ffc874", badgeForeground: "white" },
  { id: 10, name: "Tier 3", sort: 9, subtier: "high", badgeColor: "#ffec87", badgeForeground: "black" },
  { id: 11, name: "Tier 3", sort: 10, subtier: "mid", badgeColor: "#ffec87", badgeForeground: "black" },
  { id: 12, name: "Tier 3", sort: 11, subtier: "low", badgeColor: "#ffec87", badgeForeground: "black" },
  { id: 13, name: "Tier 3", sort: 12, subtier: "guard", badgeColor: "#ffec87", badgeForeground: "black" },
  { id: 14, name: "Tier 4", sort: 13, subtier: null, badgeColor: "#c9ff8d", badgeForeground: "black" },
  { id: 15, name: "Tier 5", sort: 14, subtier: null, badgeColor: "#93e69e", badgeForeground: "black" },
  { id: 16, name: "Tier 6", sort: 15, subtier: null, badgeColor: "#8fdeff", badgeForeground: "white" },
  { id: 17, name: "Tier 7", sort: 16, subtier: null, badgeColor: "#96a6ff", badgeForeground: "white" },
  { id: 18, name: "Standard", sort: 17, subtier: null, badgeColor: null, badgeForeground: "white" },
  { id: 19, name: "Undetermined", sort: 18, subtier: null, badgeColor: null, badgeForeground: "white" },
  { id: 20, name: "No List", sort: 19, subtier: null, badgeColor: null, badgeForeground: "white" },
];

export function QuickEditMap({ playerName, id, onSave, onCancel }) {
  const [anyChange, setAnyChange] = useState(false);

  const campaignListQuery = useQuery({
    queryKey: ["campaignList", playerName],
    queryFn: () => getPlayerCampaignList(playerName),
  });

  const mapDataQuery = useQuery({
    queryKey: ["mapData", playerName, id],
    queryFn: () => getPlayerMapData(playerName, id),
    onSuccess: (data) => {
      let mapData = data[0];
      let mapName = getMapName(mapData);
      document.title = "Edit '" + mapName + "' - Celeste Stats";
    },
    onError: (error) => {
      toast.error("Couldn't fetch map data: " + parseAxiosError(error).message);
    },
  });

  return (
    <>
      {mapDataQuery.isLoading || campaignListQuery.isLoading ? (
        <FakeQuickEditMapForm />
      ) : (
        <QuickEditMapForm
          playerName={playerName}
          mapDataQuery={mapDataQuery}
          campaignListQuery={campaignListQuery}
          onSave={onSave}
          onCancel={() => {
            if (anyChange) onSave(mapDataQuery.data[0]);
            else onCancel();
          }}
          onAnyChange={() => setAnyChange(true)}
        />
      )}
      <GoldenRunsEditSection playerName={playerName} mapId={id} onAnyChange={() => setAnyChange(true)} />
    </>
  );
}

function QuickEditMapForm({ playerName, mapDataQuery, campaignListQuery, onSave, onCancel }) {
  const queryClient = useQueryClient();

  useEffect(() => {
    let mapName = mapDataQuery.isError ? "" : getMapName(mapDataQuery.data[0]);
    document.title = "Edit '" + mapName + "' - Celeste Stats";
  }, []);

  const setCctData = (data) => {
    if (data === null) {
      toast.error("No CCT data found");
      return;
    }

    setValue("name", data.chapterName);
    setValue("side_name", data.sideName);
    setValue("cleared", data.completed);
    setValue("clear_duration", data.totalTime);
    setValue("clear_deaths", data.totalDeaths);
    setValue("cleared_on", data.completed ? new Date().toISOString().split("T")[0] : null);
  };
  const onCctError = (error) => {
    toast.error("Error fetching CCT data: " + error.message);
  };

  const queriedData = mapDataQuery.isError ? {} : mapDataQuery.data[0];
  const defaultCampaign =
    queriedData.campaign_id === null
      ? []
      : [campaignListQuery.data.find((campaign) => campaign.id === queriedData.campaign_id)];
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    setValue,
  } = useForm({
    defaultValues: {
      ...queriedData,
      clear_duration: formatClearDuration(queriedData.clear_duration),
      campaign: defaultCampaign,
    },
  });
  const onSubmit = handleSubmit((data) => {
    console.log("Submitted valid form", data);
    const map = {
      ...data,
      campaign_id: data.campaign.length > 0 ? data.campaign[0].id : null,
      clear_duration: clearDurationToSeconds(data.clear_duration),
    };
    console.log("produced map data -> ", map);
    saveMapMutation.mutate(map);
  });
  console.log("errors", errors);

  const saveMapMutation = useMutation({
    mutationFn: (mapData) => updatePlayerMapData(mapData),
    onSuccess: (data, mapData) => {
      console.log("Saved map data.");
      queryClient.invalidateQueries(["mapData", playerName, mapData.id]);
      onSave(mapData);
    },
    onError: (error) => {
      toast.error("Couldn't save map data: " + parseAxiosError(error).message);
    },
  });

  if (mapDataQuery.isError || campaignListQuery.isError) {
    return <FakeQuickEditMapForm />;
  }

  return (
    <>
      <Form onSubmit={onSubmit}>
        <ImportMapDataFromCctButton onSucccess={setCctData} onError={onCctError} />
        <Form.Group className="mb-3">
          <Form.Label>Campaign Name</Form.Label>
          <Controller
            control={control}
            name="campaign"
            render={({ field }) => (
              <Typeahead
                id="campaign"
                name="campaign"
                selected={field.value}
                labelKey={(o) => o.name}
                options={campaignListQuery.data}
                placeholder="Choose a campaign..."
                onChange={(selection) => field.onChange(selection)}
              />
            )}
          />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="mapName">
              <Form.Label>Map Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                {...register("name", { required: true })}
                isInvalid={errors.name !== undefined}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="sideName">
              <Form.Label>Side Name</Form.Label>
              <Form.Control type="text" name="side_name" {...register("side_name")} />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3" controlId="url">
          <Form.Label>URL</Form.Label>
          <Form.Control type="text" name="url" {...register("url")} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="cleared">
          <Form.Check type="checkbox" label="Cleared" name="cleared" {...register("cleared")} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="proofUrl">
          <Form.Label>Proof URL</Form.Label>
          <Form.Control type="text" name="proof_url" {...register("proof_url")} />
        </Form.Group>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="clearDuration">
              <Form.Label>Clear Duration</Form.Label>
              <Form.Control
                type="text"
                placeholder="(hh:)mm:ss"
                name="clear_duration"
                {...register("clear_duration", { ...FormOptions.ClearTime })}
                isInvalid={errors.clear_duration !== undefined}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="clearDeaths">
              <Form.Label>Clear Deaths</Form.Label>
              <Form.Control
                type="text"
                name="clear_deaths"
                {...register("clear_deaths", { ...FormOptions.ClearDeaths })}
                isInvalid={errors.clear_deaths !== undefined}
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3" controlId="clearedOn">
          <Form.Label>Cleared On</Form.Label>
          <Form.Control type="date" name="cleared_on" {...register("cleared_on")} />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="difficulty">
              <Form.Label>Difficulty</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="difficulty"
                  {...register("difficulty", { ...FormOptions.Difficulty })}
                  isInvalid={errors.difficulty !== undefined}
                />
                <InputGroup.Text>/ 100</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="enjoyment">
              <Form.Label>Enjoyment</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="enjoyment"
                  {...register("enjoyment", { ...FormOptions.Enjoyment })}
                  isInvalid={errors.enjoyment !== undefined}
                />
                <InputGroup.Text>/ 10</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3" controlId="notes">
          <Form.Label>Notes</Form.Label>
          <Form.Control as="textarea" rows={1} name="notes" {...register("notes")} />
        </Form.Group>

        {campaignListQuery.isError ? (
          <p className="text-danger">{parseAxiosError(campaignListQuery.error).message}</p>
        ) : null}
        {mapDataQuery.isError ? (
          <p className="text-danger">{parseAxiosError(mapDataQuery.error).message}</p>
        ) : null}
        {saveMapMutation.isError ? (
          <p className="text-danger">{parseAxiosError(saveMapMutation.error).message}</p>
        ) : null}
        <Stack direction="horizontal" gap={3}>
          <Button variant="primary" type="submit">
            <SaveIcon className="me-2" />
            Save
          </Button>
          {isDirty && (
            <Button variant="outline-secondary" className="" onClick={() => reset()}>
              Discard Changes
            </Button>
          )}
          <Button variant="outline-danger no-border ms-auto" onClick={onCancel}>
            Cancel
          </Button>
        </Stack>
      </Form>
    </>
  );
}

function FakeQuickEditMapForm() {
  return (
    <>
      <Form>
        <Form.Group className="mb-3">
          <ImportMapDataFromCctButton disabled={true} />
          <Form.Label>Campaign Name</Form.Label>
          <Typeahead
            id="campaignName"
            name="campaign_name"
            labelKey={(o) => o.name}
            options={[]}
            placeholder="Choose a campaign..."
            disabled
          />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="mapName">
              <Form.Label>Map Name</Form.Label>
              <Form.Control type="text" name="name" disabled />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="sideName">
              <Form.Label>Side Name</Form.Label>
              <Form.Control type="text" name="side_name" disabled />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3" controlId="url">
          <Form.Label>URL</Form.Label>
          <Form.Control type="text" name="url" disabled />
        </Form.Group>
        <Form.Group className="mb-3" controlId="cleared">
          <Form.Check type="checkbox" label="Cleared" name="cleared" disabled />
        </Form.Group>
        <Form.Group className="mb-3" controlId="proofUrl">
          <Form.Label>Proof URL</Form.Label>
          <Form.Control type="text" name="proof_url" disabled />
        </Form.Group>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="clearDuration">
              <Form.Label>Clear Duration</Form.Label>
              <Form.Control type="text" placeholder="hh:mm:ss" name="clear_duration" disabled />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="clearDeaths">
              <Form.Label>Clear Deaths</Form.Label>
              <Form.Control type="text" name="clear_deaths" disabled />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3" controlId="clearedOn">
          <Form.Label>Cleared On</Form.Label>
          <Form.Control type="date" name="cleared_on" disabled />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="difficulty">
              <Form.Label>Difficulty /100</Form.Label>
              <Form.Control type="text" name="difficulty" disabled />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="enjoyment">
              <Form.Label>Enjoyment /10</Form.Label>
              <Form.Control type="text" name="enjoyment" disabled />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3" controlId="notes">
          <Form.Label>Notes</Form.Label>
          <Form.Control as="textarea" rows={1} name="notes" disabled />
        </Form.Group>
      </Form>
      <Stack direction="horizontal" gap={3}>
        <Button variant="primary" disabled>
          <SaveIcon className="me-2" />
          Save
        </Button>
        <Button variant="outline-danger no-border ms-auto" disabled>
          Cancel
        </Button>
      </Stack>
    </>
  );
}

function GoldenRunsEditSection({ playerName, mapId, onAnyChange }) {
  const queryClient = useQueryClient();
  const [openedRun, setOpenedRun] = useState(null);

  const {
    isLoading,
    isError,
    error,
    data: runsList,
  } = useQuery({
    queryKey: ["goldenRunsList", mapId],
    queryFn: () => getPlayerMapGoldenStats(mapId),
  });

  const { mutate: deleteRun } = useMutation({
    mutationFn: (runId) => deletePlayerMapGoldenRun(runId),
    onSuccess: (data, runId) => {
      onAnyChange();
      queryClient.setQueryData(["goldenRunsList", mapId], (oldData) => oldData.filter((r) => r.id !== runId));
      queryClient.invalidateQueries(["topGoldenList", playerName]);
      toast.success("Deleted golden run");
    },
  });

  const { mutate: addGoldenRun } = useMutation({
    mutationFn: (mapId) => createPlayerMapGoldenRun(mapId),
    onSuccess: (data) => {
      console.log("Added golden run data, new id: ", data.id);
      onAnyChange();
      queryClient.setQueryData(["goldenRunsList", mapId], (oldData) => {
        let newRun = {
          id: data.id,
          map_id: mapId,
          is_fc: false,
          is_special: false,
          description: "",
          cleared: false,
          clear_duration: null,
          clear_deaths: null,
          cleared_on: null,
          sheet_difficulty_id: null,
          difficulty: null,
          enjoyment: null,
          notes: null,
          proof_url: null,
        };

        return [...oldData, newRun];
      });
      queryClient.invalidateQueries(["topGoldenList", playerName]);

      setOpenedRun(runsList.length);
    },
  });

  function onDelete(run) {
    deleteRun(run.id);
  }

  function handleAddGoldenRun() {
    addGoldenRun(mapId);
  }

  if (isLoading) {
    return (
      <>
        <hr />
        <Row>
          <Col>
            <h2>Golden Runs</h2>
          </Col>
          <Col className="d-flex"></Col>
        </Row>
        <Row>
          <Col>
            Loading Golden Runs <Spinner />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <hr />
      <Row>
        <Col>
          <h2>Golden Runs</h2>
        </Col>
        <Col className="d-flex">
          <Button
            variant="outline-primary ms-auto"
            size="sm"
            disabled={isError}
            style={{ alignSelf: "center", height: "fit-content" }}
            onClick={handleAddGoldenRun}
          >
            <AddIcon className="me-2" />
            Add Golden Run
          </Button>
        </Col>
      </Row>
      {isError && <Alert variant="danger">{parseAxiosError(error).message}</Alert>}
      {!isError ? (
        <Accordion activeKey={openedRun} onSelect={(eventKey) => setOpenedRun(eventKey)}>
          {runsList.map((run, index) => (
            <GoldenRunEditAccordionItem
              playerName={playerName}
              mapId={mapId}
              key={run.id}
              index={index}
              run={run}
              onDelete={onDelete}
              onAnyChange={onAnyChange}
            />
          ))}
        </Accordion>
      ) : null}
    </>
  );
}

function GoldenRunEditAccordionItem({ playerName, mapId, index, run, onDelete, onAnyChange }) {
  const queryClient = useQueryClient();

  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      ...run,
      clear_duration: formatClearDuration(run.clear_duration),
    },
  });
  const onSubmit = handleSubmit((data) => {
    console.log("Submitted valid form: ", data);
    const runData = {
      ...data,
      clear_duration: clearDurationToSeconds(data.clear_duration),
      id: run.id,
    };
    saveRun(runData);
  });
  const {
    cleared,
    clear_duration,
    clear_deaths,
    description,
    is_fc,
    is_special,
    sheet_difficulty_id,
    proof_url,
  } = watch();

  const { mutate: saveRun } = useMutation({
    mutationFn: (run) => updatePlayerMapGoldenRun(run),
    onSuccess: (data, run) => {
      console.log("Saved golden run data:", run);
      onAnyChange();
      queryClient.setQueryData(["goldenRunsList", mapId], (oldData) =>
        oldData.map((r) => (r.id === run.id ? run : r))
      );
      queryClient.invalidateQueries(["topGoldenList", playerName]);
      toast.success("Saved golden run data.");
    },
    onError: (error) => {
      toast.error("Couldn't save golden run data: " + parseAxiosError(error).message);
    },
  });

  function handleDelete() {
    onDelete(run);
  }

  let accordionDescription = description === null || description === "" ? "Golden" : description;
  accordionDescription += is_fc && !is_special ? " [FC]" : "";
  accordionDescription += !is_fc && is_special ? " [SB]" : "";
  accordionDescription += is_fc && is_special ? " [FC+SB]" : "";

  console.log("isDirty", isDirty);
  let sheetDiff = SHEET_DIFFICULTIES.find((difficulty) => difficulty.id === sheet_difficulty_id);

  return (
    <Accordion.Item eventKey={index}>
      <Accordion.Header>
        <Row style={{ width: "100%" }}>
          <Col xs={1} className="d-flex">
            <span className="my-auto mx-auto">{index + 1}</span>
          </Col>
          <Col xs={3} className="d-flex">
            <span className="my-auto mx-auto">{accordionDescription}</span>
          </Col>
          <Col xs={1} className="d-flex">
            {cleared ? (
              <GoldberryIcon className="my-auto mx-auto" />
            ) : (
              <CrossIcon style={{ marginLeft: "3px" }} className="my-auto mx-auto" />
            )}
          </Col>
          <Col xs={2} className="d-flex">
            {cleared && clear_duration !== null ? (
              <span className="my-auto mx-auto">
                {clear_duration} {<TimeIcon />}
              </span>
            ) : null}
          </Col>
          <Col xs={2} className="d-flex">
            {cleared && clear_deaths !== null ? (
              <span className="my-auto mx-auto">
                {numberWithCommas(clear_deaths)} {<DeathsIcon />}
              </span>
            ) : null}
          </Col>
          <Col xs={2} className="d-flex">
            {sheetDiff !== undefined && sheetDiff.id !== 0 ? (
              <SheetDifficultyBadge id={sheetDiff.id} className="my-auto mx-auto" />
            ) : null}
          </Col>
          <Col xs={1} className="d-flex">
            {proof_url !== null && proof_url !== "" ? (
              <Link
                to={proof_url}
                style={{ color: "lightgray" }}
                onClick={(e) => e.stopPropagation()}
                className="my-auto mx-auto"
              >
                <ExternalLinkIcon />
              </Link>
            ) : null}
          </Col>
        </Row>
      </Accordion.Header>
      <Accordion.Body>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId={"gr-is_fc" + index}>
                <Form.Check type="checkbox" label="Is Full Clear" name="is_fc" {...register("is_fc")} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId={"gr-is_sb" + index}>
                <Form.Check
                  type="checkbox"
                  label="Has Special Berry"
                  name="is_special"
                  {...register("is_special")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId={"gr-description" + index}>
                <Form.Label>Description</Form.Label>
                <Form.Control type="text" name="description" {...register("description")} />
              </Form.Group>
            </Col>
            <Col className="d-flex">
              <Form.Group className="mb-4" controlId={"gr-cleared" + index} style={{ alignSelf: "end" }}>
                <Form.Check type="checkbox" label="Cleared" name="cleared" {...register("cleared")} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId={"gr-clear_duration" + index}>
                <Form.Label>Clear Duration</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="(hh:)mm:ss"
                  name="clear_duration"
                  {...register("clear_duration", { ...FormOptions.ClearTime })}
                  isInvalid={errors.clear_duration !== undefined}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId={"gr-clear_deaths" + index}>
                <Form.Label>Clear Deaths</Form.Label>
                <Form.Control
                  type="text"
                  name="clear_deaths"
                  {...register("clear_deaths", { ...FormOptions.ClearDeaths })}
                  isInvalid={errors.clear_deaths !== undefined}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId={"gr-cleared_on" + index}>
                <Form.Label>Cleared On</Form.Label>
                <Form.Control type="date" name="cleared_on" {...register("cleared_on")} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId={"gr-sheetDifficultyId" + index}>
                <Form.Label>Sheet Difficulty</Form.Label>
                <Controller
                  control={control}
                  name="sheet_difficulty_id"
                  render={({ field }) => (
                    <SheetDifficultyIdSelect
                      name="sheet_difficulty_id"
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(parseInt(e.target.value));
                      }}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId={"gr-difficulty" + index}>
                <Form.Label>Difficulty</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="difficulty"
                    {...register("difficulty", { ...FormOptions.Difficulty })}
                    isInvalid={errors.difficulty !== undefined}
                  />
                  <InputGroup.Text>/ 100</InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId={"gr-enjoyment" + index}>
                <Form.Label>Enjoyment</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="enjoyment"
                    {...register("enjoyment", { ...FormOptions.Enjoyment })}
                    isInvalid={errors.enjoyment !== undefined}
                  />
                  <InputGroup.Text>/ 10</InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId={"gr-notes" + index}>
                <Form.Label>Notes</Form.Label>
                <Form.Control as="textarea" rows={1} name="notes" {...register("notes")} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId={"gr-proofUrl" + index}>
                <Form.Label>Proof URL</Form.Label>
                <Form.Control type="text" name="proof_url" {...register("proof_url")} />
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-between">
            <Button variant="primary" type="submit">
              <SaveIcon className="me-2" />
              Save
            </Button>
            {isDirty && (
              <Button variant="outline-secondary" className="" onClick={() => reset()}>
                Discard Changes
              </Button>
            )}
            <Button variant="outline-danger no-border" onClick={handleDelete}>
              <DeleteIcon className="me-2" />
              Delete
            </Button>
          </div>
        </Form>
      </Accordion.Body>
    </Accordion.Item>
  );
}
