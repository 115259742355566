import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { getDifficultyClass, getEnjoymentClass } from "../pages/celeste-stats/StatsUtil";
import { Container, ProgressBar, Alert, Nav, Form, Badge } from "react-bootstrap";
import { capitalizeFirstLetter } from "../util/OtherUtils";
import { useLocation, useNavigate } from "react-router";
import {
  ClearsDifficultyListIcon,
  ClearsEnjoymentListIcon,
  GoldensDifficultyListIcon,
  GoldensEnjoymentListIcon,
  JournalIcon,
  ModListIcon,
  TopGoldenListIcon,
} from "./Icons";

export function LoadingContainer({ title, fluid, centered }) {
  let centerClass = centered ? "text-center" : "";
  return (
    <Container fluid={fluid}>
      {title ? <h1 className={centerClass}>{title}</h1> : null}
      <h3 className={centerClass}>
        Loading <Spinner />
      </h3>
    </Container>
  );
}

export function ErrorContainer({ title, message, fluid }) {
  return (
    <Container fluid={fluid}>
      <h1>{title}</h1>
      <Alert variant="danger">{message}</Alert>
    </Container>
  );
}

export function Spinner() {
  return <FontAwesomeIcon spin icon={faSpinner} />;
}

export function DifficultyDisplay({ difficulty, rank }) {
  let difficultyColorClass = getDifficultyClass(difficulty);
  let rankDisplay = rank ? `#${rank}` : "";

  if (rank) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <ProgressBar
          style={{ border: "1px solid lightgray", flex: "1" }}
          variant={difficultyColorClass}
          now={difficulty}
          max={100}
          label={`${difficulty}`}
        />
        <div
          className="d-none d-xxl-block ps-3"
          style={{ minWidth: "60px", paddingBottom: "2px", textAlign: "left" }}
        >
          {rankDisplay}
        </div>
      </div>
    );
  }

  return (
    <ProgressBar
      style={{ border: "1px solid lightgray", flex: "1" }}
      variant={difficultyColorClass}
      now={difficulty}
      max={100}
      label={`${difficulty}`}
    />
  );
}

export function EnjoymentDisplay({ enjoyment, rank, progressStyle, ...props }) {
  let enjoymentColorClass = getEnjoymentClass(enjoyment);
  let rankDisplay = rank ? `#${rank}` : "";

  if (rank) {
    return (
      <div className="d-flex justify-content-center align-items-center" {...props}>
        <ProgressBar
          style={{ border: "1px solid lightgray", flex: "1", ...progressStyle }}
          variant={enjoymentColorClass}
          now={enjoyment}
          max={10}
          label={`${enjoyment}`}
        />
        <div
          className="d-none d-xxl-block ps-3"
          style={{ minWidth: "60px", paddingBottom: "2px", textAlign: "left" }}
        >
          {rankDisplay}
        </div>
      </div>
    );
  }

  return (
    <ProgressBar
      style={{ border: "1px solid lightgray", flex: "1", ...progressStyle }}
      variant={enjoymentColorClass}
      now={enjoyment}
      max={10}
      label={`${enjoyment}`}
      {...props}
    />
  );
}

export function PlayerProfileNavbar({ playerName, activePage }) {
  const navigate = useNavigate();
  const location = useLocation();

  function navigateOnClick(event) {
    event.preventDefault();
    console.log("Navigating to -> ", event.target.pathname);
    navigate(event.target.pathname);
  }

  return (
    <Nav variant="tabs" defaultActiveKey={activePage} className="mb-3">
      <Nav.Item>
        <Nav.Link href={"/player/" + playerName} onClick={navigateOnClick}>
          <ModListIcon className="me-2" />
          All Mods
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href={"/player/" + playerName + "/campaigns"} onClick={navigateOnClick}>
          <JournalIcon className="me-2" />
          Campaigns
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href={"/player/" + playerName + "/top-golden-list"} onClick={navigateOnClick}>
          <TopGoldenListIcon className="me-2" />
          Top Golden List
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href={"/player/" + playerName + "/list/clears/enjoyment"} onClick={navigateOnClick}>
          <ClearsEnjoymentListIcon className="me-2" />
          Clears By Enjoyment
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href={"/player/" + playerName + "/list/clears/difficulty"} onClick={navigateOnClick}>
          <ClearsDifficultyListIcon className="me-2" />
          Clears By Difficulty
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href={"/player/" + playerName + "/list/goldens/enjoyment"} onClick={navigateOnClick}>
          <GoldensEnjoymentListIcon className="me-2" />
          Goldens By Enjoyment
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href={"/player/" + playerName + "/list/goldens/difficulty"} onClick={navigateOnClick}>
          <GoldensDifficultyListIcon className="me-2" />
          Goldens By Difficulty
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
}

//#region Sheet Difficulty Components
export const SHEET_DIFFICULTIES = [
  { id: 0, name: "None", sort: -1, subtier: null, background_color: null, color: "white" },
  { id: 1, name: "Tier 0", sort: 20, subtier: "high", background_color: "#f874c6", color: "white" },
  { id: 2, name: "Tier 0", sort: 19, subtier: "mid", background_color: "#f874c6", color: "white" },
  { id: 3, name: "Tier 0", sort: 18, subtier: "low", background_color: "#f874c6", color: "white" },
  { id: 4, name: "Tier 1", sort: 17, subtier: "high", background_color: "#ff7b67", color: "white" },
  { id: 5, name: "Tier 1", sort: 16, subtier: "mid", background_color: "#ff7b67", color: "white" },
  { id: 6, name: "Tier 1", sort: 15, subtier: "low", background_color: "#ff7b67", color: "white" },
  { id: 7, name: "Tier 2", sort: 14, subtier: "high", background_color: "#ffc874", color: "white" },
  { id: 8, name: "Tier 2", sort: 13, subtier: "mid", background_color: "#ffc874", color: "white" },
  { id: 9, name: "Tier 2", sort: 12, subtier: "low", background_color: "#ffc874", color: "white" },
  { id: 10, name: "Tier 3", sort: 11, subtier: "high", background_color: "#ffec87", color: "black" },
  { id: 11, name: "Tier 3", sort: 10, subtier: "mid", background_color: "#ffec87", color: "black" },
  { id: 12, name: "Tier 3", sort: 9, subtier: "low", background_color: "#ffec87", color: "black" },
  { id: 13, name: "Tier 3", sort: 8, subtier: "guard", background_color: "#ffec87", color: "black" },
  { id: 14, name: "Tier 4", sort: 7, subtier: null, background_color: "#c9ff8d", color: "black" },
  { id: 15, name: "Tier 5", sort: 6, subtier: null, background_color: "#93e69e", color: "black" },
  { id: 16, name: "Tier 6", sort: 5, subtier: null, background_color: "#8fdeff", color: "white" },
  { id: 17, name: "Tier 7", sort: 4, subtier: null, background_color: "#96a6ff", color: "white" },
  { id: 18, name: "Standard", sort: 3, subtier: null, background_color: null, color: "white" },
  { id: 19, name: "Undetermined", sort: 2, subtier: null, background_color: null, color: "white" },
  { id: 20, name: "No List", sort: 1, subtier: null, background_color: null, color: "white" },
];

export function SheetDifficultyIdSelect({ name, value, onChange }) {
  return (
    <Form.Select aria-label="Sheet Difficulty" name={name} value={value} onChange={onChange}>
      {SHEET_DIFFICULTIES.map((difficulty) => {
        if (difficulty.subtier === null) {
          return <option value={difficulty.id}>{difficulty.name}</option>;
        } else {
          return <option value={difficulty.id}>{difficulty.name + " (" + difficulty.subtier + ")"}</option>;
        }
      })}
    </Form.Select>
  );
}

export function SheetDifficultyBadge({ id, text, noSubtier = false, ...props }) {
  let difficulty = SHEET_DIFFICULTIES.find((difficulty) => difficulty.id === id);

  if (difficulty === undefined) {
    return null;
  }

  let badgeStyle = {
    backgroundColor: difficulty.background_color ? difficulty.background_color : "gray",
    color: difficulty.color ?? "white",
  };

  let additionalText = text !== undefined ? text : "";

  return (
    <Badge bg="" style={badgeStyle} {...props}>
      {additionalText}
      {difficulty.subtier === null || noSubtier
        ? difficulty.name
        : capitalizeFirstLetter(difficulty.subtier) + " " + difficulty.name}
    </Badge>
  );
}

//#endregion

export function VerticalRule({ color = "lightgray", style, ...props }) {
  return (
    <div
      style={{
        width: "1px",
        backgroundColor: color ? color : "lightgray",
        ...style,
      }}
      {...props}
    ></div>
  );
}
