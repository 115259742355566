//Formats a number of seconds into a string of format HH:MM:SS
export function formatClearDuration(clearSeconds) {
  if (clearSeconds === null || clearSeconds === undefined) {
    return "";
  }

  let hours = Math.floor(clearSeconds / 3600);
  let remainder = clearSeconds % 3600;
  let minutes = Math.floor(remainder / 60);
  let secondsRemainder = remainder % 60;

  return (
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    ":" +
    secondsRemainder.toString().padStart(2, "0")
  );
}

//Parses a string of format HH:MM:SS or MM:SS into seconds
export function clearDurationToSeconds(clearDurationString) {
  if (clearDurationString === null || clearDurationString === undefined || clearDurationString === "") {
    return null;
  }

  let clearDurationParts = clearDurationString.split(":");
  let clearDurationSeconds = 0;
  if (clearDurationParts.length === 3) {
    clearDurationSeconds = parseInt(clearDurationParts[0]) * 3600;
    clearDurationSeconds += parseInt(clearDurationParts[1]) * 60;
    clearDurationSeconds += parseInt(clearDurationParts[2]);
  } else if (clearDurationParts.length === 2) {
    clearDurationSeconds = parseInt(clearDurationParts[0]) * 60;
    clearDurationSeconds += parseInt(clearDurationParts[1]);
  }

  return clearDurationSeconds;
}

export function numberWithCommas(number) {
  if (number === null || number === undefined) return "";

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function asNullableApiValue(value, allowZero = false) {
  if (value === null || value === undefined || value === "" || (!allowZero && value === 0)) return null;

  return value;
}

export function getDifficultyClass(difficulty) {
  let difficultyColorClass = "";
  if (difficulty < 25) {
    difficultyColorClass = "info";
  } else if (difficulty < 50) {
    difficultyColorClass = "success";
  } else if (difficulty < 75) {
    difficultyColorClass = "warning";
  } else {
    difficultyColorClass = "danger";
  }

  return difficultyColorClass;
}

export function getEnjoymentClass(enjoyment) {
  let enjoymentColorClass = "";
  if (enjoyment <= 3) {
    enjoymentColorClass = "danger";
  } else if (enjoyment <= 6) {
    enjoymentColorClass = "warning";
  } else if (enjoyment < 9) {
    enjoymentColorClass = "success";
  } else {
    enjoymentColorClass = "info";
  }

  return enjoymentColorClass;
}

/*
Takes a list of objects and groups them by a property
Input: [{id: 1, name: 'a'}, {id: 2, name: 'a'}, {id: 3, name: 'b'}], 'name'
Output: [{'a': [{id: 1, name: 'a'}, {id: 2, name: 'a'}]}, {'b': [{id: 3, name: 'b'}]}
*/
export function groupListByProperty(objectList, property) {
  return objectList.reduce(function (reduced, entry) {
    if (!(property in entry) || entry[property] === null) return reduced;

    let key = entry[property];
    if (!(key in reduced)) {
      reduced[key] = [];
    }
    reduced[key].push(entry);
    return reduced;
  }, {});
}

export function sortListByProperties(list, properties) {
  list.sort(function (a, b) {
    for (let i = 0; i < properties.length; i++) {
      let property = properties[i];

      let propName = property;
      let sortDirection = "asc";
      if (typeof property === typeof []) {
        propName = property[0];
        sortDirection = property[1];
      }

      let valueA = a[propName];
      let valueB = b[propName];

      if ((valueA === null || valueA === undefined) && (valueB === null || valueB === undefined)) continue;

      if (typeof valueA === "string") valueA = valueA.toLowerCase();
      if (typeof valueB === "string") valueB = valueB.toLowerCase();

      if (typeof valueA === "string" && typeof valueB === "string") {
        if (valueA === "" && valueB === "") continue;
      }

      if (valueA < valueB) return sortDirection === "asc" ? -1 : 1;
      if (valueA > valueB) return sortDirection === "asc" ? 1 : -1;
    }
    return 0;
  });
}

export function getMapName(map) {
  if (map === null || map === undefined) return null;

  let mapName = map.name;
  if (map.side_name !== null && map.side_name !== undefined && map.side_name !== "") {
    mapName += " [" + map.side_name + "]";
  }

  return mapName;
}

export function getMapNameFromGoldenRun(golden) {
  let mapName = golden.map_name;
  if (golden.map_side_name !== null && golden.map_side_name !== "") {
    mapName += " [" + golden.map_side_name + "]";
  }

  return mapName;
}

export function getGoldenDescription(golden) {
  let name =
    golden.description === null || golden.description === "" || golden.description === undefined
      ? "Golden"
      : golden.description;
  name += golden.is_fc && !golden.is_special ? " [FC]" : "";
  name += !golden.is_fc && golden.is_special ? " [SB]" : "";
  name += golden.is_fc && golden.is_special ? " [FC+SB]" : "";

  return name;
}

export async function fetchCurrentChapterStats() {
  const response = await fetch("http://localhost:32270/cct/currentChapterStats");
  const parsed = await response.json();
  return parsed;
}

export async function fetchCctCurrentMapData() {
  const response = await fetchCurrentChapterStats();

  if (response.errorCode !== 0) {
    return null;
  }

  //parse as ChapterStats
  const stats = response.chapterStats;
  const gameData = response.chapterStats.gameData;

  const toReturn = {
    campaignName: stats.campaignName,
    chapterName: stats.chapterName,
    sideName: stats.sideName === "A-Side" ? "" : stats.sideName,
    goldenCollectedCount: stats.goldenCollectedCount,
    completed: gameData.completed,
    fullClear: gameData.fullClear,
    totalTime:
      gameData.totalTime === -1 ? "" : formatClearDuration(Math.floor(gameData.totalTime / (1000 * 10000))),
    totalDeaths: gameData.totalDeaths,
  };

  return toReturn;
}
