import { useMutation, useQuery } from "react-query";
import { useLocalStorage } from "./useStorage";
import { useEventListener } from "./useEventListener";
import { getUserSessionData, loginUser, logoutUser, parseAxiosError } from "./CelesteStatsApi";
import { useCallback } from "react";
import { toast } from "react-toastify";

export function useUserSession(sessionExpiredCallback = undefined) {
  const [user, setUser] = useLocalStorage("user", null);
  const isLoggedIn = !!user?.name;
  const isOwnPage = (userName) => {
    return isLoggedIn && user.name === userName;
  };
  const onSessionExpired = useCallback(sessionExpiredCallback, [sessionExpiredCallback]);

  const loginMutation = useMutation({
    mutationFn: ({ userName, password, onSuccess, onError }) => loginUser(userName, password),
    onSuccess: (data, { userName, password, onSuccess, onError }) => {
      const event = new CustomEvent("userChanged", { detail: data });
      window.dispatchEvent(event);
      if (typeof onSuccess === "function") onSuccess(data);
    },
    onError: (error, { userName, password, onSuccess, onError }) => {
      if (typeof onError === "function") onError(error);
      else toast.error("Login failed: " + parseAxiosError(error).message);
    },
  });

  const logoutMutation = useMutation({
    mutationFn: (callback) => logoutUser(),
    onSuccess: (data, callback) => {
      const event = new CustomEvent("userChanged", { detail: null });
      window.dispatchEvent(event);
      if (typeof callback === "function") callback(data);
    },
  });

  useEventListener("userChanged", (event) => {
    if (event.detail !== null) setUser(event.detail);
    else setUser(undefined);
  });

  const login = (userName, password, onSuccess, onError) => {
    loginMutation.mutate({ userName, password, onSuccess, onError });
  };
  const logout = (callback) => {
    logoutMutation.mutate(callback);
  };

  //Check user session action
  const checkSessionQuery = useQuery({
    queryKey: "checkSession",
    queryFn: () => getUserSessionData(),
    // enabled: isLoggedIn && onSessionExpired !== null, //If logged in and onSessionExpired isnt explicitly null
    refetchInterval: 1000 * 60 * 5, //5 minutes
    onSuccess: (data) => {
      const event = new CustomEvent("userChanged", { detail: data });
      window.dispatchEvent(event);
    },
    onError: (error) => {
      //Check if the error specified that the user doesnt have access to the page
      if (error.response?.status === 401 && isLoggedIn) {
        const event = new CustomEvent("userChanged", { detail: null });
        window.dispatchEvent(event);
        if (
          onSessionExpired !== undefined &&
          onSessionExpired !== null &&
          typeof onSessionExpired === "function"
        ) {
          onSessionExpired();
        } else if (onSessionExpired === null) {
          //Do nothing if explicitly set to null
        } else {
          //Default action after session expired: send toast notification
          toast.error("Your session has expired. Please login again.");
        }
      } else {
        if (isLoggedIn) {
          console.log("Error checking user session: ", error);
        }
      }
    },
  });

  return { user, isLoggedIn, isOwnPage, login, logout, loginMutation, logoutMutation, checkSessionQuery };
}
