import { useQuery } from "react-query";
import { getPublicPlayersList, parseAxiosError } from "../../hooks/CelesteStatsApi";
import { ErrorContainer, LoadingContainer, SheetDifficultyBadge } from "../../components/MiscComponents";
import { Container, Stack } from "react-bootstrap";
import {
  ClearIcon,
  DeathsIcon,
  GoldberryIcon,
  JournalIcon,
  TimeIcon,
  UserIcon,
} from "../../components/Icons";
import { formatClearDuration, numberWithCommas } from "./StatsUtil";
import "../../css/PlayersList.css";
import { Link } from "react-router-dom";
import axios from "axios";

export default function PlayersListPage() {
  document.title = "All Players - Celeste Stats";
  const playerListQuery = useQuery({
    queryKey: "playerList",
    queryFn: () => getPublicPlayersList(),
  });

  const testQuery = useQuery({
    queryKey: "test",
    queryFn: () => axios.get("https://stats.vi-home.de/api/test", { params: { id: [1, 2, 3, 4] } }),
  });

  if (playerListQuery.isLoading) {
    return <LoadingContainer title="All Players" />;
  } else if (playerListQuery.isError) {
    let error = parseAxiosError(playerListQuery.error);
    return <ErrorContainer title="All Players" message={error.message} />;
  }

  return (
    <Container>
      <h1>All Players ({playerListQuery.data.length})</h1>
      {playerListQuery.data.map((player) => (
        <PlayerDisplay key={player.id} player={player} />
      ))}
    </Container>
  );
}

export function PlayerDisplay({ player }) {
  const centerItemsGrid = {
    placeItems: "center",
  };

  const briefStatsGrid = {
    display: "grid",
    grid: "1fr 1fr / 1fr 2fr 2fr 1fr",
    gap: "0px",
    width: "100%",
    padding: "5px",
  };

  const separatorStyle = {
    backgroundColor: "lightgray",
    width: "100%",
    height: "100%",
  };

  return (
    <Link to={"/player/" + player.name} style={{ color: "inherit", textDecoration: "none" }}>
      <div className="mb-3 player-list-outer-grid">
        <div style={{ gridArea: "name", ...centerItemsGrid }} className="player-list-username-grid">
          <UserIcon size="huge" style={{ gridArea: "icon" }} />
          <span style={{ gridArea: "username" }}>{player.name}</span>
        </div>
        <div
          style={{
            gridArea: "maps",
            ...briefStatsGrid,
          }}
        >
          <div style={{ gridArea: "1 / 1 / 2 / 3" }}>
            <b>
              <u>Maps</u>
            </b>
          </div>
          <Stack direction="horizontal" gap={1} style={{ gridArea: "2 / 1" }}>
            {player.maps_cleared} <ClearIcon />
          </Stack>
          <Stack direction="horizontal" gap={1} style={{ gridArea: "1 / 3 / 2 / 5" }}>
            <b>{player.campaigns_count}</b>
            <JournalIcon />
            <b>Campaign(s)</b>
          </Stack>
          <Stack direction="horizontal" gap={1} style={{ gridArea: "2 / 2" }}>
            {formatClearDuration(player.maps_clear_duration ?? 0)} <TimeIcon />
          </Stack>
          <Stack direction="horizontal" gap={1} style={{ gridArea: "2 / 3" }}>
            {numberWithCommas(player.maps_clear_deaths ?? 0)} <DeathsIcon />
          </Stack>
        </div>

        <div
          style={{
            gridArea: "goldens",
            ...briefStatsGrid,
          }}
        >
          <div style={{ gridArea: "1 / 1 / 2 / 5" }}>
            <b>
              <u>Goldens</u>
            </b>
          </div>
          <Stack direction="horizontal" gap={1} style={{ gridArea: "2 / 1" }}>
            {player.goldens_cleared} <GoldberryIcon />
          </Stack>

          <Stack direction="horizontal" gap={1} style={{ gridArea: "2 / 2" }}>
            {formatClearDuration(player.goldens_clear_duration ?? 0)} <TimeIcon />
          </Stack>
          <Stack direction="horizontal" gap={1} style={{ gridArea: "2 / 3" }}>
            {numberWithCommas(player.goldens_clear_deaths ?? 0)} <DeathsIcon />
          </Stack>
        </div>

        <div className="players-list-tier-badges-container">
          <Stack gap={2}>
            <b>
              <u>Goldens by Tier</u>
            </b>
            <Stack direction="horizontal" gap={1} className="players-list-tier-badges">
              {player.count_tier_0 !== 0 && (
                <SheetDifficultyBadge id={1} text={player.count_tier_0 + "x "} noSubtier />
              )}
              {player.count_tier_1 !== 0 && (
                <SheetDifficultyBadge id={4} text={player.count_tier_1 + "x "} noSubtier />
              )}
              {player.count_tier_2 !== 0 && (
                <SheetDifficultyBadge id={7} text={player.count_tier_2 + "x "} noSubtier />
              )}
              {player.count_tier_3 !== 0 && (
                <SheetDifficultyBadge id={10} text={player.count_tier_3 + "x "} noSubtier />
              )}
              {player.count_tier_4 !== 0 && (
                <SheetDifficultyBadge id={14} text={player.count_tier_4 + "x "} noSubtier />
              )}
              {player.count_tier_5 !== 0 && (
                <SheetDifficultyBadge id={15} text={player.count_tier_5 + "x "} noSubtier />
              )}
              {player.count_tier_6 !== 0 && (
                <SheetDifficultyBadge id={16} text={player.count_tier_6 + "x "} noSubtier />
              )}
              {player.count_tier_7 !== 0 && (
                <SheetDifficultyBadge id={17} text={player.count_tier_7 + "x "} noSubtier />
              )}
              {player.count_standard !== 0 && (
                <SheetDifficultyBadge id={18} text={player.count_standard + "x "} noSubtier />
              )}
            </Stack>
          </Stack>
        </div>

        <div style={{ gridArea: "sep1", ...separatorStyle }} />
        <div style={{ gridArea: "sep2", ...separatorStyle }} />
        <div style={{ gridArea: "sep3", ...separatorStyle }} />
      </div>
    </Link>
  );
}
