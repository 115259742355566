import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export function useModal(defaultData, onClose, onOpen = undefined) {
  const [isVisible, setIsVisible] = useState(false);
  const [data, setData] = useState(defaultData);
  const [storedTitle] = useState(document.title);

  const open = (data) => {
    setData(data);
    if (typeof onOpen === "function") onOpen(data);
    setIsVisible(true);
  };
  const close = (cancelled = false, newData = undefined) => {
    let ret = onClose(cancelled, newData ?? data);
    if (ret !== false) {
      setIsVisible(false);
      document.title = storedTitle;
    }
  };
  const cancel = () => close(true);

  return { isVisible, data, setData, open, close, cancel };
}

export function CustomModal({ modalHook, children, options }) {
  const { isVisible, close, cancel } = modalHook;

  let title = options?.title ?? "Modal Title";
  let acceptText = options?.acceptText ?? "Accept";
  let acceptVariant = options?.acceptVariant ?? "primary";
  let cancelText = options?.cancelText ?? "Cancel";
  let cancelVariant = options?.cancelVariant ?? "secondary";
  let hideFooter = options?.hideFooter ?? false;

  return (
    <Modal show={isVisible} onHide={modalHook.cancel}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {!hideFooter && (
        <Modal.Footer>
          <Button variant={acceptVariant} onClick={() => close(false)}>
            {acceptText}
          </Button>
          {cancelText !== "" && (
            <Button variant={cancelVariant} onClick={cancel} className="ms-auto">
              {cancelText}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
}
