import { useRef, useState } from "react";
import {
  Button,
  Container,
  Stack,
  Tabs,
  Tab,
  ListGroup,
  ProgressBar,
  Row,
  Col,
  Badge,
  Card,
  Form,
  Modal,
} from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  formatClearDuration,
  getDifficultyClass,
  getEnjoymentClass,
  numberWithCommas,
  getMapName,
} from "./StatsUtil";
import { ErrorContainer, LoadingContainer } from "../../components/MiscComponents";
import { groupListByProperty, sortListByProperties } from "./StatsUtil";

import clear from "../../assets/journal/clear.png";
import time from "../../assets/journal/time.png";
import skullA from "../../assets/journal/skullA.png";
import goldberryGif from "../../assets/journal/goldberry_animated_highres.gif";

import { faArrowUpRightFromSquare, faCheck, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QuickEditMap } from "./QuickEditMap";
import { useUserSession } from "../../hooks/useUserSession";
import {
  anyLoading,
  cloneCampaignToOwnAccount,
  getCampaignInfo,
  getCampaignMapList,
  getFirstAxiosError,
  getPlayerCampaignList,
  getPlayerMapRanks,
  parseAxiosError,
} from "../../hooks/CelesteStatsApi";
import { CustomModal, useModal } from "../../hooks/useModal";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { PlayerGeneralStats } from "./Player";

let progressStyle = { border: "1px solid lightgray", width: "100%" };
let rowStyle = { borderBottom: "1px solid #ddd" };

export default function CampaignPage() {
  let { playerName, campaignId, tab } = useParams();
  let navigate = useNavigate();
  let [currentTab, setCurrentTab] = useState(tab ?? "info");
  const userSession = useUserSession();
  const isOwnPage = userSession.isOwnPage(playerName);

  const campaignInfoQuery = useQuery({
    queryKey: ["campaignInfo", campaignId, playerName],
    queryFn: () => getCampaignInfo(campaignId, playerName),
  });
  const campaignMapListQuery = useQuery({
    queryKey: ["campaignMapList", campaignId],
    queryFn: () => getCampaignMapList(campaignId),
  });
  const campaignMapRanksQuery = useQuery({
    queryKey: ["campaignMapRanks", campaignId, playerName],
    queryFn: () => getPlayerMapRanks(playerName, campaignId),
  });
  const userCampaignsQuery = useQuery({
    queryKey: ["userCampaigns", userSession.user?.name],
    queryFn: () => getPlayerCampaignList(userSession.user?.name),
    enabled: userSession.isLoggedIn,
  });

  const modalRefs = {
    openCopyModal: useRef(),
    openEditMapModal: useRef(),
  };

  function setTab(tab) {
    setCurrentTab(tab);
    if (tab === "info") {
      navigate("/player/" + playerName + "/campaign/" + campaignId, {
        replace: true,
      });
    } else {
      navigate("/player/" + playerName + "/campaign/" + campaignId + "/" + tab, { replace: true });
    }
  }

  let anyError = getFirstAxiosError([
    campaignInfoQuery,
    campaignMapListQuery,
    campaignMapRanksQuery,
    userCampaignsQuery,
  ]);
  if (anyError !== null) {
    return (
      <Container>
        <PlayerGeneralStats playerName={playerName} />
        <ErrorContainer title={"Campaign"} message={anyError.message} />
      </Container>
    );
  } else if (
    anyLoading([campaignInfoQuery, campaignMapListQuery, campaignMapRanksQuery, userCampaignsQuery])
  ) {
    return (
      <Container>
        <PlayerGeneralStats playerName={playerName} />
        <LoadingContainer title={"Campaign"} />
      </Container>
    );
  }

  const campaignInfo = campaignInfoQuery.data;
  document.title = playerName + "'s '" + campaignInfo.name + "' Stats - Celeste Stats";

  let embedUrl = null;
  if (campaignInfo.url !== null && campaignInfo.url !== "" && campaignInfo.url.includes("gamebanana.com")) {
    //Get id from url like https://gamebanana.com/{type}/{id}
    let urlParts = campaignInfo.url.split("/");
    let id = urlParts[urlParts.length - 1];
    let type = urlParts[urlParts.length - 2];

    embedUrl = "https://gamebanana.com/" + type + "/embeddables/" + id + "?type=large";
  }

  let userHasThisCampaign = false;
  let userCampaignId = null;
  if (userSession.isLoggedIn) {
    //Find campaign id
    let userCampaign = userCampaignsQuery.data.find((campaign) => campaign.name === campaignInfo.name);
    if (userCampaign !== undefined) {
      userHasThisCampaign = true;
      userCampaignId = userCampaign.id;
    }
  }

  return (
    <Container>
      <PlayerGeneralStats playerName={playerName} />
      <Container>
        <Stack gap={3}>
          <Stack direction="horizontal" gap={3}>
            <h1 className="my-auto">{campaignInfo.name}</h1>
            {isOwnPage ? (
              <Link to={"/player/" + playerName + "/edit-campaign/" + campaignId}>
                <Button variant="primary">Edit</Button>
              </Link>
            ) : null}
            {userSession.isLoggedIn && !isOwnPage && !userHasThisCampaign ? (
              <Button variant="primary" onClick={() => modalRefs.openCopyModal.current(campaignInfo)}>
                Copy Campaign
              </Button>
            ) : null}
            {userSession.isLoggedIn && !isOwnPage && userHasThisCampaign ? (
              <span className="text-secondary">
                You have{" "}
                <Link to={"/player/" + userSession.user.name + "/campaign/" + userCampaignId}>
                  this campaign
                </Link>{" "}
                <FontAwesomeIcon className="text-success" icon={faCheck} />
              </span>
            ) : null}
            {embedUrl !== null && campaignInfo.url !== "" ? (
              <Link to={campaignInfo.url} target="_blank" className="ms-auto">
                <Card>
                  <Card.Img variant="top" src={embedUrl} width={350} height={75} />
                </Card>
              </Link>
            ) : null}
          </Stack>
          <Tabs activeKey={currentTab} onSelect={(k) => setTab(k)} className="mb-1" transition={false}>
            <Tab eventKey="info" title="Info">
              <CampaignInfo campaignInfo={campaignInfo} campaignMapList={campaignMapListQuery.data} />
            </Tab>
            <Tab eventKey="clears" title="Clears">
              <ClearList
                campaignInfo={campaignInfo}
                campaignMapList={campaignMapListQuery.data}
                campaignMapRanks={campaignMapRanksQuery.data}
                isClears={true}
                isOwnPage={isOwnPage}
                editMapModalCallback={modalRefs.openEditMapModal}
              />
            </Tab>
            <Tab eventKey="goldens" title="Goldens">
              <ClearList
                campaignInfo={campaignInfo}
                campaignMapList={campaignMapListQuery.data}
                campaignMapRanks={campaignMapRanksQuery.data}
                isClears={false}
                isOwnPage={isOwnPage}
                editMapModalCallback={modalRefs.openEditMapModal}
              />
            </Tab>
            <Tab eventKey="golden-tracker" title="Golden Tracker">
              Tab content for Golden Tracker
            </Tab>
          </Tabs>
        </Stack>
      </Container>

      <ModalContainer
        campaignId={campaignId}
        playerName={playerName}
        userSession={userSession}
        modalRefs={modalRefs}
      />
    </Container>
  );
}

function ModalContainer({ campaignId, playerName, userSession, modalRefs }) {
  const queryClient = useQueryClient();
  const { mutate: cloneCampaign } = useMutation({
    mutationFn: (id) => cloneCampaignToOwnAccount(id),
    onSuccess: () => {
      toast.success("Cloned campaign to your account!");
      queryClient.invalidateQueries(["userCampaigns", userSession.user.name]);
      queryClient.invalidateQueries(["playerMapList", playerName]);
      queryClient.invalidateQueries(["playerMapMapRanks", playerName]);
      queryClient.invalidateQueries(["playerGeneralStats", playerName]);
    },
    onError: (error) => {
      toast.error("Couldn't clone campaign: " + parseAxiosError(error).message);
    },
  });

  const copyModal = useModal(null, (cancelled, data) => {
    if (cancelled) return;
    cloneCampaign(data.id);
  });

  const editModal = useModal(null, (cancelled, mapData) => {
    //Invalidate campaignMapList and campaignMapRanks queries
    queryClient.invalidateQueries(["campaignInfo", campaignId, playerName]);
    queryClient.invalidateQueries(["campaignMapList", campaignId]);
    queryClient.invalidateQueries(["campaignMapRanks", campaignId, playerName]);

    queryClient.invalidateQueries(["playerMapList", playerName]);
    queryClient.invalidateQueries(["playerMapMapRanks", playerName]);
    queryClient.invalidateQueries(["playerGeneralStats", playerName]);

    if (cancelled) return;

    toast.success("Map updated!");
  });

  modalRefs.openCopyModal.current = copyModal.open;
  modalRefs.openEditMapModal.current = editModal.open;

  return (
    <>
      <Modal show={editModal.isVisible} onHide={editModal.cancel} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Quick Edit Map</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuickEditMap
            playerName={playerName}
            id={editModal.data}
            onSave={(mapData) => editModal.close(false, mapData)}
            onCancel={editModal.cancel}
          />
        </Modal.Body>
      </Modal>

      <CustomModal modalHook={copyModal} options={{ title: "Copy Campaign", acceptText: "Copy" }}>
        Do you want to copy the campaign <strong>{copyModal?.data?.name}</strong> to your account?
        <br />
        This will include all settings and <strong>{copyModal?.data?.maps_count}</strong> map(s), but no stats
        or goldens!
      </CustomModal>
    </>
  );
}

function getSortInfo(campaignInfo) {
  return {
    sortMajorName: campaignInfo.sort_major_name,
    sortMajorLabels: campaignInfo.sort_major_labels !== null ? campaignInfo.sort_major_labels.split(",") : [],
    sortMajorAccentColors:
      campaignInfo.sort_major_accent_colors !== null ? campaignInfo.sort_major_accent_colors.split(",") : [],
    sortMinorName: campaignInfo.sort_minor_name,
    sortMinorLabels: campaignInfo.sort_minor_labels !== null ? campaignInfo.sort_minor_labels.split(",") : [],
    sortMinorAccentColors:
      campaignInfo.sort_minor_accent_colors !== null ? campaignInfo.sort_minor_accent_colors.split(",") : [],
  };
}

function CampaignInfo({ campaignInfo, campaignMapList }) {
  let mapsDifficultyClass = getDifficultyClass(campaignInfo.maps_avg_difficulty);
  let mapsEnjoymentClass = getEnjoymentClass(campaignInfo.maps_avg_enjoyment);
  let goldensDifficultyClass = getDifficultyClass(campaignInfo.goldens_avg_difficulty);
  let goldensEnjoymentClass = getEnjoymentClass(campaignInfo.goldens_avg_enjoyment);

  let sort = getSortInfo(campaignInfo);

  let groupClasses = "d-flex justify-content-between align-items-center";
  return (
    <>
      <Row>
        <Col xl={4}>
          <h3>Meta Info</h3>
          <ListGroup>
            <ListGroup.Item className={groupClasses}>
              <span className="fw-bold">Campaign Name</span>
              {campaignInfo.name}
            </ListGroup.Item>
            <ListGroup.Item className={groupClasses}>
              <span className="fw-bold">Player Name</span>
              <Link to={"/player/" + campaignInfo.user_name}>{campaignInfo.user_name}</Link>
            </ListGroup.Item>
            <ListGroup.Item className={groupClasses}>
              <span className="fw-bold">Maps in Campaign</span>
              {campaignInfo.maps_count} Map
              {campaignInfo.maps_count === 1 ? "" : "s"}
            </ListGroup.Item>

            {campaignInfo.url !== null && campaignInfo.url !== "" ? (
              <ListGroup.Item className={groupClasses}>
                <span className="fw-bold">URL</span>
                <Link to={campaignInfo.url} target="_blank">
                  {campaignInfo.url}{" "}
                  <FontAwesomeIcon style={{ color: "lightgray" }} icon={faArrowUpRightFromSquare} />
                </Link>
              </ListGroup.Item>
            ) : null}

            {sort.sortMajorName !== null ? (
              <>
                <ListGroup.Item className={groupClasses}>
                  <span className="fw-bold">{sort.sortMajorName}</span>
                </ListGroup.Item>
                <ListGroup.Item className={groupClasses}>
                  <Stack direction="horizontal" gap={1}>
                    {sort.sortMajorLabels.map((name, index) => (
                      <Badge key={index} bg="secondary">
                        {name}
                      </Badge>
                    ))}
                  </Stack>
                </ListGroup.Item>
              </>
            ) : null}

            {sort.sortMinorName !== null ? (
              <>
                <ListGroup.Item className={groupClasses}>
                  <span className="fw-bold">{sort.sortMinorName}</span>
                </ListGroup.Item>
                <ListGroup.Item className={groupClasses}>
                  <Stack direction="horizontal" gap={1}>
                    {sort.sortMinorLabels.map((name, index) => (
                      <Badge key={index} bg="secondary">
                        {name}
                      </Badge>
                    ))}
                  </Stack>
                </ListGroup.Item>
              </>
            ) : null}
          </ListGroup>
        </Col>
        <Col xl={4}>
          <h3>Clears</h3>
          <ListGroup>
            <ListGroup.Item className={groupClasses}>
              <span className="fw-bold">Maps Cleared</span>
              <span>
                {campaignInfo.maps_cleared} / {campaignInfo.maps_count}{" "}
                <img src={clear} width={20} height={20} alt="" />
              </span>
            </ListGroup.Item>
            <ListGroup.Item className={groupClasses}>
              <span className="fw-bold">Total Clear Time</span>
              <span>
                {formatClearDuration(campaignInfo.maps_clear_duration ? campaignInfo.maps_clear_duration : 0)}{" "}
                <img src={time} width={20} height={20} alt="" />
              </span>
            </ListGroup.Item>
            <ListGroup.Item className={groupClasses}>
              <span className="fw-bold">Total Clear Deaths</span>
              <span>
                {numberWithCommas(campaignInfo.maps_clear_deaths ? campaignInfo.maps_clear_deaths : 0)}{" "}
                <img src={skullA} width={20} height={20} alt="" />
              </span>
            </ListGroup.Item>

            <ListGroup.Item className={groupClasses}>
              <span className="fw-bold">Maps Avg. Difficulty</span>
            </ListGroup.Item>
            <ListGroup.Item className={groupClasses}>
              <ProgressBar
                style={progressStyle}
                variant={mapsDifficultyClass}
                now={campaignInfo.maps_avg_difficulty}
                max={100}
                label={`${Math.round(campaignInfo.maps_avg_difficulty * 100) / 100}`}
              />
            </ListGroup.Item>

            <ListGroup.Item className={groupClasses}>
              <span className="fw-bold">Maps Avg. Enjoyment</span>
            </ListGroup.Item>
            <ListGroup.Item className={groupClasses}>
              <ProgressBar
                style={progressStyle}
                variant={mapsEnjoymentClass}
                now={campaignInfo.maps_avg_enjoyment}
                max={10}
                label={`${Math.round(campaignInfo.maps_avg_enjoyment * 100) / 100}`}
              />
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col xl={4}>
          <h3>Goldens</h3>
          <ListGroup>
            <ListGroup.Item className={groupClasses}>
              <span className="fw-bold">Maps Goldened</span>
              <span>
                {campaignInfo.goldens_cleared ? campaignInfo.goldens_cleared : 0} / {campaignInfo.maps_count}{" "}
                <img src={goldberryGif} width={18} height={16} alt="" />
              </span>
            </ListGroup.Item>
            <ListGroup.Item className={groupClasses}>
              <span className="fw-bold">Total Golden Time</span>
              <span>
                {formatClearDuration(
                  campaignInfo.goldens_clear_duration ? campaignInfo.goldens_clear_duration : 0
                )}{" "}
                <img src={time} width={20} height={20} alt="" />
              </span>
            </ListGroup.Item>
            <ListGroup.Item className={groupClasses}>
              <span className="fw-bold">Total Golden Deaths</span>
              <span>
                {numberWithCommas(campaignInfo.goldens_clear_deaths ? campaignInfo.goldens_clear_deaths : 0)}{" "}
                <img src={skullA} width={20} height={20} alt="" />
              </span>
            </ListGroup.Item>

            <ListGroup.Item className={groupClasses}>
              <span className="fw-bold">Goldens Avg. Difficulty</span>
            </ListGroup.Item>
            <ListGroup.Item className={groupClasses}>
              <ProgressBar
                style={progressStyle}
                variant={goldensDifficultyClass}
                now={campaignInfo.goldens_avg_difficulty}
                max={100}
                label={`${Math.round(campaignInfo.goldens_avg_difficulty * 100) / 100}`}
              />
            </ListGroup.Item>

            <ListGroup.Item className={groupClasses}>
              <span className="fw-bold">Goldens Avg. Enjoyment</span>
            </ListGroup.Item>
            <ListGroup.Item className={groupClasses}>
              <ProgressBar
                style={progressStyle}
                variant={goldensEnjoymentClass}
                now={campaignInfo.goldens_avg_enjoyment}
                max={10}
                label={`${Math.round(campaignInfo.goldens_avg_enjoyment * 100) / 100}`}
              />
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      {sort.sortMajorName !== null ? (
        <LobbyInfo
          campaignMapList={campaignMapList}
          sortMajorName={sort.sortMajorName}
          sortMajorLabels={sort.sortMajorLabels}
        />
      ) : null}
    </>
  );
}

function LobbyInfo({ campaignMapList, sortMajorName, sortMajorLabels }) {
  //there can be arbitrary number of groups
  //the groups should be displayed in a <Col>, which is wrapped in a <Row>
  //BUT there should be a max of 3 groups per row

  //so we need to split the groups into groups of 3
  //then we can map over those groups and display them in a row

  const groupedLobbyMapList = groupListByProperty(campaignMapList, "sort_major");
  const keys = Object.keys(groupedLobbyMapList);
  keys.sort();

  let groupedList = [];
  let group = [];

  for (let i = 0; i < keys.length; i++) {
    group.push(groupedLobbyMapList[keys[i]]);
    if (group.length === 3) {
      groupedList.push(group);
      group = [];
    }
  }

  if (group.length > 0) {
    groupedList.push(group);
  }

  return (
    <>
      <hr />
      <h3>{sortMajorName}</h3>
      {groupedList.map((group, index) => (
        <LobbyInfoGroup key={index} index={index} group={group} sortMajorLabels={sortMajorLabels} />
      ))}
    </>
  );
}

function LobbyInfoGroup({ index, group, sortMajorLabels }) {
  return (
    <>
      {index > 0 ? <hr /> : null}
      <Row>
        {group.map((lobbyMaps, index) => (
          <LobbyInfoCol key={index} lobbyMaps={lobbyMaps} sortMajorLabels={sortMajorLabels} />
        ))}
        {/* {group.length < 3 ? <Col></Col> : null}
        {group.length < 2 ? <Col></Col> : null} */}
      </Row>
    </>
  );
}

function LobbyInfoCol({ lobbyMaps, sortMajorLabels }) {
  let groupClasses = "d-flex justify-content-between align-items-center";
  let progressStyle = { border: "1px solid lightgray", width: "100%" };

  let sortMajorNum = lobbyMaps[0].sort_major;
  let sortMajorLabel = sortMajorLabels[sortMajorNum - 1];

  let mapCount = lobbyMaps.length;

  let clearCount = lobbyMaps.reduce((total, lobbyMap) => total + lobbyMap.cleared, 0);
  let clearDuration = lobbyMaps.reduce((total, lobbyMap) => total + lobbyMap.clear_duration, 0);
  let clearDeaths = lobbyMaps.reduce((total, lobbyMap) => total + lobbyMap.clear_deaths, 0);

  let goldenCount = lobbyMaps.reduce((total, lobbyMap) => total + lobbyMap.golden_cleared, 0);
  let goldenDuration = lobbyMaps.reduce((total, lobbyMap) => total + lobbyMap.golden_duration, 0);
  let goldenDeaths = lobbyMaps.reduce((total, lobbyMap) => total + lobbyMap.golden_deaths, 0);

  let avgClearDifficulty = lobbyMaps.reduce((total, lobbyMap) => total + lobbyMap.difficulty, 0) / clearCount;
  avgClearDifficulty = isNaN(avgClearDifficulty) ? 0 : avgClearDifficulty;
  let avgClearEnjoyment = lobbyMaps.reduce((total, lobbyMap) => total + lobbyMap.enjoyment, 0) / clearCount;
  avgClearEnjoyment = isNaN(avgClearEnjoyment) ? 0 : avgClearEnjoyment;
  let clearDifficultyClass = getDifficultyClass(avgClearDifficulty);
  let clearEnjoymentClass = getEnjoymentClass(avgClearEnjoyment);

  let avgGoldenDifficulty =
    lobbyMaps.reduce((total, lobbyMap) => total + lobbyMap.golden_one_difficulty, 0) / goldenCount;
  avgGoldenDifficulty = isNaN(avgGoldenDifficulty) ? 0 : avgGoldenDifficulty;
  let avgGoldenEnjoyment =
    lobbyMaps.reduce((total, lobbyMap) => total + lobbyMap.golden_one_enjoyment, 0) / goldenCount;
  avgGoldenEnjoyment = isNaN(avgGoldenEnjoyment) ? 0 : avgGoldenEnjoyment;
  let goldenDifficultyClass = getDifficultyClass(avgGoldenDifficulty);
  let goldenEnjoymentClass = getEnjoymentClass(avgGoldenEnjoyment);

  return (
    <Col xl={4}>
      <h5 style={{ marginTop: "1rem" }} className="fw-bold">
        {sortMajorLabel}
      </h5>
      <ListGroup>
        <ListGroup.Item className={groupClasses}>
          <span className="fw-bold">Maps Cleared</span>
          <span>
            {clearCount} / {mapCount} <img src={clear} width={20} height={20} alt="" />
          </span>
        </ListGroup.Item>
        <ListGroup.Item className={groupClasses}>
          <span>Clear Time</span>
          <span>
            {formatClearDuration(clearDuration)} <img src={time} width={20} height={20} alt="" />
          </span>
        </ListGroup.Item>
        <ListGroup.Item className={groupClasses}>
          <span>Clear Deaths</span>
          <span>
            {numberWithCommas(clearDeaths)} <img src={skullA} width={20} height={20} alt="" />
          </span>
        </ListGroup.Item>

        <ListGroup.Item className={groupClasses}>
          <span>Clear Avg. Difficulty</span>
        </ListGroup.Item>
        <ListGroup.Item className={groupClasses}>
          <ProgressBar
            style={progressStyle}
            variant={clearDifficultyClass}
            now={avgClearDifficulty}
            max={100}
            label={`${Math.round(avgClearDifficulty * 100) / 100}`}
          />
        </ListGroup.Item>

        <ListGroup.Item className={groupClasses}>
          <span>Clear Avg. Enjoyment</span>
        </ListGroup.Item>
        <ListGroup.Item className={groupClasses}>
          <ProgressBar
            style={progressStyle}
            variant={clearEnjoymentClass}
            now={avgClearEnjoyment}
            max={10}
            label={`${Math.round(avgClearEnjoyment * 100) / 100}`}
          />
        </ListGroup.Item>
      </ListGroup>
      <ListGroup style={{ marginTop: "0.5rem" }}>
        <ListGroup.Item className={groupClasses}>
          <span className="fw-bold">Maps Goldened</span>
          <span>
            {goldenCount} / {mapCount} <img src={goldberryGif} width={18} height={16} alt="" />
          </span>
        </ListGroup.Item>
        <ListGroup.Item className={groupClasses}>
          <span>Golden Time</span>
          <span>
            {formatClearDuration(goldenDuration)} <img src={time} width={20} height={20} alt="" />
          </span>
        </ListGroup.Item>
        <ListGroup.Item className={groupClasses}>
          <span>Golden Deaths</span>
          <span>
            {numberWithCommas(goldenDeaths)} <img src={skullA} width={20} height={20} alt="" />
          </span>
        </ListGroup.Item>

        <ListGroup.Item className={groupClasses}>
          <span>Golden Avg. Difficulty</span>
        </ListGroup.Item>
        <ListGroup.Item className={groupClasses}>
          <ProgressBar
            style={progressStyle}
            variant={goldenDifficultyClass}
            now={avgGoldenDifficulty}
            max={100}
            label={`${Math.round(avgGoldenDifficulty * 100) / 100}`}
          />
        </ListGroup.Item>

        <ListGroup.Item className={groupClasses}>
          <span>Golden Avg. Enjoyment</span>
        </ListGroup.Item>
        <ListGroup.Item className={groupClasses}>
          <ProgressBar
            style={progressStyle}
            variant={goldenEnjoymentClass}
            now={avgGoldenEnjoyment}
            max={10}
            label={`${Math.round(avgGoldenEnjoyment * 100) / 100}`}
          />
        </ListGroup.Item>
      </ListGroup>
    </Col>
  );
}

function ClearList({
  campaignInfo,
  campaignMapList,
  campaignMapRanks,
  isClears,
  isOwnPage,
  editMapModalCallback,
}) {
  let clearHeader = isClears ? (
    <img src={clear} width={26} height={26} alt="" />
  ) : (
    <img src={goldberryGif} width={29.25} height={26} alt="" />
  );

  let sorts = getSortInfo(campaignInfo);
  if (sorts.sortMajorName !== null) {
    let campaignMapListGrouped = groupListByProperty(campaignMapList, "sort_major");
    let keys = Object.keys(campaignMapListGrouped);
    keys.sort();

    return (
      <>
        {isClears ? <h2>Clear Stats</h2> : <h2>Golden Stats</h2>}
        {keys.map((key, index) => {
          let group = campaignMapListGrouped[key];
          return (
            <MajorRow
              key={index}
              campaignInfo={campaignInfo}
              filteredMapList={group}
              campaignMapRanks={campaignMapRanks}
              isClears={isClears}
              isOwnPage={isOwnPage}
              editMapModalCallback={editMapModalCallback}
            />
          );
        })}
      </>
    );
  } else if (sorts.sortMinorName !== null) {
    let campaignMapListGrouped = groupListByProperty(campaignMapList, "sort_minor");
    let keys = Object.keys(campaignMapListGrouped);
    keys.sort();

    return (
      <>
        {isClears ? <h2>Clear Stats</h2> : <h2>Golden Stats</h2>}
        <Row>
          <Col sm={1}></Col>
          <Col sm={11}>
            <MapRowHeader clearHeader={clearHeader} />
          </Col>
        </Row>
        {keys.map((key, index) => {
          let group = campaignMapListGrouped[key];
          return (
            <MinorRow
              key={index}
              campaignInfo={campaignInfo}
              filteredMapList={group}
              campaignMapRanks={campaignMapRanks}
              isClears={isClears}
              isOwnPage={isOwnPage}
              editMapModalCallback={editMapModalCallback}
            />
          );
        })}
      </>
    );
  }

  sortListByProperties(campaignMapList, ["sort_major", "sort_minor", "sort_order"]);

  return (
    <>
      {isClears ? <h2>Clear Stats</h2> : <h2>Golden Stats</h2>}
      <MapRowHeader clearHeader={clearHeader} />
      {campaignMapList.map((mapData) => {
        //find map rank by map id in list of objects
        return (
          <MapRow
            key={mapData.id}
            campaignInfo={campaignInfo}
            mapData={mapData}
            campaignMapRanks={campaignMapRanks}
            isClears={isClears}
            isOwnPage={isOwnPage}
            editMapModalCallback={editMapModalCallback}
          />
        );
      })}
    </>
  );
}
function MajorRow({
  campaignInfo,
  filteredMapList,
  campaignMapRanks,
  isClears,
  isOwnPage,
  editMapModalCallback,
}) {
  let clearHeader = isClears ? (
    <img src={clear} width={26} height={26} alt="" />
  ) : (
    <img src={goldberryGif} width={29.25} height={26} alt="" />
  );
  let sorts = getSortInfo(campaignInfo);
  let sortMajorNum = filteredMapList[0].sort_major;
  let sortMajorLabel = sorts.sortMajorLabels[sortMajorNum - 1];
  let majorAccentColor = sorts.sortMajorAccentColors[sortMajorNum - 1] ?? null;
  let borderStyle = majorAccentColor
    ? { borderLeft: `5px solid ${majorAccentColor}`, paddingLeft: "12px" }
    : { borderLeft: "5px solid #fff" };

  if (sorts.sortMinorName !== null) {
    let campaignMapListGrouped = groupListByProperty(filteredMapList, "sort_minor");
    let keys = Object.keys(campaignMapListGrouped);
    keys.sort();

    return (
      <>
        <h3 className="fw-bold mt-3">{sortMajorLabel}</h3>
        <div style={borderStyle}>
          <Row>
            <Col sm={1}></Col>
            <Col sm={11}>
              <MapRowHeader clearHeader={clearHeader} />
            </Col>
          </Row>
          {keys.map((key, index) => {
            let group = campaignMapListGrouped[key];
            return (
              <MinorRow
                key={index}
                campaignInfo={campaignInfo}
                filteredMapList={group}
                campaignMapRanks={campaignMapRanks}
                isClears={isClears}
                isOwnPage={isOwnPage}
                editMapModalCallback={editMapModalCallback}
              />
            );
          })}
        </div>
      </>
    );
  }

  sortListByProperties(filteredMapList, ["sort_2", "sort_3"]);

  return (
    <>
      <h3 className="fw-bold mt-3">{sortMajorLabel}</h3>
      <MapRowHeader clearHeader={clearHeader} />
      {filteredMapList.map((mapData) => (
        <MapRow
          key={mapData.id}
          campaignInfo={campaignInfo}
          mapData={mapData}
          campaignMapRanks={campaignMapRanks}
          isClears={isClears}
          isOwnPage={isOwnPage}
          editMapModalCallback={editMapModalCallback}
        />
      ))}
    </>
  );
}

function MinorRow({
  campaignInfo,
  filteredMapList,
  campaignMapRanks,
  isClears,
  isOwnPage,
  editMapModalCallback,
}) {
  let sorts = getSortInfo(campaignInfo);
  let sortNum = filteredMapList[0].sort_minor;
  let minorName = sorts.sortMinorLabels[sortNum - 1];
  let minorAccentColor = sorts.sortMinorAccentColors[sortNum - 1] ?? "white";

  let nameStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "180%",
    rotate: "-90deg",
  };
  let singleNameStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
  };

  sortListByProperties(filteredMapList, ["sort_order"]);

  return (
    <Row style={{ backgroundColor: minorAccentColor }}>
      <Col sm={1} style={rowStyle} className="d-none d-lg-flex">
        <div style={filteredMapList.length > 2 ? nameStyle : singleNameStyle}>{minorName}</div>
      </Col>
      <Col sm={11}>
        {filteredMapList.map((mapData) => (
          <MapRow
            key={mapData.id}
            campaignInfo={campaignInfo}
            mapData={mapData}
            campaignMapRanks={campaignMapRanks}
            isClears={isClears}
            isOwnPage={isOwnPage}
            editMapModalCallback={editMapModalCallback}
          />
        ))}
      </Col>
    </Row>
  );
}
function MapRow({ campaignInfo, mapData, campaignMapRanks, isClears, isOwnPage, editMapModalCallback }) {
  let { playerName } = useParams();
  let mapRanks = campaignMapRanks.find((mapRanks) => mapRanks.id === mapData.id) ?? {
    difficulty_rank: "-",
    enjoyment_rank: "-",
    golden_difficulty_rank: "-",
    golden_enjoyment_rank: "-",
  };

  let mapName = getMapName(mapData);
  let mapId = mapData.id;
  let cleared = isClears ? mapData.cleared : mapData.golden_cleared;
  let clearElement =
    cleared !== null && cleared !== 0 ? (
      isClears ? (
        <img src={clear} width={20} height={20} alt="" />
      ) : (
        <img src={goldberryGif} width={22.5} height={20} alt="" />
      )
    ) : (
      <Form>
        <Form.Check disabled defaultChecked={false} />
      </Form>
    );
  let duration = isClears ? mapData.clear_duration : mapData.golden_duration;
  let deaths = isClears ? mapData.clear_deaths : mapData.golden_deaths;

  let difficulty = isClears ? mapData.difficulty : mapData.golden_one_difficulty;
  let difficultyClass = getDifficultyClass(difficulty);
  let difficultyRank =
    difficulty === null ? "" : "#" + (isClears ? mapRanks.difficulty_rank : mapRanks.golden_difficulty_rank);

  let enjoyment = isClears ? mapData.enjoyment : mapData.golden_one_enjoyment;
  let enjoymentClass = getEnjoymentClass(enjoyment);
  let enjoymentRank =
    enjoyment === null ? "" : "#" + (isClears ? mapRanks.enjoyment_rank : mapRanks.golden_enjoyment_rank);

  return (
    <Row style={rowStyle} className="py-1">
      <Col style={{ flex: "40 0" }}>
        <Link to={"/player/" + playerName + "/map/" + mapId}>{mapName}</Link>
        {isOwnPage === true ? (
          <Link onClick={() => editMapModalCallback.current(mapId)}>
            <FontAwesomeIcon icon={faEdit} width={20} height={20} color="lightgray" />
          </Link>
        ) : null}
      </Col>
      <Col style={{ flex: "10 0" }} className="text-center my-auto">
        {clearElement}
      </Col>
      <Col style={{ flex: "15 0" }} className="text-center my-auto">
        {formatClearDuration(duration)}
      </Col>
      <Col style={{ flex: "10 0" }} className="text-center my-auto">
        {numberWithCommas(deaths)}
      </Col>
      <Col style={{ flex: "30 0" }} className="my-auto">
        <ProgressBar
          style={progressStyle}
          variant={difficultyClass}
          now={difficulty}
          max={100}
          label={`${Math.round(difficulty * 100) / 100}`}
        />
      </Col>
      <Col style={{ flex: "9 0" }} className="text-center my-auto">
        {difficultyRank}
      </Col>
      <Col style={{ flex: "30 0" }} className="my-auto">
        <ProgressBar
          style={progressStyle}
          variant={enjoymentClass}
          now={enjoyment}
          max={10}
          label={`${Math.round(enjoyment * 100) / 100}`}
        />
      </Col>
      <Col style={{ flex: "9 0" }} className="text-center my-auto">
        {enjoymentRank}
      </Col>
    </Row>
  );
}

function MapRowHeader({ clearHeader }) {
  return (
    <Row style={rowStyle} className="py-1">
      <Col style={{ flex: "40 0" }} className="fw-bold">
        Map Name
      </Col>
      <Col style={{ flex: "10 0" }} className="text-center mt-auto fw-bold">
        {clearHeader}
      </Col>
      <Col style={{ flex: "15 0" }} className="text-center my-auto">
        <img src={time} width={26} height={26} alt="" />
      </Col>
      <Col style={{ flex: "10 0" }} className="text-center my-auto">
        <img src={skullA} width={26} height={26} alt="" />
      </Col>
      <Col style={{ flex: "30 0" }} className="my-auto fw-bold">
        Difficulty
      </Col>
      <Col style={{ flex: "9 0" }} className="text-center my-auto fw-bold">
        Diff. #
      </Col>
      <Col style={{ flex: "30 0" }} className="my-auto fw-bold">
        Enjoyment
      </Col>
      <Col style={{ flex: "9 0" }} className="text-center my-auto fw-bold">
        Enjoy. #
      </Col>
    </Row>
  );
}
