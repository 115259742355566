import { useQuery, useQueryClient } from "react-query";
import {
  anyLoading,
  getFirstAxiosError,
  getPlayerMapData,
  getPlayerMapGoldenStats,
  getPlayerMapRanks,
  parseAxiosError,
} from "../../hooks/CelesteStatsApi";
import {
  DifficultyDisplay,
  EnjoymentDisplay,
  ErrorContainer,
  LoadingContainer,
  SheetDifficultyBadge,
} from "../../components/MiscComponents";
import "../../css/Map.css";
import { Card, Container, Modal, Stack } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { formatClearDuration, getGoldenDescription, getMapName, numberWithCommas } from "./StatsUtil";
import {
  ClearIcon,
  CrossIcon,
  DeathsIcon,
  EditIcon,
  GoldberryIcon,
  JournalIcon,
  TextWithIcon,
  TimeIcon,
  TurnDownRightArrowIcon,
  UserIcon,
  YouTubeIcon,
} from "../../components/Icons";
import { toast } from "react-toastify";
import { useUserSession } from "../../hooks/useUserSession";
import { PlayerGeneralStats } from "./Player";
import { useModal } from "../../hooks/useModal";
import { QuickEditMap } from "./QuickEditMap";

export default function MapPage() {
  const { playerName, mapId } = useParams();
  document.title = playerName + "'s Map Stats - Celeste Stats";
  const userSession = useUserSession();

  const mapDataQuery = useQuery({
    queryKey: ["mapData", playerName, mapId],
    queryFn: () => getPlayerMapData(playerName, mapId),
    onError: (error) => {
      toast.error("Couldn't fetch map data: " + parseAxiosError(error).message);
    },
  });
  const playerMapRanksQuery = useQuery({
    queryKey: ["playerMapRanks", playerName],
    queryFn: () => getPlayerMapRanks(playerName),
    onError: (error) => {
      toast.error("Couldn't fetch map ranks: " + parseAxiosError(error).message);
    },
  });
  const goldenRunsListQuery = useQuery({
    queryKey: ["goldenRunsList", mapId],
    queryFn: () => getPlayerMapGoldenStats(mapId),
  });

  if (anyLoading([mapDataQuery, playerMapRanksQuery, goldenRunsListQuery])) {
    return (
      <Container>
        <PlayerGeneralStats playerName={playerName} />
        <LoadingContainer title="Map Details" />
      </Container>
    );
  }
  const error = getFirstAxiosError([mapDataQuery, playerMapRanksQuery, goldenRunsListQuery]);
  if (error) {
    return (
      <Container>
        <PlayerGeneralStats playerName={playerName} />
        <ErrorContainer title="Map Details" message={error.message} />
      </Container>
    );
  }

  const map = mapDataQuery.data[0];
  const ranks = playerMapRanksQuery.data.filter((rank) => rank.id === map.id)[0] ?? {
    id: null,
    difficulty_rank: null,
    enjoyment_rank: null,
    golden_difficulty_rank: null,
    golden_enjoyment_rank: null,
  };

  document.title = playerName + "'s '" + getMapName(map) + "' Stats - Celeste Stats";

  return (
    <Container>
      <PlayerGeneralStats playerName={playerName} />
      <Container>
        <div id="map-details-grid">
          <MapHeader map={map} userSession={userSession} />
          <ClearStatsTable map={map} userSession={userSession} />
          <EnjoyDiffDisplay map={map} ranks={ranks} userSession={userSession} />
          <ProofEmbed url={map.proof_url} />
          <NotesBox map={map} userSession={userSession} />
          <GoldenRuns goldenRuns={goldenRunsListQuery.data} map={map} userSession={userSession} />
        </div>
      </Container>
    </Container>
  );
}

function MapHeader({ map, userSession }) {
  const queryClient = useQueryClient();
  const editModal = useModal(null, (cancelled, data) => {
    if (cancelled) return;
    queryClient.setQueryData(["mapData", map.user_name, map.id + ""], [data]);
  });

  return (
    <>
      <Modal show={editModal.isVisible} onHide={editModal.cancel} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Quick Edit Map</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuickEditMap
            playerName={map.user_name}
            id={editModal.data}
            onSave={(mapData) => editModal.close(false, mapData)}
            onCancel={editModal.cancel}
          />
        </Modal.Body>
      </Modal>

      <Stack direction="horizontal" className="map-details-col-span-2">
        <Stack direction="vertical">
          <Stack direction="horizontal" gap={1}>
            <h1>{getMapName(map)}</h1>
            {userSession.isOwnPage(map.user_name) && (
              <EditIcon
                className="ms-2"
                size="medium"
                color="lightgray"
                onClick={() => editModal.open(map.id)}
                style={{ cursor: "pointer" }}
              />
            )}
          </Stack>
          <CampaignInfo map={map} userSession={userSession} />
        </Stack>
        <GameBananaUrlEmbed
          url={map.url ?? map.campaign_url}
          classNames={["d-none", "d-lg-block"]}
          style={{ alignSelf: "start" }}
        />
      </Stack>
    </>
  );
}
function CampaignInfo({ map, userSession }) {
  if (map.campaign_id === null || map.campaign_id === undefined || map.campaign_id === "") {
    return null;
  }

  return (
    <TextWithIcon>
      <TurnDownRightArrowIcon size="tiny" /> Campaign
      <JournalIcon />-
      <Link to={"/player/" + map.user_name + "/campaign/" + map.campaign_id}>{map.campaign_name}</Link>
    </TextWithIcon>
  );
}
function EnjoyDiffDisplay({ map, ranks, userSession }) {
  const enjoymentRank = map.cleared ? (map.enjoyment ? ranks.enjoyment_rank : null) : null;
  const difficultyRank = map.cleared ? (map.difficulty ? ranks.difficulty_rank : null) : null;

  return (
    <Stack gap={1}>
      <Stack direction="horizontal" gap={1}>
        <h4 className="my-0 align-self-end">Enjoyment</h4>
        <h1 className="my-0 ms-auto" style={{ color: "#d2d2d2" }}>
          #{enjoymentRank ?? "-"}
        </h1>
      </Stack>
      <EnjoymentDisplay enjoyment={map.enjoyment} />
      <Stack direction="horizontal" gap={1}>
        <h4 className="my-0 align-self-end">Difficulty</h4>
        <h1 className="my-0 ms-auto" style={{ color: "#d2d2d2" }}>
          #{difficultyRank ?? "-"}
        </h1>
      </Stack>
      <DifficultyDisplay difficulty={map.difficulty} />
    </Stack>
  );
}
function ClearStatsTable({ map, userSession }) {
  const tableGrid = {
    gridTemplateColumns: "repeat(2, max-content)",
    gridTemplateRows: "repeat(4, max-content)",
    gap: "3px 10px",
    fontSize: "125%",
  };

  return (
    <div className="d-grid" style={tableGrid}>
      <div className="d-contents">
        <b>Cleared?</b>
        <span>{map.cleared ? <ClearIcon /> : "No"}</span>
      </div>
      <div className="d-contents">
        <b>Clear Time</b>
        <TextWithIcon>
          {map.cleared ? formatClearDuration(map.clear_duration ?? 0) : "-"}
          <TimeIcon />
        </TextWithIcon>
      </div>
      <div className="d-contents">
        <b>Clear Deaths</b>
        <TextWithIcon>
          {map.cleared ? numberWithCommas(map.clear_deaths ?? 0) : "-"}
          <DeathsIcon />
        </TextWithIcon>
      </div>
      <div className="d-contents">
        <b>Cleared On</b>
        <span>{map.cleared ? map.cleared_on ?? "-" : "-"}</span>
      </div>
    </div>
  );
}

function ProofEmbed({ url, ...props }) {
  if (url === undefined || url === null || url === "") {
    return (
      <div {...props}>
        <h4 className="mb-2">Clear Video</h4>
        <p className="text-muted">No clear video attached.</p>
      </div>
    );
  }

  if (url.includes("youtube.com") || url.includes("youtu.be")) {
    let ytUrl = null;
    if (url.includes("youtu.be")) {
      ytUrl = url.replace("youtu.be", "youtube.com/embed");
    } else {
      ytUrl = url.replace("watch?v=", "embed/");
    }

    //Get rid of any extra url parameters
    ytUrl = ytUrl.split("&")[0];

    return (
      <div {...props}>
        <Stack className="mb-2" direction="horizontal" gap={2}>
          <h4 className="mb-0">Clear Video</h4>
          <YouTubeIcon size="large" />
        </Stack>
        <div style={{ position: "relative", width: "100%", paddingBottom: "56.25%" }}>
          <iframe
            src={ytUrl}
            title="YouTube video player"
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            style={{ width: "100%", height: "100%", position: "absolute", top: "0", left: "0" }}
          ></iframe>
        </div>
      </div>
    );
  }

  return (
    <div {...props}>
      <h4 className="mb-2">Clear Video</h4>
      <p className="text-muted">Couldn't embed</p>
      <Link to={url} target="_blank">
        {url}
      </Link>
    </div>
  );
}

function NotesBox({ map, userSession }) {
  return (
    <Card style={{ height: "max-content" }}>
      <Card.Body>
        <Card.Title>Notes</Card.Title>
        <Card.Text className={map.notes ? "" : "text-muted"}>{map.notes ?? "No notes attached."}</Card.Text>
      </Card.Body>
    </Card>
  );
}
function GoldenRuns({ map, goldenRuns, userSession }) {
  return (
    <div className="map-details-col-span-2">
      <h2>Golden Runs</h2>
      {goldenRuns.length === 0 ? (
        <p className="text-muted">No golden runs attached.</p>
      ) : (
        goldenRuns.map((goldenRun) => <GoldenRunDisplay goldenRun={goldenRun} userSession={userSession} />)
      )}
    </div>
  );
}
function GoldenRunDisplay({ goldenRun, userSession }) {
  let description = getGoldenDescription(goldenRun);

  const detailsGrid = {
    gridTemplateColumns: "repeat(2, max-content)",
    gridTemplateRows: "repeat(4, max-content)",
    gap: "3px 10px",
    fontSize: "115%",
  };

  return (
    <div className="golden-details-grid mb-3">
      <Stack direction="horizontal" gap={2} style={{ width: "max-content" }}>
        <h3 className="mb-0">{description}</h3>
        {goldenRun.cleared ? <GoldberryIcon /> : <CrossIcon size="medium" color="red" />}
        {goldenRun.cleared === 1 && <SheetDifficultyBadge id={goldenRun.sheet_difficulty_id} />}
      </Stack>
      <ProofEmbed url={goldenRun.proof_url} className="golden-details-proof" />
      <div className="d-grid" style={detailsGrid}>
        <div className="d-contents">
          <b>Golden Time</b>
          <TextWithIcon>
            {goldenRun.cleared ? formatClearDuration(goldenRun.clear_duration ?? 0) : "-"}
            <TimeIcon />
          </TextWithIcon>
        </div>
        <div className="d-contents">
          <b>Golden Deaths</b>
          <TextWithIcon>
            {goldenRun.cleared ? numberWithCommas(goldenRun.clear_deaths ?? 0) : "-"}
            <DeathsIcon />
          </TextWithIcon>
        </div>
        <div className="d-contents">
          <b>Cleared On</b>
          <span>{goldenRun.cleared ? goldenRun.cleared_on ?? "-" : "-"}</span>
        </div>
      </div>
    </div>
  );
}

function GameBananaUrlEmbed({ url, classNames, ...props }) {
  if (url === undefined || url === null || url === "" || !url.includes("gamebanana.com")) {
    return null;
  }

  //Get id from url like https://gamebanana.com/{type}/{id}
  let urlParts = url.split("/");
  let id = urlParts[urlParts.length - 1];
  let type = urlParts[urlParts.length - 2];

  let embedUrl = "https://gamebanana.com/" + type + "/embeddables/" + id + "?type=large";

  return (
    <Link to={url} target="_blank" className={["ms-auto", ...classNames].join(" ")} {...props}>
      <Card>
        <Card.Img variant="top" src={embedUrl} width={350} height={75} />
      </Card>
    </Link>
  );
}
