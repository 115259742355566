import "./css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import Layout from "./pages/Layout";

import IndexPage from "./pages/celeste-stats/Index";
import TicTacToe from "./pages/TicTacToe";
import PlayerMapDataPage from "./pages/celeste-stats/Player";
import NoPage from "./pages/NoPage";
import LoginPage from "./pages/celeste-stats/Login";
import AddMapDataPage from "./pages/celeste-stats/AddMap";
import AdminPanel from "./pages/admin/AdminPanel";
import CampaignPage from "./pages/celeste-stats/Campaign";
import AddCampaignPage from "./pages/celeste-stats/AddCampaign";
import EditCampaignPage from "./pages/celeste-stats/EditCampaign";
import ListPage from "./pages/celeste-stats/List";
import TopGoldenListPage from "./pages/celeste-stats/TopGoldenList";
import AccountPage from "./pages/celeste-stats/Account";
import PlayersListPage from "./pages/celeste-stats/PlayersList";
import { RegistrationPage, VerifyPage } from "./pages/celeste-stats/Registration";
import MapPage from "./pages/celeste-stats/Map";
import BulkAddMapsPage from "./pages/celeste-stats/BulkAddMaps";
import CampaignListPage from "./pages/celeste-stats/CampaignList";
import { PasswordRecoveryPage } from "./pages/celeste-stats/PasswordRecovery";

import { TestPage } from "./pages/celeste-stats/Test";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<IndexPage />} />
      <Route path="/tic-tac-toe" element={<TicTacToe />} />
      <Route path="/add-map" element={<AddMapDataPage />} />
      <Route path="/bulk-add-maps" element={<BulkAddMapsPage />} />
      <Route path="/add-campaign" element={<AddCampaignPage />} />
      <Route path="/player/:playerName/:sortMode?" element={<PlayerMapDataPage />} />
      <Route path="/player/:playerName/campaign/:campaignId/:tab?" element={<CampaignPage />} />
      <Route path="/player/:playerName/campaigns" element={<CampaignListPage />} />
      <Route path="/player/:playerName/edit-campaign/:campaignId" element={<EditCampaignPage />} />
      <Route path="/player/:playerName/list/:clear/:enjoyment" element={<ListPage />} />
      <Route path="/player/:playerName/top-golden-list" element={<TopGoldenListPage />} />
      <Route path="/player/:playerName/map/:mapId" element={<MapPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/admin/:tab?" element={<AdminPanel />} />
      <Route path="/settings/:tab?" element={<AccountPage />} />
      <Route path="/players" element={<PlayersListPage />} />
      <Route path="/sign-up/:code?" element={<RegistrationPage />} />
      <Route path="/verify/:code" element={<VerifyPage />} />
      <Route path="/password-recovery/:code?" element={<PasswordRecoveryPage />} />
      <Route path="/test/:tab?" element={<TestPage />} />
      <Route path="*" element={<NoPage />} />
    </Route>
  )
);

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
      <ToastContainer position="bottom-right" />
    </QueryClientProvider>
  );
}
