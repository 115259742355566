import { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Table, Popover, OverlayTrigger, Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  DifficultyDisplay,
  EnjoymentDisplay,
  ErrorContainer,
  LoadingContainer,
} from "../../components/MiscComponents";
import { getMapName, sortListByProperties } from "./StatsUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { PlayerProfileNavbar } from "../../components/MiscComponents";
import { useQuery, useQueryClient } from "react-query";
import { getPlayerMapData, parseAxiosError } from "../../hooks/CelesteStatsApi";
import { useUserSession } from "../../hooks/useUserSession";
import { PlayerGeneralStats } from "./Player";
import { useModal } from "../../hooks/useModal";
import { QuickEditMap } from "./QuickEditMap";

const difficultyCategories = [90, 75, 50, 30, 10, 0];
const enjoymentCategories = [9, 8, 7, 6, 2, 1];
const colors = ["#d5a6bd", "#ea9999", "#f9cb9c", "#ffe599", "#b6d7a8", "#a4c2f4"];

export default function ListPage() {
  const { playerName, clear, enjoyment } = useParams();
  const userSession = useUserSession();

  const playerMapDataQuery = useQuery({
    queryKey: ["playerMapList", playerName],
    queryFn: () => getPlayerMapData(playerName),
  });

  const modalRefs = {
    openEditModal: useRef(),
  };

  let clearTitle = clear === "clears" ? "Clears" : "Goldens";
  let enjoymentTitle = enjoyment === "enjoyment" ? "Enjoyment" : "Difficulty";
  let title = playerName + "'s " + clearTitle + " by " + enjoymentTitle;
  document.title = title + " - Celeste Stats";
  let urlPart = "/player/" + playerName + "/list/" + clear + "/" + enjoyment;

  if (playerMapDataQuery.isLoading) {
    return (
      <>
        <Container>
          <PlayerGeneralStats playerName={playerName} activePage={urlPart} />
        </Container>
        <LoadingContainer fluid centered />
      </>
    );
  } else if (playerMapDataQuery.isError) {
    let errorDetails = parseAxiosError(playerMapDataQuery.error);
    return (
      <>
        <Container>
          <PlayerGeneralStats playerName={playerName} activePage={urlPart} />
        </Container>
        <ErrorContainer message={errorDetails.message} fluid centered />
      </>
    );
  }

  let groupProperty =
    clear === "clears"
      ? enjoyment === "enjoyment"
        ? "enjoyment"
        : "difficulty"
      : enjoyment === "enjoyment"
      ? "golden_one_enjoyment"
      : "golden_one_difficulty";

  //Filter out all maps that dont have a value for the groupProperty
  let filteredData = playerMapDataQuery.data.filter((map) => map[groupProperty] !== null);
  sortListByProperties(filteredData, [[groupProperty, "desc"], "name"]);

  let groupCategories = enjoyment === "enjoyment" ? enjoymentCategories : difficultyCategories;

  //Group maps into the categories.
  //Example categories: [90, 50, 0]
  //Would create the 3 groups: 90+, 50-89.99, 0-49.99

  let mapGroups = groupCategories.map((category) => {
    let categoryIndex = groupCategories.indexOf(category);
    let previousCategory = groupCategories[categoryIndex - 1];

    let mapList = null;
    let categoryLabel = null;
    if (previousCategory === undefined) {
      mapList = filteredData.filter((map) => map[groupProperty] >= category);
      categoryLabel = category + "+";
    } else {
      mapList = filteredData.filter(
        (map) => map[groupProperty] >= category && map[groupProperty] < previousCategory
      );
      categoryLabel = category + "+";
    }

    return {
      category: categoryLabel,
      mapList: mapList,
      color: colors[categoryIndex],
    };
  });

  console.log("Grouped maps -> ", mapGroups, "groupProperty -> ", groupProperty);

  return (
    <>
      <Container>
        <PlayerGeneralStats playerName={playerName} activePage={urlPart} />
      </Container>
      <Container fluid style={{ overflowX: "visible", width: "2700px" }} className="pe-5">
        {/* <PlayerProfileNavbar playerName={playerName} activePage={urlPart} /> */}
        <Row>
          {mapGroups.map((mapGroup) => (
            <Col key={mapGroup.category} className="col-2 pe-0">
              <CategoryTable
                label={mapGroup.category}
                mapList={mapGroup.mapList}
                color={mapGroup.color}
                clear={clear}
                enjoyment={enjoyment}
                isOwnPage={userSession.isOwnPage(playerName)}
                openEditModalRef={modalRefs.openEditModal}
              />
            </Col>
          ))}
        </Row>
      </Container>
      <ModalContainer playerName={playerName} modalRefs={modalRefs} />
    </>
  );
}

function CategoryTable({ label, mapList, color, clear, enjoyment, isOwnPage, openEditModalRef }) {
  let difficultyBold = enjoyment === "enjoyment" ? "" : "fw-bold";
  let enjoymentBold = enjoyment === "enjoyment" ? "fw-bold" : "";

  return (
    <div>
      <h2
        className="text-center pb-1"
        style={{ borderBottom: "1px solid lightgray", backgroundColor: color }}
      >
        {label}
      </h2>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th style={{ maxWidth: "150px" }}>Campaign</th>
            <th style={{ maxWidth: "180px" }}>Map</th>
            <th className={difficultyBold + " text-center"} style={{ width: "1%" }}>
              Diff.
            </th>
            <th className={enjoymentBold + " text-center"} style={{ width: "1%" }}>
              Enj.
            </th>
          </tr>
        </thead>
        <tbody>
          {mapList.map((map) => (
            <MapRow
              key={map.id}
              map={map}
              clear={clear}
              enjoyment={enjoyment}
              isOwnPage={isOwnPage}
              openEditModalRef={openEditModalRef}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
}

function MapRow({ map, clear, enjoyment, isOwnPage, openEditModalRef }) {
  const openEditModal = () => {
    document.body.click();
    openEditModalRef.current(map.id);
  };

  let difficultyBold = enjoyment === "enjoyment" ? "" : "fw-bold";
  let enjoymentBold = enjoyment === "enjoyment" ? "fw-bold" : "";

  let difficultyValue = clear === "clears" ? map.difficulty : map.golden_one_difficulty;
  let enjoymentValue = clear === "clears" ? map.enjoyment : map.golden_one_enjoyment;

  if (clear !== "clears") {
    //Round to 1 decimal place
    enjoymentValue = Math.round(enjoymentValue * 10) / 10;
  }

  let styleNoTextOverflow = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "180px",
  };
  let styleNoTextOverflowCampaign = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "150px",
  };

  const NamePopover = (
    <Popover id="basic-popover" style={{ maxWidth: "400px", width: "50%" }}>
      <Popover.Header>Map Info</Popover.Header>
      <Popover.Body>
        <Row>
          <Col sm={3}>Map:</Col>
          <Col sm={9}>
            <Link to={"/player/" + map.user_name + "/map/" + map.id}>{getMapName(map)}</Link>{" "}
            {isOwnPage && (
              <Link style={{ color: "lightgray" }} onClick={openEditModal}>
                <FontAwesomeIcon icon={faEdit} />
              </Link>
            )}
          </Col>
        </Row>
        {map.campaign_name ? (
          <Row>
            <Col sm={3}>Campaign:</Col>
            <Col sm={9}>
              <Link to={"/player/" + map.user_name + "/campaign/" + map.campaign_id}>
                {map.campaign_name}
              </Link>
            </Col>
          </Row>
        ) : null}
        {map.difficulty !== null ? (
          <Row>
            <Col sm={3}>Clear Diff.:</Col>
            <Col sm={9} className="d-flex align-items-center">
              <DifficultyDisplay difficulty={map.difficulty} />
            </Col>
          </Row>
        ) : null}
        {map.enjoyment !== null ? (
          <Row>
            <Col sm={3}>Clear Enj.:</Col>
            <Col sm={9} className="d-flex align-items-center">
              <EnjoymentDisplay enjoyment={map.enjoyment} />
            </Col>
          </Row>
        ) : null}
        {map.golden_one_difficulty !== null ? (
          <Row>
            <Col sm={3}>Gold Diff.:</Col>
            <Col sm={9} className="d-flex align-items-center">
              <DifficultyDisplay difficulty={map.golden_one_difficulty} />
            </Col>
          </Row>
        ) : null}
        {map.golden_one_enjoyment !== null ? (
          <Row>
            <Col sm={3}>Gold Enj.:</Col>
            <Col sm={9} className="d-flex align-items-center">
              <EnjoymentDisplay enjoyment={Math.round(map.golden_one_enjoyment * 10) / 10} />
            </Col>
          </Row>
        ) : null}
      </Popover.Body>
    </Popover>
  );

  return (
    <tr>
      <td style={{ width: "150px" }}>
        <Link to={"/player/" + map.user_name + "/campaign/" + map.campaign_id}>
          <Button style={styleNoTextOverflowCampaign} className="p-0" variant="link">
            {map.campaign_name}
          </Button>
        </Link>
      </td>
      <td style={{ width: "180px" }}>
        <OverlayTrigger trigger="click" placement="auto" overlay={NamePopover} rootClose>
          <Button style={styleNoTextOverflow} className="p-0" variant="link">
            {getMapName(map)}
          </Button>
        </OverlayTrigger>
      </td>
      <td className={difficultyBold + " text-center"}>{difficultyValue}</td>
      <td className={enjoymentBold + " text-center"}>{enjoymentValue}</td>
    </tr>
  );
}

function ModalContainer({ playerName, modalRefs }) {
  const queryClient = useQueryClient();
  const editModal = useModal(null, (cancelled, mapData) => {
    if (cancelled) return;
    queryClient.setQueryData(["playerMapList", playerName], (oldData) => {
      let newData = oldData.map((map) => {
        if (map.id === mapData.id) {
          return mapData;
        }
        return map;
      });
      return newData;
    });
  });

  modalRefs.openEditModal.current = editModal.open;

  return (
    <>
      <Modal show={editModal.isVisible} onHide={editModal.cancel} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Quick Edit Map</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuickEditMap
            playerName={playerName}
            id={editModal.data}
            onSave={(mapData) => editModal.close(false, mapData)}
            onCancel={editModal.cancel}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
