import { Button, Col, Container, Form, Row, Stack, Alert, FloatingLabel } from "react-bootstrap";
import { parseAxiosError } from "../../hooks/CelesteStatsApi";
import { useEventListener } from "../../hooks/useEventListener";
import { Spinner } from "../../components/MiscComponents";
import { useUserSession } from "../../hooks/useUserSession";
import { useNavigate } from "react-router";

export default function LoginPage() {
  document.title = "Login - Celeste Stats";
  const navigate = useNavigate();
  const userSession = useUserSession();

  function handleSubmit() {
    let userName = document.getElementById("user_name").value;
    let password = document.getElementById("password").value;

    userSession.login(userName, password, (data) => {
      console.log("Login successful: " + data.name);
      navigate("/player/" + data.name);
    });
  }

  useEventListener("keydown", onKeyDown);
  function onKeyDown(event) {
    if (event.keyCode === 13) {
      handleSubmit();
    }
  }

  return (
    <Container>
      <h2>Login</h2>
      {userSession.isLoggedIn ? (
        <Stack gap={3}>
          <div className="p-2">You are already logged in</div>
        </Stack>
      ) : (
        <Row>
          <Col sm={4}>
            <Form>
              <FloatingLabel label="Username" className="mb-3">
                <Form.Control type="text" id="user_name" placeholder="User Name" onKeyDown={onKeyDown} />
              </FloatingLabel>
              <FloatingLabel label="Password" className="mb-3">
                <Form.Control type="password" id="password" placeholder="Password" onKeyDown={onKeyDown} />
              </FloatingLabel>
              {userSession.loginMutation.isError && (
                <Alert variant="danger">{parseAxiosError(userSession.loginMutation.error).message}</Alert>
              )}
              <Stack gap={3}>
                <Button variant="primary" onClick={handleSubmit}>
                  Login
                </Button>
                {userSession.loginMutation.isLoading && <Spinner />}
              </Stack>
            </Form>
          </Col>
        </Row>
      )}
    </Container>
  );
}
