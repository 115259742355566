import { Tab, Row, Col, Nav, Container, Form, Button, ProgressBar, Stack } from "react-bootstrap";
import { useUserSession } from "../../hooks/useUserSession";
import {
  DeleteIcon,
  EmailIcon,
  ExternalLinkIcon,
  GlumbsupIcon,
  InfoIcon,
  PasswordIcon,
  SaveIcon,
  SettingsIcon,
  ShieldIcon,
  TextWithIcon,
  UserIcon,
  VisibilityIcon,
} from "../../components/Icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteUserAccount,
  getUserAccountData,
  parseAxiosError,
  updateUserAccountSettings as updateUserAccountSettings,
  updateUserPreferences,
} from "../../hooks/CelesteStatsApi";
import { Spinner } from "../../components/MiscComponents";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FormOptions } from "../../hooks/useFormUtil";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { numberWithCommas } from "./StatsUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";

export default function AccountPage({}) {
  document.title = "Settings - Celeste Stats";
  const userSession = useUserSession();
  const { tab } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(tab ?? "preferences");
  const handleSelect = (key) => {
    console.log("Changed tab to: ", key);
    setActiveTab(key);
    if (key === "preferences") {
      navigate("/settings", { replace: true });
    } else {
      navigate("/settings/" + key, { replace: true });
    }
  };

  const userInfoQuery = useQuery({
    queryKey: ["userInfo", userSession.user?.name],
    enabled: userSession.isLoggedIn,
    queryFn: () => getUserAccountData(),
    staleTime: 0,
  });

  if (!userSession.isLoggedIn) {
    return (
      <Container>
        <h2>Account Settings</h2>
        <p className="text-danger">You must be logged in to view this page.</p>
      </Container>
    );
  }

  if (!userSession.isLoggedIn) {
    return (
      <Container>
        <h2>Account Settings</h2>
        <p className="text-danger">You must be logged in to view this page.</p>
      </Container>
    );
  }

  return (
    <Container>
      {/* <h2>Account Settings</h2> */}
      <h2>
        <UserIcon size="small" className="me-2" />
        {userSession.user.name}
      </h2>
      <hr />
      {userInfoQuery.isLoading ? (
        <p>
          Loading... <Spinner />
        </p>
      ) : (
        <Tab.Container id="left-tabs-container" defaultActiveKey={activeTab} onSelect={handleSelect}>
          <Row>
            <Col sm={2}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="preferences">
                    <SettingsIcon className="me-2" />
                    Preferences
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="account">
                    <ShieldIcon className="me-2" />
                    Account
                  </Nav.Link>
                </Nav.Item>
                <hr className="my-2" />
                <Nav.Item>
                  <Nav.Link eventKey="account-info">
                    <InfoIcon className="me-2" />
                    Info
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="data-export">
                    <FontAwesomeIcon icon={faFileExport} className="me-2" />
                    Data Export
                  </Nav.Link>
                </Nav.Item>
                <hr className="my-2" />
                <Nav.Item>
                  <Nav.Link eventKey="delete-account">
                    <DeleteIcon className="me-2" />
                    Delete Account
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={10} style={{ borderLeft: "1px solid #c6c7c8" }}>
              <Tab.Content>
                <Tab.Pane eventKey="preferences">
                  <PreferencesForm userSession={userSession} userInfoQuery={userInfoQuery} />
                </Tab.Pane>
                <Tab.Pane eventKey="account">
                  <AccountSettingsForm userSession={userSession} userInfoQuery={userInfoQuery} />
                </Tab.Pane>
                <Tab.Pane eventKey="account-info">
                  <AccountInfoSection userSession={userSession} userInfoQuery={userInfoQuery} />
                </Tab.Pane>
                <Tab.Pane eventKey="data-export">
                  <DataExportSection userSession={userSession} />
                </Tab.Pane>
                <Tab.Pane eventKey="delete-account">
                  <DeleteAccountForm userSession={userSession} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )}
    </Container>
  );
}

function PreferencesForm({ userSession, userInfoQuery }) {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      is_listed_publicly: userInfoQuery.data.is_listed_publicly,
    },
  });
  const onSubmit = handleSubmit((data) => {
    let preferences = {
      is_listed_publicly: data.is_listed_publicly,
    };
    updatePreferences(preferences);
  });

  const { mutate: updatePreferences } = useMutation({
    mutationFn: (preferences) => updateUserPreferences(preferences),
    onSuccess: (data) => {
      toast.success("Preferences updated");
      userSession.checkSessionQuery.refetch();
      queryClient.invalidateQueries(["playerList"]);
      queryClient.invalidateQueries(["userInfo", userSession.user.name]);
    },
    onError: (error) => {
      toast.error("Failed to update preferences: " + parseAxiosError(error).message);
    },
  });

  return (
    <>
      <h3>Your Preferences</h3>
      <Form style={{ maxWidth: "400px" }} onSubmit={onSubmit}>
        <Form.Group className="mb-3" controlId="is_listed_publicly">
          <Form.Check
            type="switch"
            label={
              <>
                Listed Publicly <VisibilityIcon />
              </>
            }
            {...register("is_listed_publicly")}
          />
          <Form.Text className="text-muted">
            If enabled, your profile will be visible on the <Link to="/players">All Players</Link> page.
          </Form.Text>
        </Form.Group>
        <hr />
        <Button variant="primary" type="submit" className="mb-2">
          <SaveIcon className="me-2" />
          Update Preferences
        </Button>
      </Form>
    </>
  );
}

function AccountSettingsForm({ userSession, userInfoQuery }) {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues: {
      username: userInfoQuery.data.name,
      email: userInfoQuery.data.email,
      password: "",
      new_password: "",
      confirm_password: "",
    },
    mode: "onBlur",
  });
  const onSubmit = handleSubmit((data) => {
    let settings = {
      name: data.username,
      email: data.email,
      password: data.password,
    };
    if (data.new_password !== null && data.new_password !== undefined && data.new_password !== "") {
      settings.new_password = data.new_password;
    }
    updateAccountSettings(settings);
  });

  const newPassword = watch("new_password");
  const validateConfirmPassword = (value) => {
    if (value === newPassword) return true;
    return "Passwords do not match";
  };

  const { mutate: updateAccountSettings } = useMutation({
    mutationFn: (settings) => updateUserAccountSettings(settings),
    onSuccess: (data) => {
      toast.success("Account settings updated");
      setValue("password", "");
      setValue("new_password", "");
      setValue("confirm_password", "");
      userSession.checkSessionQuery.refetch();
      queryClient.invalidateQueries(["userInfo", userSession.user.name]);
    },
    onError: (error) => {
      toast.error("Failed to update account settings: " + parseAxiosError(error).message);
    },
  });

  return (
    <>
      <h3>Your Account</h3>
      <Form style={{ maxWidth: "400px" }} onSubmit={onSubmit} autoComplete="off">
        <Form.Group className="mb-3" controlId="username">
          <Form.Label>
            <UserIcon className="me-2" />
            Username
          </Form.Label>
          <Form.Control
            type="text"
            {...register("username", FormOptions.Username)}
            isInvalid={errors.username !== undefined}
            autoComplete="off"
          />
          {errors.username && <Form.Text className="text-danger">{errors.username.message}</Form.Text>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>
            <EmailIcon className="me-2" />
            Email
          </Form.Label>
          <Form.Control
            type="email"
            {...register("email", { ...FormOptions.Email, required: false })}
            isInvalid={errors.email !== undefined}
            autoComplete="off"
          />
          {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="new_password">
          <Form.Label>
            <PasswordIcon className="me-2" />
            New Password
          </Form.Label>
          <Form.Control
            type="password"
            {...register("new_password", { ...FormOptions.Password, required: false })}
            isInvalid={errors.new_password !== undefined}
          />
          {errors.new_password && (
            <Form.Text className="text-danger">{errors.new_password.message}</Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="confirm_password">
          <Form.Label>
            <PasswordIcon className="me-2" />
            Confirm Password
          </Form.Label>
          <Form.Control
            type="password"
            {...register("confirm_password", {
              ...FormOptions.Password,
              required: false,
              validate: validateConfirmPassword,
            })}
            isInvalid={errors.confirm_password !== undefined}
            autoComplete="off"
          />
          {errors.confirm_password && (
            <Form.Text className="text-danger">{errors.confirm_password.message}</Form.Text>
          )}
        </Form.Group>
        <hr />
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>
            <PasswordIcon className="me-2" />
            Current Password
          </Form.Label>
          <Form.Control
            type="password"
            {...register("password", FormOptions.Password)}
            isInvalid={errors.password !== undefined}
          />
          {errors.password && <Form.Text className="text-danger">{errors.password.message}</Form.Text>}
        </Form.Group>
        <Button variant="primary" type="submit" disabled={!isValid || !isDirty} className="mb-2">
          <SaveIcon className="me-2" />
          Update Account Settings
        </Button>
      </Form>
    </>
  );
}

function AccountInfoSection({ userSession, userInfoQuery }) {
  const rateLimit = {
    balance: userInfoQuery.data.rate_balance ?? 0,
    specialBalance: userInfoQuery.data.rate_special_balance ?? 0,
    maxBalance: userInfoQuery.data.rate_max_balance,
    maxSpecialBalance: userInfoQuery.data.rate_max_special_balance,
  };

  const variantColors = [
    [0.5, "success"],
    [0.1, "warning"],
    [0, "danger"],
  ];

  function getVariantColor(value, max) {
    let percent = value / max;
    for (let i = 0; i < variantColors.length; i++) {
      if (percent >= variantColors[i][0]) return variantColors[i][1];
    }
    return "success";
  }

  const balanceVariant = getVariantColor(rateLimit.balance, rateLimit.maxBalance);
  const specialBalanceVariant = getVariantColor(rateLimit.specialBalance, rateLimit.maxSpecialBalance);

  return (
    <>
      <h3>Account Info</h3>
      <div style={{ maxWidth: "400px" }}>
        <h5>Rate Limit</h5>

        <Stack direction="horizontal" className="mb-0 fw-bold">
          <span>Balance</span>
          <span className="ms-auto">
            {`${numberWithCommas(rateLimit.balance)}/${numberWithCommas(rateLimit.maxBalance)}`}
          </span>
        </Stack>
        <ProgressBar
          variant={balanceVariant}
          now={rateLimit.balance}
          max={rateLimit.maxBalance}
          className="mb-2"
          style={{ border: "1px solid lightgray" }}
        />
        <p className="text-muted">
          This balance is the number of read-requests you can make to the API in a day.
        </p>

        <Stack direction="horizontal" className="mb-0 fw-bold">
          <span>Modify Balance</span>
          <span className="ms-auto">
            {`${numberWithCommas(rateLimit.specialBalance)}/${numberWithCommas(rateLimit.maxSpecialBalance)}`}
          </span>
        </Stack>
        <ProgressBar
          variant={specialBalanceVariant}
          now={rateLimit.specialBalance}
          max={rateLimit.maxSpecialBalance}
          className="mb-2"
          style={{ border: "1px solid lightgray" }}
        />
        <p className="text-muted">
          This balance is for data modifying requests like creating/updating/deleting a Map, ...
        </p>

        <p className="mt-2 text-muted">
          The idea of this is to prevent abuse and these limits are currently guessed. If you run dangerously
          low here while using the site, contact me on Discord{" "}
          <GlumbsupIcon style={{ marginBottom: "4px" }} />
        </p>
      </div>
    </>
  );
}

function DataExportSection({ userSession }) {
  const openDownloadPage = () => {
    window.open("https://stats.vi-home.de/api/user/export?download=1", "_blank");
  };

  return (
    <>
      <h3>Data Export</h3>
      <div style={{ maxWidth: "400px" }}>
        <p className="">
          Click on the button below to download a JSON file containing all of your account data. This
          includes:
        </p>
        <ul>
          <li>All Maps & Stats</li>
          <li>All Campaigns & Settings</li>
          <li>All Golden Stats</li>
          <li>Your Preferences</li>
        </ul>
        <p className="">
          No private information (e-mail, password, ...) is provided in this file. Use this to backup your
          data or import it elsewhere.
        </p>
        <Button variant="primary" onClick={openDownloadPage}>
          <SaveIcon className="me-2" />
          Download Data
        </Button>
      </div>
    </>
  );
}

function DeleteAccountForm({ userSession }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      confirm_name: "",
      password: "",
    },
    mode: "onBlur",
  });
  const onSubmit = handleSubmit((data) => {
    let confirmData = {
      confirm_name: data.confirm_name,
      password: data.password,
    };
    deleteAccount(confirmData);
  });

  const validateConfirmName = (value) => {
    if (value === userSession.user.name) return true;
    return "That is not your username";
  };

  const { mutate: deleteAccount } = useMutation({
    mutationFn: (confirmData) => deleteUserAccount(confirmData),
    onSuccess: (data) => {
      toast.success("Account deleted");
      queryClient.invalidateQueries(["checkSession"]);
      navigate("/login");
    },
    onError: (error) => {
      toast.error("Failed to delete account: " + parseAxiosError(error).message);
    },
  });

  return (
    <>
      <h3 className="text-danger">Delete Account</h3>
      <Form className="mb-2" onSubmit={onSubmit} style={{ maxWidth: "400px" }} autoComplete="off">
        <Form.Group className="mb-3" controlId="confirm_name">
          <Form.Label>
            <UserIcon className="me-2" />
            Confirm Username
          </Form.Label>
          <Form.Control
            type="text"
            {...register("confirm_name", { ...FormOptions.Username, validate: validateConfirmName })}
            isInvalid={errors.confirm_name !== undefined}
            autoComplete="off"
          />
          {errors.confirm_name && (
            <Form.Text className="text-danger">
              {errors.confirm_name.message}
              <br />
            </Form.Text>
          )}
          <Form.Text className="text-muted">
            Type your username to confirm deletion of your account.
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="delete-password">
          <Form.Label>
            <PasswordIcon className="me-2" />
            Current Password
          </Form.Label>
          <Form.Control
            type="password"
            {...register("password", FormOptions.Password)}
            isInvalid={errors.password !== undefined}
            autoComplete="off"
          />
          {errors.password && <Form.Text className="text-danger">{errors.password.message}</Form.Text>}
        </Form.Group>
        <Button variant="danger" type="submit" className="mb-2" disabled={!isValid}>
          <SaveIcon className="me-2" />
          Delete Account '{userSession.user.name}'
        </Button>
      </Form>
    </>
  );
}
