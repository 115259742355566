import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { addCampaign, getPlayerCampaignList, parseAxiosError } from "../../hooks/CelesteStatsApi";
import { toast } from "react-toastify";
import { useUserSession } from "../../hooks/useUserSession";
import { useForm } from "react-hook-form";
import { CustomModal, useModal } from "../../hooks/useModal";

export default function AddCampaignPage() {
  const navigate = useNavigate();
  const userSession = useUserSession();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm();
  const onSubmit = handleSubmit((data) => {
    console.log("Creating Campaign: " + data.name);
    addNewCampaign(data.name);
  });

  const { mutate: addNewCampaign } = useMutation({
    mutationFn: (campaignName) => addCampaign({ name: campaignName }),
    onSuccess: (data, campaignName) => {
      navigate("/player/" + userSession.user.name + "/edit-campaign/" + data.id);
    },
    onError: (error) => {
      let parsedError = parseAxiosError(error);
      if (parsedError.code === 10) {
        //Invalid campaign name
        setError("name", { type: "api", message: parsedError.message });
        return;
      }
      toast.error("Error adding campaign: " + parseAxiosError(error).message);
    },
  });

  console.log("errors:", errors);

  if (!userSession.isLoggedIn) {
    return (
      <Container>
        <h1 className="text-danger">Login to add campaigns!</h1>
        <Link to="/login">Go to Login</Link>
      </Container>
    );
  }

  return (
    <Container>
      <h2 className="">Create New Campaign</h2>
      <Row>
        <Col sm={4}>
          <Form validated={isValid} onSubmit={onSubmit}>
            <Form.Group controlId="Campaign Name" className="mb-3">
              <Form.Label>Campaign Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                {...register("name", { required: true, minLength: 1, maxLength: 128 })}
                isInvalid={errors.name !== undefined}
              />
              {errors.name && errors.name?.type !== "api" && (
                <p className="text-danger">Campaign name must be between 1 and 128 characters</p>
              )}
              {errors.name?.type === "api" && <p className="text-danger">{errors.name.message}</p>}
            </Form.Group>
            <Button type="submit">
              <FontAwesomeIcon icon={faPlus} /> Create
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export function AddCampaignModal({ userName, openRef, onClose }) {
  const modal = useModal(
    null,
    (cancelled) => {
      if (cancelled) {
        if (typeof onClose === "function") onClose(true, modal.data);
        return;
      }
      if (typeof onClose === "function") onClose(false, modal.data);
    },
    () => reset()
  );
  openRef.current = modal.open;

  const campaignListQuery = useQuery({
    queryKey: ["campaignList", userName],
    queryFn: () => getPlayerCampaignList(userName),
  });

  const { mutate: addNewCampaign } = useMutation({
    mutationFn: (campaignName) => addCampaign({ name: campaignName }),
    onSuccess: (data, campaignName) => {
      modal.close();
    },
    onError: (error) => {
      toast.error("Error adding campaign: " + parseAxiosError(error).message);
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });
  const onSubmit = handleSubmit((data) => {
    modal.setData(data.name);
    addNewCampaign(data.name);
  });

  const validateDuplicateName = (value) => {
    if (campaignListQuery.data.find((c) => c.name === value) !== undefined) {
      return "Campaign name already exists";
    }
    return true;
  };

  return (
    <CustomModal modalHook={modal} options={{ title: "Create New Campaign", hideFooter: true }}>
      <Form validated={isValid} onSubmit={onSubmit}>
        <Form.Group controlId="Campaign Name" className="mb-3">
          <Form.Label>Campaign Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            {...register("name", {
              required: {
                value: true,
                message: "Campaign name can't be empty",
              },
              maxLength: {
                value: 128,
                message: "Campaign name must be between 1 and 128 characters",
              },
              validate: {
                duplicateName: validateDuplicateName,
              },
            })}
            isInvalid={errors.name !== undefined}
          />
          {errors.name !== undefined && <p className="text-danger">{errors.name.message}</p>}
        </Form.Group>
        <Button type="submit">
          <FontAwesomeIcon icon={faPlus} /> Create
        </Button>
      </Form>
    </CustomModal>
  );
}
