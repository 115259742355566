import { Button, Col, Container, Form, ListGroup, Row, Table } from "react-bootstrap";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "../../components/MiscComponents";
import { getMapName, sortListByProperties } from "./StatsUtil";
import { AddIcon, DeleteIcon, SaveIcon } from "../../components/Icons";
import {
  getCampaignInfo,
  getCampaignMapList,
  parseAxiosError,
  updateCampaignInfo,
  updatePlayerMapData,
} from "../../hooks/CelesteStatsApi";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useUserSession } from "../../hooks/useUserSession";
import { useFieldArray, useForm } from "react-hook-form";
import { FormOptions } from "../../hooks/useFormUtil";

export default function EditCampaignPage() {
  document.title = "Edit Campaign - Celeste Stats";
  const { playerName, campaignId } = useParams();
  const navigate = useNavigate();

  const userSession = useUserSession();
  let isOwnPage = userSession.isOwnPage(playerName);

  if (userSession.isLoggedIn && !isOwnPage) {
    return (
      <Container>
        <h1 className="text-danger">You cannot edit this campaign!</h1>
      </Container>
    );
  }
  if (!userSession.isLoggedIn) {
    return (
      <Container>
        <h1 className="text-danger">Login to edit campaigns!</h1>
        <Link to="/login">Go to Login</Link>
      </Container>
    );
  }

  return (
    <Container>
      <h2 className="">Edit Campaign</h2>
      <Row>
        <Col lg={6}>
          <CampaignInfoForm campaignId={campaignId} playerName={playerName} />
          <hr />
          <h2 className="">Maps Sort Order</h2>
          <p className="text-muted">
            Here you can determine the assignment of maps in the campaign to the Major-/Sub-Categories. If a
            campaign has a Major- or Sub-Category, all maps need to have a value set for that category in
            order to be correctly displayed.
          </p>
          <MapSortEditList campaignId={campaignId} playerName={playerName} />
        </Col>
      </Row>
    </Container>
  );
}

function CampaignInfoForm({ campaignId, playerName }) {
  const queryClient = useQueryClient();

  const campaignInfoQuery = useQuery({
    queryKey: ["campaignInfo", campaignId, playerName],
    queryFn: () => getCampaignInfo(campaignId, playerName),
    onSuccess: (data) => {
      document.title = "Edit '" + data.name + "' - Celeste Stats";
      let parsedForm = parseToForm(data);
      console.log("Parsed form: ", parsedForm);
      setValue("name", parsedForm.name);
      setValue("url", parsedForm.url);
      setValue("sort_major_name", parsedForm.sort_major_name);
      setValue("sort_major_labels", parsedForm.sort_major_labels);
      setValue("sort_minor_name", parsedForm.sort_minor_name);
      setValue("sort_minor_labels", parsedForm.sort_minor_labels);
    },
  });

  const { mutate: updateCampaign } = useMutation({
    mutationFn: (campaignInfo) => updateCampaignInfo(campaignInfo),
    onSuccess: (data, campaignInfo) => {
      queryClient.invalidateQueries(["campaignInfo", campaignId, playerName]);
      toast.success("Updated campaign '" + campaignInfo.name + "'");
    },
    onError: (error, campaignInfo) => {
      toast.error("Error updating campaign " + campaignInfo.name + ": " + parseAxiosError(error).message);
    },
  });

  const defaultValues =
    campaignInfoQuery.data === undefined
      ? {
          name: "",
          url: "",
          sort_major_name: "",
          sort_major_labels: [],
          sort_minor_name: "",
          sort_minor_labels: [],
        }
      : parseToForm(campaignInfoQuery.data);
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
  });
  const onSubmit = handleSubmit((data) => {
    console.log("Submitted data: ", data);
    let parsedData = parseFromForm(data);
    parsedData.id = campaignId;
    updateCampaign(parsedData);
  });
  const majorFieldArray = useFieldArray({
    control,
    name: "sort_major_labels",
  });
  const minorFieldArray = useFieldArray({
    control,
    name: "sort_minor_labels",
  });

  function importDefaultLabelSet() {
    let defaultMajorLabels = [
      { label: "Beginner", color: "#4bd5eb" },
      { label: "Intermediate", color: "#dd4c0b" },
      { label: "Advanced", color: "#f8b951" },
      { label: "Expert", color: "#c93073" },
      { label: "Grandmaster", color: "#ffebb0" },
    ];

    let defaultMinorLabels = [
      { label: "Green", color: "#deffe3" },
      { label: "Yellow", color: "#fffcde" },
      { label: "Red", color: "#ffdede" },
      { label: "Cracked", color: "#eec7ff" },
      { label: "Heart Side", color: "#e0e0e0" },
    ];

    setValue("sort_major_labels", defaultMajorLabels);
    setValue("sort_minor_labels", defaultMinorLabels);
  }

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group as={Row} controlId="Campaign Name" className="mb-3">
        <Form.Label column lg={4}>
          Campaign Name
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            {...register("name", { ...FormOptions.CampaignNameRequired })}
            isInvalid={errors.name}
          />
          {errors.name !== undefined && (
            <Form.Control.Feedback type="invalid">{errors.name.message}</Form.Control.Feedback>
          )}
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="Campaign URL" className="mb-3">
        <Form.Label column lg={4}>
          Campaign URL
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            {...register("url", { ...FormOptions.CampaignUrl })}
            isInvalid={errors.url}
          />
          {errors.url !== undefined && (
            <Form.Control.Feedback type="invalid">{errors.url.message}</Form.Control.Feedback>
          )}
        </Col>
      </Form.Group>

      <hr />
      <Row>
        <Col lg={6}>
          <h2 className="">Map Sorting</h2>
        </Col>
        <Col lg={6} className="d-flex justify-content-end align-items-center">
          <Button variant="outline-primary" size="sm" onClick={importDefaultLabelSet}>
            Import Default Labels
          </Button>
        </Col>
      </Row>
      <Form.Group as={Row} controlId="Sort Major Name" className="mb-3">
        <Form.Label column lg={4}>
          Major Category Name
        </Form.Label>
        <Col lg={8}>
          <Form.Control maxLength={32} type="text" {...register("sort_major_name")} />
        </Col>
      </Form.Group>

      <Row>
        <Col lg={6}>
          <EditLabelGroup register={register} fieldArray={majorFieldArray} name="sort_major_labels" />
        </Col>
      </Row>

      <hr />

      <Form.Group as={Row} controlId="Sort Minor Name" className="mb-3">
        <Form.Label column lg={4}>
          Sub-Category Name
        </Form.Label>
        <Col lg={8}>
          <Form.Control maxLength={32} type="text" {...register("sort_minor_name")} />
        </Col>
      </Form.Group>

      <Row>
        <Col lg={6}>
          <EditLabelGroup register={register} fieldArray={minorFieldArray} name="sort_minor_labels" />
        </Col>
      </Row>

      <Button type="submit">
        <SaveIcon /> Save Campaign Details
      </Button>
    </Form>
  );
}

function EditLabelGroup({ register, name, fieldArray }) {
  return (
    <div className="mb-3 d-flex flex-column">
      <Button
        variant="success"
        onClick={() => fieldArray.append({ label: "Test", color: "#ff0000" })}
        className="mb-2"
      >
        <AddIcon /> Add Label
      </Button>
      <ListGroup>
        {fieldArray.fields.map((field, index) => (
          <ListGroup.Item key={field.id}>
            <div className="d-flex">
              <span className="my-auto me-2">{index + 1}</span>
              <Form.Control type="text" {...register(`${name}.${index}.label`)} />
              <Form.Control className="mx-2" type="color" {...register(`${name}.${index}.color`)} />
              <Button variant="outline-danger" onClick={() => fieldArray.remove(index)}>
                <DeleteIcon />
              </Button>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
}

//A component to display the list of maps with their current sort_major, sort_minor and sort_order values. Editable in place
function MapSortEditList({ campaignId, playerName }) {
  const queryClient = useQueryClient();

  const campaignMapListQuery = useQuery({
    queryKey: ["campaignMapList", campaignId],
    queryFn: () => getCampaignMapList(campaignId),
    onSuccess: (data) => {
      sortListByProperties(data, ["sort_major", "sort_minor", "sort_order", "name"]);
      setValue("maps", data);
      setDefaultValues({ maps: data });
    },
  });

  const { mutate: updateMapData } = useMutation({
    mutationFn: (map) => updatePlayerMapData(map),
    onSuccess: (data, map) => {
      toast.success("Updated '" + getMapName(map) + "'");
    },
    onError: (error, map) => {
      toast.error("Error updating map " + getMapName(map) + ": " + parseAxiosError(error).message);
    },
  });

  const [defaultValues, setDefaultValues] = useState(
    campaignMapListQuery.data === undefined ? { maps: [] } : { maps: campaignMapListQuery.data }
  );
  const { register, control, setValue, handleSubmit } = useForm({
    defaultValues: defaultValues,
  });
  const onSubmit = handleSubmit((data) => {
    console.log("Submitted data: ", data);
    console.log("defaultValues: ", defaultValues);

    let changedMaps = data.maps.filter((map) => {
      let originalMap = defaultValues.maps.find((m) => m.id === map.id);
      return (
        map.sort_major !== originalMap.sort_major ||
        map.sort_minor !== originalMap.sort_minor ||
        map.sort_order !== originalMap.sort_order
      );
    });

    changedMaps.forEach((map) => {
      updateMapData(map);
    });

    queryClient.invalidateQueries(["campaignMapList", campaignId]);
  });
  const mapsFieldArray = useFieldArray({
    control,
    name: "maps",
  });

  return (
    <Form onSubmit={onSubmit}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Map</th>
            <th>Major Category</th>
            <th>Sub-Category</th>
            <th>Order</th>
          </tr>
        </thead>
        <tbody>
          {mapsFieldArray.fields.map((field, index, array) => (
            <tr key={field.id}>
              <td className="text-center" style={{ verticalAlign: "middle" }}>
                {getMapName(array[index])}
              </td>
              <td className="text-center">
                <Form.Control type="number" {...register(`maps.${index}.sort_major`)} />
              </td>
              <td className="text-center">
                <Form.Control type="number" {...register(`maps.${index}.sort_minor`)} />
              </td>
              <td className="text-center">
                <Form.Control type="number" {...register(`maps.${index}.sort_order`)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button variant="primary" type="submit">
        <SaveIcon /> Save Sort Order
      </Button>
    </Form>
  );
}

function parseToForm(obj) {
  let majorLabels = obj.sort_major_labels === null ? [] : obj.sort_major_labels.split(",");
  let majorColors = obj.sort_major_accent_colors === null ? [] : obj.sort_major_accent_colors.split(",");

  let labelObjects = [];
  for (let i = 0; i < majorLabels.length; i++) {
    labelObjects.push({
      label: majorLabels[i],
      color: majorColors[i],
    });
  }

  let minorLabels = obj.sort_minor_labels === null ? [] : obj.sort_minor_labels.split(",");
  let minorColors = obj.sort_minor_accent_colors === null ? [] : obj.sort_minor_accent_colors.split(",");
  let minorLabelObjects = [];
  for (let i = 0; i < minorLabels.length; i++) {
    minorLabelObjects.push({
      label: minorLabels[i],
      color: minorColors[i],
    });
  }

  return {
    id: obj.id,
    name: obj.name,
    url: obj.url,
    sort_major_name: obj.sort_major_name,
    sort_major_labels: labelObjects,
    sort_minor_name: obj.sort_minor_name,
    sort_minor_labels: minorLabelObjects,
  };
}
function parseFromForm(form) {
  let sort_major_labels = [];
  let sort_major_accent_colors = [];
  for (let i = 0; i < form.sort_major_labels.length; i++) {
    sort_major_labels.push(form.sort_major_labels[i].label);
    sort_major_accent_colors.push(form.sort_major_labels[i].color);
  }

  let sort_minor_labels = [];
  let sort_minor_accent_colors = [];
  for (let i = 0; i < form.sort_minor_labels.length; i++) {
    sort_minor_labels.push(form.sort_minor_labels[i].label);
    sort_minor_accent_colors.push(form.sort_minor_labels[i].color);
  }

  return {
    name: form.name,
    url: form.url,
    sort_major_name: form.sort_major_name,
    sort_major_labels: sort_major_labels.join(","),
    sort_major_accent_colors: sort_major_accent_colors.join(","),
    sort_minor_name: form.sort_minor_name,
    sort_minor_labels: sort_minor_labels.join(","),
    sort_minor_accent_colors: sort_minor_accent_colors.join(","),
  };
}
