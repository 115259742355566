import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Nav, Row, Tab } from "react-bootstrap";

export function TabContainer({ tabs, activeTab, handleSelect, ...props }) {
  return (
    <Tab.Container defaultActiveKey={activeTab} onSelect={handleSelect} {...props}>
      <Row>
        <Col sm={2}>
          <Nav variant="pills" className="flex-column">
            {tabs.map((tab) => (
              <Nav.Item key={tab.key}>
                <Nav.Link eventKey={tab.key}>
                  <FontAwesomeIcon icon={tab.icon} className="me-2" />
                  {tab.title}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Col sm={10} style={{ borderLeft: "1px solid #c6c7c8" }}>
          <Tab.Content>
            {tabs.map((tab) => (
              <Tab.Pane key={tab.key} eventKey={tab.key}>
                {tab.component}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
}
