import React from "react";
import { unstable_useBlocker as useBlocker } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

export default function Prompt({ when, message }) {
  let blocker = useBlocker(when); //useBlocker(isFormDirty)

  React.useEffect(() => {
    console.log(blocker);
  }, [blocker]);

  const handleClose = () => blocker.reset();
  const handleConfirm = () => blocker.proceed();

  return (
    <Modal show={blocker.state === "blocked"} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleConfirm}>
          Leave
        </Button>
        <Button variant="secondary" onClick={handleClose} className="ms-auto">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
