import { Accordion, Button, Col, Container, Form, InputGroup, Row, Stack } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { AddIcon } from "../../components/Icons";
import { Token, Typeahead } from "react-bootstrap-typeahead";
import { LoadingContainer } from "../../components/MiscComponents";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useUserSession } from "../../hooks/useUserSession";
import { toast } from "react-toastify";
import {
  addPlayerMapData,
  createPlayerMapFullGoldenRun,
  createPlayerMapGoldenRun,
  getPlayerCampaignList,
  parseAxiosError,
} from "../../hooks/CelesteStatsApi";
import { useRef } from "react";
import { AddCampaignModal } from "./AddCampaign";
import { clearDurationToSeconds, getMapName } from "./StatsUtil";
import { SHEET_DIFFICULTIES } from "../../components/MiscComponents";

const dataSelectOptions = [
  { label: "Map Name*", value: "name" },
  { label: "Side Name", value: "side_name" },
  { label: "Map URL", value: "url" },
  { label: "Cleared?", value: "cleared" },
  { label: "Clear Time", value: "clear_duration" },
  { label: "Clear Deaths", value: "clear_deaths" },
  { label: "Cleared On", value: "cleared_on" },
  { label: "Difficulty", value: "difficulty" },
  { label: "Enjoyment", value: "enjoyment" },
  { label: "Notes", value: "notes" },
  { label: "Proof URL", value: "proof_url" },
  { label: "Sort: Major", value: "sort_major" },
  { label: "Sort: Minor", value: "sort_minor" },
  { label: "Sort: Order", value: "sort_order" },

  { label: "Golden: Description", value: "golden_description" },
  { label: "Golden: Is FC?", value: "golden_is_fc" },
  { label: "Golden: Special Berry?", value: "golden_is_sb" },
  { label: "Golden: Cleared?", value: "golden_cleared" },
  { label: "Golden: Clear Time", value: "golden_clear_duration" },
  { label: "Golden: Clear Deaths", value: "golden_clear_deaths" },
  { label: "Golden: Cleared On", value: "golden_cleared_on" },
  { label: "Golden: Difficulty", value: "golden_difficulty" },
  { label: "Golden: Enjoyment", value: "golden_enjoyment" },
  { label: "Golden: Tier", value: "golden_sheet_difficulty_id" },
  { label: "Golden: Notes", value: "golden_notes" },
  { label: "Golden: Proof URL", value: "golden_proof_url" },
];
const exampleData = {
  name: "The Summit",
  side_name: "A-Side",
  url: "https://gamebanana.com/mods/393245",
  cleared: "True",
  clear_duration: "1:29:12",
  clear_deaths: "476",
  cleared_on: "2023-07-15",
  difficulty: "12.5",
  enjoyment: "8.4",
  notes: "Pretty cool map",
  proof_url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
  sort_major: "7",
  sort_minor: "0",
  sort_order: "1",

  golden_description: "Golden",
  golden_is_fc: "True",
  golden_is_sb: "False",
  golden_cleared: "True",
  golden_clear_duration: "17:56:33",
  golden_clear_deaths: "513",
  golden_cleared_on: "2023-07-26",
  golden_difficulty: "32",
  golden_enjoyment: "1.5",
  golden_sheet_difficulty_id: "high tier 3",
  golden_notes: "awful golden",
  golden_proof_url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
};

export default function BulkAddMapsPage() {
  document.title = "Bulk Add Maps - Celeste Stats";
  const userSession = useUserSession();
  const queryClient = useQueryClient();

  const form = useForm({
    defaultValues: {
      campaign: [],
      data_format: [{ label: "Map Name*", value: "name" }],
      data: "",
    },
    mode: "onBlur",
  });
  const setDataError = (message, line) => {
    if (message === null) {
      form.clearErrors("data");
      return;
    }

    form.setError("data", {
      type: "manual",
      message: message + " on line " + line,
    });
  };
  const onSubmit = form.handleSubmit((data) => {
    console.log(data);
    const separator = data.data_separator === "tab" ? "\t" : data.data_separator === "comma" ? "," : ";";
    const maps = parseMapData(data.data, separator, data.data_format, setDataError);
    console.log("Post parse data, errors: ", form.formState.errors);

    if (form.formState.errors.data !== undefined) {
      return;
    }

    if (maps.length === 0) {
      toast.error("No maps to add!");
      return;
    }

    //Add campaign_id to maps
    maps.forEach((map) => {
      if (data.campaign.length > 0) {
        map.campaign_id = data.campaign[0].id;
      } else {
        map.campaign_id = null;
      }
    });

    console.log("Maps to add:", maps);

    // addMap for all maps
    const addMapRecursive = (mapIndex) => {
      if (mapIndex >= maps.length) {
        return;
      }
      let currentMap = maps[mapIndex];
      addMap(currentMap).then((data) => {
        toast.success("Map '" + getMapName(currentMap) + "' added");
        const newMapId = data.id;
        if (currentMap.golden !== undefined) {
          const golden = currentMap.golden;
          golden.map_id = newMapId;
          addGoldenRun(golden).then((data) => {
            toast.success("Added Golden Run for '" + getMapName(currentMap) + "'");
          });
        }
        addMapRecursive(mapIndex + 1);
      });
    };
    addMapRecursive(0);

    queryClient.invalidateQueries(["playerMapList", userSession.user.name]);
    queryClient.invalidateQueries(["playerMapRanks", userSession.user.name]);
    queryClient.invalidateQueries(["playerGeneralStats", userSession.user.name]);
    queryClient.invalidateQueries(["topGoldenList", userSession.user.name]);
  });

  const data_format = form.watch("data_format");
  const data_separator = form.watch("data_separator");
  const validateData = (data) => {
    if (data.length === 0) {
      return "No data provided";
    }

    const separator = data_separator === "tab" ? "\t" : data_separator === "comma" ? "," : ";";
    const maps = parseMapData(data, separator, data_format, setDataError);
    if (form.formState.errors.data !== undefined) {
      return form.formState.errors.data.message;
    }

    form.setValue("preview", maps.map((map) => JSON.stringify(map, null, 2)).join("\n"));
    return true;
  };

  const campaignListQuery = useQuery({
    queryKey: ["campaignList", userSession.user?.name],
    queryFn: () => getPlayerCampaignList(userSession.user?.name),
    enabled: userSession.isLoggedIn,
    onError: (error) => {
      toast.error("Error loading campaign list: " + parseAxiosError(error).message);
    },
  });

  const { mutateAsync: addMap } = useMutation({
    mutationFn: (map) => addPlayerMapData(map),
    onError: (error) => {
      toast.error("Error adding map: " + parseAxiosError(error).message);
    },
  });

  const { mutateAsync: addGoldenRun } = useMutation({
    mutationFn: (mapId) => createPlayerMapFullGoldenRun(mapId),
    onSuccess: (data) => {
      console.log("Added golden run data, new id: ", data.id);
    },
  });

  const openAddCampaignModalRef = useRef(null);
  const onCloseAddCampaign = (cancelled, campaignName) => {
    if (!cancelled) {
      queryClient.invalidateQueries(["campaignList", userSession.user?.name]);
    }
  };

  if (!userSession.isLoggedIn) {
    return (
      <Container>
        <h1 className="text-danger">Login to add maps!</h1>
      </Container>
    );
  }

  if (campaignListQuery.isLoading) {
    return <LoadingContainer title={"Bulk Add Maps"} />;
  }

  const handleKeydown = (event) => {
    if (event.key == "Tab") {
      event.preventDefault();
      var start = event.target.selectionStart;
      var end = event.target.selectionEnd;
      event.target.value = event.target.value.substring(0, start) + "\t" + event.target.value.substring(end);
      event.target.selectionStart = event.target.selectionEnd = start + 1;
    }
  };

  //the users localized date format
  const date = new Date("2022-08-21");
  const dateString = date.toLocaleDateString();

  const separatorPreview = data_separator === "tab" ? " <tab> " : data_separator === "comma" ? ", " : "; ";
  const dataFormatString = data_format.map((d) => d.label).join(separatorPreview);
  const dataFormatExample = data_format.map((d) => exampleData[d.value]).join(separatorPreview);

  return (
    <Container>
      <Instructions dateString={dateString} />
      <hr />
      <h1>Bulk Add Maps</h1>
      <Form onSubmit={onSubmit} className="mb-5">
        <Row>
          <Col lg={4}>
            <Form.Group className="mb-3">
              <Form.Label>Campaign Name</Form.Label>
              <InputGroup>
                <Controller
                  control={form.control}
                  name="campaign"
                  render={({ field }) => (
                    <Typeahead
                      id="campaign"
                      name="campaign"
                      selected={field.value}
                      labelKey={(o) => o.name}
                      options={campaignListQuery.data ?? []}
                      placeholder="Choose a campaign..."
                      onChange={(selection) => field.onChange(selection)}
                    />
                  )}
                />
                <Button variant="primary" onClick={() => openAddCampaignModalRef.current()} className="">
                  <AddIcon /> Add Campaign
                </Button>
              </InputGroup>
              <Form.Text className="text-muted">
                Select a campaign to add all maps to it. Leave empty to add standalone maps.
              </Form.Text>
            </Form.Group>
          </Col>
          <Col lg={8}>
            <Form.Group className="mb-3">
              <Form.Label>Map Data Format</Form.Label>
              <Controller
                control={form.control}
                name="data_format"
                render={({ field }) => (
                  <Typeahead
                    id="data_format"
                    name="data_format"
                    selected={field.value}
                    labelKey={(o) => o.label}
                    options={dataSelectOptions}
                    multiple
                    onChange={(selection) => field.onChange(selection)}
                  />
                )}
              />

              <Form.Label className="mt-2">Data Separator</Form.Label>
              <Form.Select
                style={{ maxWidth: "150px" }}
                {...form.register("data_separator", { required: true })}
                isInvalid={form.formState.errors.data_separator !== undefined}
              >
                <option value="tab">Tab</option>
                <option value="comma">Comma</option>
                <option value="semicolon">Semicolon</option>
              </Form.Select>

              {data_format.length > 0 && (
                <>
                  <Form.Text className="text-muted">
                    Data Format: <code>{dataFormatString}</code>
                  </Form.Text>
                  <br />
                  <Form.Text className="text-muted">
                    Example: <code>{dataFormatExample}</code>
                  </Form.Text>
                </>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="data" className="mb-3">
          <Form.Label>Map Data</Form.Label>
          <Form.Control
            as="textarea"
            rows={10}
            onKeyDown={handleKeydown}
            {...form.register("data", { required: true, validate: validateData })}
            isInvalid={form.formState.errors.data !== undefined}
          />
          {form.formState.errors.data && (
            <>
              <Form.Control.Feedback type="invalid">
                {form.formState.errors.data.message}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Make sure the selected Data Format is correct!
              </Form.Control.Feedback>
            </>
          )}
        </Form.Group>

        <Stack direction="horizontal" gap={3}>
          <Button type="submit">
            <AddIcon className="me-2" />
            Bulk Add
          </Button>
          <Button
            variant="secondary"
            className="ms-auto"
            onClick={() => {
              form.trigger("data");
            }}
          >
            Create Preview
          </Button>
        </Stack>

        <hr />
        <Form.Group controlId="preview" className="mb-3">
          <Form.Label>Maps Preview</Form.Label>
          <Form.Control as="textarea" rows={10} readOnly {...form.register("preview")} />
        </Form.Group>
      </Form>

      <AddCampaignModal
        openRef={openAddCampaignModalRef}
        onClose={onCloseAddCampaign}
        userName={userSession.user.name}
      />
    </Container>
  );
}

function Instructions({ dateString }) {
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <b>Bulk Add Instructions</b>
        </Accordion.Header>
        <Accordion.Body>
          <p>This page is for adding maps in bulk. The format:</p>
          <ul>
            <li>One line per map</li>
            <li>Values for each map are separated by a selectable separator.</li>
            <li>The only required field is the Map Name. All other fields are optional.</li>
            <li>
              Using the Data Format selection you can select which fields you want to add and in what order.
            </li>
          </ul>
          <p>Data Formats:</p>
          <ul>
            <li>Truth Values (e.g. Cleared?, Goldened?): Either (1, True, Yes) or (0, False, No) </li>
            <li>Duration Values (e.g. Clear Time): HH:MM:SS, e.g. 5:13:41, 0:09:12, 0:0:5</li>
            <li>Dates (e.g. Cleared On): yyyy-mm-dd, e.g. 2023-07-15</li>
            <li>
              Enjoyment/Difficulty Values: Float number, (0 &lt;= enjoyment &lt;= 10), (0 &lt;= difficulty
              &lt;= 100)
            </li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

function parseMapData(text, separator, data_format, setDataError) {
  let error = {
    message: null,
    line: null,
  };

  const maps = text.split("\n").map((line, index) => {
    const map = {};
    const values = line.split(separator);

    //Check if lengths line up
    if (values.length !== data_format.length) {
      error.message = "Line had " + values.length + " values, expected " + data_format.length;
      error.line = index + 1;
      return;
    }

    for (let i = 0; i < data_format.length; i++) {
      let format = data_format[i];
      const isGolden = format.value.startsWith("golden_");
      const goldenErrorAddition = isGolden ? " (Golden)" : "";

      let value = values[i].trim();

      let formatValue = format.value.replace("golden_", "");
      let formatLabel = format.label;

      if (value === undefined) {
        if (format === "name") {
          error.message = "Missing value for 'Map Name'";
          error.line = index + 1;
          return;
        }
        continue;
      }

      if (formatValue === "cleared" || formatValue === "is_fc" || formatValue === "is_sb") {
        let isTrue = value === "1" || value.toLowerCase() === "true" || value.toLowerCase() === "yes";
        let isFalse = value === "0" || value.toLowerCase() === "false" || value.toLowerCase() === "no";

        if (!isTrue && !isFalse) {
          error.message = "Invalid value for '" + formatLabel + "'" + goldenErrorAddition;
          error.line = index + 1;
          return;
        }

        value = isTrue;
      } else if (formatValue === "clear_duration") {
        value = clearDurationToSeconds(value);
        if (value === null) {
          error.message = "Invalid value for '" + formatLabel + "'" + goldenErrorAddition;
          error.line = index + 1;
          return;
        }
      } else if (formatValue === "clear_deaths") {
        value = parseInt(value);
        if (isNaN(value)) {
          error.message = "Invalid value for '" + formatLabel + "'" + goldenErrorAddition;
          error.line = index + 1;
          return;
        }
      } else if (formatValue === "cleared_on") {
        let date = new Date(value);
        console.log("date:", date, "value:", value);
        if (isNaN(date.getTime())) {
          error.message = "Invalid value for '" + formatLabel + "'" + goldenErrorAddition;
          error.line = index + 1;
          return;
        }
        value = date.toISOString().split("T")[0];
      } else if (formatValue === "difficulty") {
        value = parseFloat(value);
        if (isNaN(value) || value < 0 || value > 100) {
          error.message = "Invalid value for '" + formatLabel + "'" + goldenErrorAddition;
          error.line = index + 1;
          return;
        } else if (value < 0) {
          error.message = "'" + formatLabel + "' was lower than 0" + goldenErrorAddition;
          error.line = index + 1;
          return;
        } else if (value > 100) {
          error.message = "'" + formatLabel + "' was higher than 100" + goldenErrorAddition;
          error.line = index + 1;
          return;
        }
      } else if (formatValue === "enjoyment") {
        value = parseFloat(value);
        if (isNaN(value)) {
          error.message = "Invalid value for '" + formatLabel + "'" + goldenErrorAddition;
          error.line = index + 1;
          return;
        } else if (value < 0) {
          error.message = "'" + formatLabel + "' was lower than 0" + goldenErrorAddition;
          error.line = index + 1;
          return;
        } else if (value > 10) {
          error.message = "'" + formatLabel + "' was higher than 10" + goldenErrorAddition;
          error.line = index + 1;
          return;
        }
      } else if (formatValue === "sheet_difficulty_id") {
        value = parseSheetDifficultyId(value);
        if (value === null) {
          error.message = "Invalid value for '" + formatLabel + "'" + goldenErrorAddition;
          error.line = index + 1;
          return;
        }
      }

      if (isGolden) {
        if (map.golden === undefined) {
          map.golden = {};
        }
        map.golden[formatValue] = value;
      } else {
        map[formatValue] = value;
      }
    }

    //Check for required field: name
    console.log("Checking for required field: name | map:", map);
    if (map.name === undefined) {
      error.message = "Missing value for 'Map Name'";
      error.line = index + 1;
      return;
    }

    return map;
  });

  setDataError(error.message, error.line);
  return maps;
}

function parseSheetDifficultyId(name) {
  name = name.trim().toLowerCase();
  //Replace certain characters: (, )
  name = name.replace("(", "");
  name = name.replace(")", "");

  //SHEET_DIFFICULTIES is an array with each entry being a tier of format: {id, name, subtier}
  //Find the entry where name matches "subtier name" or "name subtier"

  let tier = SHEET_DIFFICULTIES.find((tier) => {
    if (tier.subtier === null) {
      return tier.name.toLowerCase() === name;
    } else {
      let tierName1 = tier.name.toLowerCase() + " " + tier.subtier.toLowerCase();
      let tierName2 = tier.subtier.toLowerCase() + " " + tier.name.toLowerCase();
      return tierName1 === name || tierName2 === name;
    }
  });

  if (tier === undefined) {
    return null;
  }
  return tier.id;
}
