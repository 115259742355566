import { useQuery } from "react-query";
import { getServerSettings, parseAxiosError } from "../hooks/CelesteStatsApi";
import { toast } from "react-toastify";

export default function useServerSettings() {
  return useQuery({
    queryKey: ["serverSettings"],
    queryFn: () => getServerSettings(),
    onError: (error) => {
      toast.error("Couldn't fetch server settings: " + parseAxiosError(error).message);
    },
  });
}
