import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./GoldenRunsDropdown.css";
import { SHEET_DIFFICULTIES } from "./MiscComponents";
import { CrossIcon, DeathsIcon, GoldberryIcon, TextWithIcon, TimeIcon } from "./Icons";
import {
  formatClearDuration,
  getGoldenDescription,
  numberWithCommas,
} from "../pages/celeste-stats/StatsUtil";
import { capitalizeFirstLetter } from "../util/OtherUtils";

export function GoldenRunsDropdown({ goldens }) {
  if (goldens === undefined || goldens === null || goldens.length === 0) return null;
  if (goldens.length === 1) return <GoldenRunDisplay golden={goldens[0]} doLink={true} />;

  //Sort by sheet_difficulty_id ascending, then by clear_duration descending. Sort nulls to the end.
  goldens = goldens.sort((a, b) => {
    if (a.sheet_difficulty_id === null) return 1;
    if (b.sheet_difficulty_id === null) return -1;
    if (a.sheet_difficulty_id !== b.sheet_difficulty_id) return a.sheet_difficulty_id - b.sheet_difficulty_id;
    return b.clear_duration - a.clear_duration;
  });

  const aggregateGolden = {
    id: "aggregate",
    description: "Total",
    cleared: goldens.every((golden) => golden.cleared),
    clear_duration: goldens.reduce((sum, golden) => sum + golden.clear_duration, 0),
    clear_deaths: goldens.reduce((sum, golden) => sum + golden.clear_deaths, 0),
    sheet_difficulty_id: goldens[0].sheet_difficulty_id,
  };
  return (
    <Dropdown>
      <Dropdown.Toggle as={GoldenRunDisplay} isToggle={true} golden={aggregateGolden} />
      <Dropdown.Menu>
        <Dropdown.ItemText>All Golden Runs</Dropdown.ItemText>
        {goldens.map((golden) => {
          return <Dropdown.ItemText key={golden.id} as={GoldenRunDisplay} golden={golden} doLink={true} />;
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

//Other sizes: small, large
export function GoldenRunDisplay({ golden, className, doLink = false, isToggle = false, ...props }) {
  const tier = SHEET_DIFFICULTIES.find((difficulty) => difficulty.id === golden.sheet_difficulty_id) ?? {};
  const backgroundColor = tier.background_color ?? "white";
  const color = tier.background_color === null ? "black" : tier.color ?? "black";

  const clearedIcon = golden.cleared ? <GoldberryIcon style={{ marginBottom: "2px" }} /> : <CrossIcon />;
  const timeStr = formatClearDuration(golden.clear_duration);
  const deathsStr = numberWithCommas(golden.clear_deaths);
  const tierString =
    tier.name !== undefined
      ? tier.subtier === null || true
        ? tier.name
        : capitalizeFirstLetter(tier.subtier) + " " + tier.name
      : "-";

  let description = getGoldenDescription(golden);
  //Remove the string "Golden" IF golden.description is null
  if (golden.description === null || golden.description === "" || golden.description === undefined) {
    description = description.replace("Golden", "").trim();
  }

  const isToggleClass = isToggle ? " golden-run-display-toggle" : "";

  const anchor = (
    <a
      className={className + " golden-run-display" + isToggleClass}
      style={{ backgroundColor: backgroundColor, color: color, border: "1px solid #888" }}
      role="button"
      href={doLink && golden.proof_url !== null ? golden.proof_url : undefined}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      <div className="text-center clear">{clearedIcon}</div>
      <span className="text-center tier">{tierString}</span>
      <TextWithIcon className="time">
        <TimeIcon />
        {timeStr !== "" ? timeStr : "-"}
      </TextWithIcon>
      <TextWithIcon className="deaths">
        <DeathsIcon />
        {deathsStr !== "" ? deathsStr : "-"}
      </TextWithIcon>
      <span className="might-overflow description">{description}</span>
    </a>
  );

  if (golden.notes === null || golden.notes === "" || golden.notes === undefined) {
    return anchor;
  }

  return (
    <OverlayTrigger placement="right" overlay={<Tooltip>{golden.notes}</Tooltip>}>
      {anchor}
    </OverlayTrigger>
  );
}
