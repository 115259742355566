import goldberryGif from "../assets/journal/goldberry_animated_highres.gif";
import time from "../assets/journal/time.png";
import skullA from "../assets/journal/skullA.png";
import clear from "../assets/journal/clear.png";
import journal from "../assets/gui/journal.png";
import turnDownRightArrow from "../assets/icons/turn-right-arrow.svg";
import glumbsup from "../assets/emotes/glumpsup.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faTrash,
  faEdit,
  faAdd,
  faNoteSticky,
  faBolt,
  faSave,
  faTimes,
  faCog,
  faRightToBracket,
  faRightFromBracket,
  faUserPlus,
  faHammer,
  faList,
  faHome,
  faStar,
  faHeart,
  faWeight,
  faDumbbell,
  faWeightHanging,
  faCircle,
  faSquare,
  faUser,
  faShieldHalved,
  faEnvelope,
  faKey,
  faEye,
  faUsers,
  faCircleCheck,
  faChartSimple,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Stack } from "react-bootstrap";

const IconSizes = {
  "very-tiny": 10,
  tiny: 14,
  small: 20,
  "medium-small": 24,
  medium: 30,
  large: 40,
};
const FontAwesomeIconSizes = {
  "very-tiny": "xs",
  tiny: "sm",
  small: "sm",
  "medium-small": "lg",
  medium: "lg",
  large: "lg",
  huge: "2x",
};

//#region Celeste Icons
export function ClearIcon({ size = "small", ...props }) {
  let width = IconSizes[size] ?? IconSizes.small;
  return <img src={clear} width={width} height={width} alt="" {...props} />;
}

export function DeathsIcon({ size = "small", ...props }) {
  let width = IconSizes[size] ?? IconSizes.small;
  return <img src={skullA} width={width} height={width} alt="" {...props} />;
}

export function TimeIcon({ size = "small", ...props }) {
  let width = IconSizes[size] ?? IconSizes.small;
  return <img src={time} width={width} height={width} alt="" {...props} />;
}

export function JournalIcon({ size = "small", ...props }) {
  let width = IconSizes[size] ?? IconSizes.small;
  return <img src={journal} width={width} height={width} alt="" {...props} />;
}

export function GoldberryIcon({ size = "small", ...props }) {
  const sizes = {
    tiny: {
      width: 14,
      height: 12,
    },
    small: {
      width: 18,
      height: 16,
    },
    medium: {
      width: 30,
      height: 24,
    },
    large: {
      width: 40,
      height: 32,
    },
  };

  let { width, height } = sizes[size] ?? sizes.small;

  return <img src={goldberryGif} width={width} height={height} alt="" {...props} />;
}

export function TurnDownRightArrowIcon({ size = "small", ...props }) {
  let width = IconSizes[size] ?? IconSizes.small;
  return <img src={turnDownRightArrow} width={width} height={width} alt="" {...props} />;
}
//#endregion

//#region Emote Icons
export function GlumbsupIcon({ size = "small", ...props }) {
  let width = IconSizes[size] ?? IconSizes.small;
  return <img src={glumbsup} width={width} height={width} alt="" {...props} />;
}
//#endregion

//#region FontAwesome Manage Icons
export function DeleteIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faTrash} size={width} {...props} />;
}
export function EditIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faEdit} size={width} {...props} />;
}
export function AddIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faAdd} size={width} {...props} />;
}
export function NoteIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faNoteSticky} size={width} {...props} />;
}
export function ActionIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faBolt} size={width} {...props} />;
}
export function ExternalLinkIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faArrowUpRightFromSquare} size={width} {...props} />;
}
export function SaveIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faSave} size={width} {...props} />;
}
export function CrossIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faTimes} size={width} {...props} />;
}
export function SuccessIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faCircleCheck} size={width} {...props} />;
}

export function UserIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faUser} size={width} {...props} />;
}
export function EmailIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faEnvelope} size={width} {...props} />;
}
export function PasswordIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faKey} size={width} {...props} />;
}
export function VisibilityIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faEye} size={width} {...props} />;
}
export function InfoIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faInfoCircle} size={width} {...props} />;
}
//#endregion

//#region FontAwesome Nav Icons
export function HomeIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faHome} size={width} {...props} />;
}
export function PublicPlayersIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faUsers} size={width} {...props} />;
}

export function SignInIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faRightToBracket} size={width} {...props} />;
}
export function SignOutIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faRightFromBracket} size={width} {...props} />;
}
export function SignUpIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faUserPlus} size={width} {...props} />;
}
export function SettingsIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faCog} size={width} {...props} />;
}
export function ShieldIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faShieldHalved} size={width} {...props} />;
}
export function AdminPanelIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faHammer} size={width} {...props} />;
}

export function ModListIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faList} size={width} {...props} />;
}
export function TopGoldenListIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faStar} size={width} {...props} />;
}
export function ClearsEnjoymentListIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faHeart} size={width} {...props} />;
}
export function ClearsDifficultyListIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faDumbbell} size={width} {...props} />;
}
export function GoldensEnjoymentListIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return (
    <FontAwesomeIcon
      icon={faHeart}
      mask={faSquare}
      transform={"shrink-5"}
      width={width}
      height={width}
      {...props}
    />
  );
}
export function GoldensDifficultyListIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return (
    <FontAwesomeIcon
      icon={faDumbbell}
      mask={faSquare}
      transform={"shrink-6"}
      width={width}
      height={width}
      {...props}
    />
  );
}
//#endregion

//#region FontAwesome Brand Icons
export function YouTubeIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faYoutube} size={width} {...props} />;
}
//#endregion

//#region FontAwesome Misc Icons
export function ChartIcon({ size = "tiny", ...props }) {
  let width = FontAwesomeIconSizes[size] ?? FontAwesomeIconSizes.small;
  return <FontAwesomeIcon icon={faChartSimple} size={width} {...props} />;
}
//#endregion

export function TextWithIcon({ children, gap, style, ...props }) {
  return (
    <Stack direction="horizontal" gap={gap ?? 1} style={{ ...style, height: "max-content" }} {...props}>
      {children}
    </Stack>
  );
}
