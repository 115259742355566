function toNullableFloat(value) {
  if (value === null || value === undefined) return null;
  if (typeof value === typeof 1.2) return value;
  if (typeof value !== typeof "") return null;
  if (value.trim() === "") return null;
  let float = parseFloat(value.trim());
  if (isNaN(float)) return value;
  return float;
}

function toNullableInt(value) {
  if (value === null || value === undefined) return null;
  if (typeof value === typeof 1) return value;
  if (typeof value !== typeof "") return null;
  if (value.trim() === "") return null;
  let int = parseInt(value.trim());
  if (isNaN(int) || value.indexOf(".") !== -1) return value;
  return int;
}

export const FormOptions = {
  Difficulty: {
    setValueAs: toNullableFloat,
    validate: (v) =>
      v === null || (typeof v === typeof 1.2 && v >= 0 && v <= 100) || "'" + v + "' is not valid value",
  },
  Enjoyment: {
    setValueAs: toNullableFloat,
    validate: (v) =>
      v === null || (typeof v === typeof 1.2 && v >= 0 && v <= 10) || "'" + v + "' is not valid value",
  },
  ClearTime: {
    pattern: /^(\d{1,4}:)?\d{1,2}:\d{1,2}$/,
  },
  ClearDeaths: {
    pattern: /^\d+$/,
    setValueAs: toNullableInt,
  },
  Username: {
    required: {
      value: true,
      message: "Username can't be empty",
    },
    minLength: {
      value: 3,
      message: "Username must be at least 3 characters long",
    },
    maxLength: {
      value: 32,
      message: "Username can't be longer than 32 characters",
    },
  },
  Password: {
    required: {
      value: true,
      message: "Password can't be empty",
    },
    minLength: {
      value: 6,
      message: "Password must be at least 6 characters long",
    },
    maxLength: {
      value: 128,
      message: "Password can't be longer than 128 characters",
    },
  },
  Email: {
    required: {
      value: true,
      message: "Email can't be empty",
    },
    pattern: {
      value: /^\S+@\S+\.\S+$/,
      message: "Invalid email address",
    },
  },
  CampaignName: {
    maxLength: {
      value: 128,
      message: "Campaign name can't be longer than 128 characters",
    },
  },
  CampaignNameRequired: {
    required: {
      value: true,
      message: "Campaign name can't be empty",
    },
    maxLength: {
      value: 128,
      message: "Campaign name can't be longer than 128 characters",
    },
  },
  CampaignUrl: {
    maxLength: {
      value: 256,
      message: "Campaign URL can't be longer than 256 characters",
    },
  },
};
