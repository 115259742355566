import { useRef, useState } from "react";
import "../../css/Player.css";
import { useParams, Link, useNavigate } from "react-router-dom";
import { formatClearDuration, getMapName, numberWithCommas } from "./StatsUtil";
import { Container, Table, Row, Col, Button, Stack, Modal, Form, Alert } from "react-bootstrap";

import {
  DifficultyDisplay,
  EnjoymentDisplay,
  LoadingContainer,
  PlayerProfileNavbar,
} from "../../components/MiscComponents";
import { QuickEditMap } from "./QuickEditMap";
import {
  ActionIcon,
  AddIcon,
  ChartIcon,
  ClearIcon,
  DeathsIcon,
  DeleteIcon,
  EditIcon,
  ExternalLinkIcon,
  GoldberryIcon,
  JournalIcon,
  NoteIcon,
  TimeIcon,
  UserIcon,
} from "../../components/Icons";
import {
  deleteMap,
  getPlayerAllGoldenStats,
  getPlayerGeneralInfo,
  getPlayerMapData,
  getPlayerMapRanks,
  parseAxiosError,
} from "../../hooks/CelesteStatsApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { CustomModal, useModal } from "../../hooks/useModal";
import { useUserSession } from "../../hooks/useUserSession";
import { AddCampaignModal } from "./AddCampaign";
import { useLocalStorage } from "../../hooks/useStorage";
import { GoldenRunsDropdown } from "../../components/GoldenRunsDropdown";

export default function PlayerMapDataPage() {
  let { playerName, sortMode } = useParams();
  document.title = playerName + "'s Mod List - Celeste Stats";
  const [localStorageSort, setLocalStorageSort] = useLocalStorage("playerMapListSort", "id");

  const navigate = useNavigate();
  const userSession = useUserSession();
  const isOwnPage = userSession.isOwnPage(playerName);

  let [sort, setSort] = useState(sortMode ?? localStorageSort ?? "id");

  const playerMapListQuery = useQuery({
    queryKey: ["playerMapList", playerName],
    queryFn: () => getPlayerMapData(playerName),
    onError: (error) => {
      toast.error("Couldn't fetch map data: " + parseAxiosError(error).message);
    },
  });

  const playerMapRanksQuery = useQuery({
    queryKey: ["playerMapRanks", playerName],
    queryFn: () => getPlayerMapRanks(playerName),
    onError: (error) => {
      toast.error("Couldn't fetch map ranks: " + parseAxiosError(error).message);
    },
  });

  const playerAllGoldenRunsQuery = useQuery({
    queryKey: ["playerAllGoldenRuns", playerName],
    queryFn: () => getPlayerAllGoldenStats(playerName),
    onError: (error) => {
      toast.error("Couldn't fetch golden runs list: " + parseAxiosError(error).message);
    },
  });

  const modalRefs = {
    openDeleteModal: useRef(),
    openEditModal: useRef(),
    openAddCampaignModal: useRef(),
  };

  function handleSortChange(event) {
    setSort(event.target.value);
    setLocalStorageSort(event.target.value);
    if (event.target.value === "id") {
      navigate("/player/" + playerName + "", { replace: true });
    } else {
      navigate("/player/" + playerName + "/" + event.target.value, {
        replace: true,
      });
    }
  }

  if (playerMapListQuery.isLoading || playerMapRanksQuery.isLoading || playerAllGoldenRunsQuery.isLoading) {
    return (
      <>
        <Container>
          <PlayerGeneralStats playerName={playerName} activePage={"/player/" + playerName} />
        </Container>
        <LoadingContainer fluid title="Full Mod List" />
      </>
    );
  }

  let playerMapRanks = playerMapRanksQuery.data;

  return (
    <>
      <Container>
        <PlayerGeneralStats playerName={playerName} activePage={"/player/" + playerName} />
      </Container>
      <Container fluid className="mb-3">
        <Stack direction="horizontal" gap={1} className="player-data-sort-box">
          <SortBox onSortChange={handleSortChange} defaultSort={sort} />
          {isOwnPage ? (
            <div className="ms-auto">
              <Button
                variant="primary"
                onClick={() => modalRefs.openAddCampaignModal.current()}
                className="me-2"
              >
                <AddIcon /> Add Campaign
              </Button>
              <Link to="/add-map">
                <Button variant="primary">
                  <AddIcon /> Add Map
                </Button>
              </Link>
            </div>
          ) : null}
        </Stack>
      </Container>
      {!playerMapListQuery.isError && !playerMapRanksQuery.isError && !playerAllGoldenRunsQuery.isError ? (
        <PlayerDataTable
          playerName={playerName}
          sort={sort}
          playerMapData={playerMapListQuery.data}
          playerMapRanks={playerMapRanks}
          playerAllGoldenRuns={playerAllGoldenRunsQuery.data}
          isOwnPage={isOwnPage}
          deleteMapModalRef={modalRefs.openDeleteModal}
          editMapModalRef={modalRefs.openEditModal}
        />
      ) : (
        <Container fluid>
          <Alert variant="danger">
            Couldn't fetch map data: {parseAxiosError(playerMapListQuery.error).message}
          </Alert>
        </Container>
      )}
      <ModalContainer playerName={playerName} modalRefs={modalRefs} />
    </>
  );
}

function ModalContainer({ playerName, modalRefs }) {
  const queryClient = useQueryClient();

  const { mutate: deleteMapData } = useMutation({
    mutationFn: (id) => deleteMap(id),
    onSuccess: () => {
      toast.success("Map deleted successfully!");
    },
    onError: (error) => {
      toast.error("Couldn't delete map: " + parseAxiosError(error).message);
    },
  });

  const deleteModal = useModal(null, (cancelled, map) => {
    if (cancelled) return;
    deleteMapData(map.id);
    queryClient.setQueryData(["playerMapList", playerName], (oldData) => {
      return oldData.filter((otherMap) => otherMap.id !== map.id);
    });
  });

  const editModal = useModal(null, (cancelled, mapData) => {
    queryClient.invalidateQueries(["playerMapList", playerName]);
    queryClient.invalidateQueries(["playerMapRanks", playerName]);
    queryClient.invalidateQueries(["playerGeneralStats", playerName]);

    if (cancelled) return;

    toast.success("Map updated!");
  });

  modalRefs.openDeleteModal.current = deleteModal.open;
  modalRefs.openEditModal.current = editModal.open;

  return (
    <>
      <AddCampaignModal openRef={modalRefs.openAddCampaignModal} userName={playerName} />

      <CustomModal
        modalHook={deleteModal}
        options={{
          title: "Confirm Deletion",
          acceptText: "Delete",
          acceptVariant: "danger",
        }}
      >
        Are you sure you want to delete "<strong>{getMapName(deleteModal.data)}</strong>" and all attached
        stats & golden runs?
      </CustomModal>

      <Modal show={editModal.isVisible} onHide={editModal.cancel} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Quick Edit Map</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuickEditMap
            playerName={playerName}
            id={editModal.data}
            onSave={(mapData) => editModal.close(false, mapData)}
            onCancel={editModal.cancel}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

function PlayerDataHeader({ playerName }) {
  return (
    <div className="player-data-header">
      <h1>{playerName}'s Mod List</h1>
    </div>
  );
}
function SortBox({ onSortChange, defaultSort }) {
  return (
    <Form>
      <Form.Group controlId="sort" as={Row}>
        <Form.Label column md="auto">
          Sort By
        </Form.Label>
        <Col>
          <Form.Select onChange={onSortChange} defaultValue={defaultSort}>
            <option value="-id">Date Added ↓</option>
            <option value="id">Date Added ↑</option>
            <option value="-name">Name ↓</option>
            <option value="name">Name ↑</option>
            <option value="-enjoyment">Enjoyment ↓</option>
            <option value="enjoyment">Enjoyment ↑</option>
            <option value="-difficulty">Difficulty ↓</option>
            <option value="difficulty">Difficulty ↑</option>
            <option value="-clear_duration">Clear Time ↓</option>
            <option value="clear_duration">Clear Time ↑</option>
            <option value="-clear_deaths">Clear Deaths ↓</option>
            <option value="clear_deaths">Clear Deaths ↑</option>
            <option value="-golden_duration">Golden Time ↓</option>
            <option value="golden_duration">Golden Time ↑</option>
            <option value="-golden_deaths">Golden Deaths ↓</option>
            <option value="golden_deaths">Golden Deaths ↑</option>
            <option value="-golden_one_tier_sort">Golden Tier ↓</option>
          </Form.Select>
        </Col>
      </Form.Group>
    </Form>
  );
}

export function PlayerGeneralStats({ playerName, activePage, ...props }) {
  const placeholder = {
    maps_cleared: 0,
    campaigns_cleared: 0,
    total_clear_time: 0,
    total_clear_deaths: 0,
    total_golden_time: 0,
    total_golden_deaths: 0,
    maps_goldened: 0,
  };

  const playerGeneralStatsQuery = useQuery({
    queryKey: ["playerGeneralStats", playerName],
    queryFn: () => getPlayerGeneralInfo(playerName),
    placeholderData: placeholder,
  });

  let playerGeneralStats = playerGeneralStatsQuery.isError ? placeholder : playerGeneralStatsQuery.data;

  const separatorStyle = {
    backgroundColor: "lightgray",
    width: "100%",
    height: "100%",
  };

  return (
    <Container className="mt-2" {...props}>
      <h4>
        <UserIcon size="large" /> {playerName}'s Mod List
      </h4>
      {/* <h4>
        <ChartIcon /> General Stats
      </h4> */}
      {playerGeneralStatsQuery.isError && (
        <Alert variant="danger">
          Couldn't fetch general stats: {parseAxiosError(playerGeneralStatsQuery.error).message}
        </Alert>
      )}

      <div className="general-stats-grid mb-2">
        <div className="general-stats-inner-grid">
          <div style={{ display: "contents" }}>
            <b style={{ justifySelf: "end" }}>Maps Cleared</b>
            <Stack direction="horizontal" gap={1}>
              {playerGeneralStats.maps_cleared ?? 0}
              <ClearIcon />
            </Stack>
          </div>

          <div style={{ display: "contents" }}>
            <b style={{ justifySelf: "end" }}>Campaign(s)</b>
            <Stack direction="horizontal" gap={1}>
              {playerGeneralStats.campaigns_played ?? 0}
              <JournalIcon />
            </Stack>
          </div>

          <div style={{ display: "contents" }}>
            <Stack direction="horizontal" gap={1} style={{ justifySelf: "end" }}>
              <b>Clear</b>
              <TimeIcon />
            </Stack>
            {formatClearDuration(playerGeneralStats.total_clear_time ?? 0)}
          </div>

          <div style={{ display: "contents" }}>
            <Stack direction="horizontal" gap={1} style={{ justifySelf: "end" }}>
              <b>Clear</b>
              <DeathsIcon />
            </Stack>
            {numberWithCommas(playerGeneralStats.total_clear_deaths ?? 0)}
          </div>
        </div>
        <div style={{ ...separatorStyle }} />
        <div className="general-stats-inner-grid">
          <div style={{ display: "contents" }}>
            <b style={{ gridArea: "1 / 1", justifySelf: "end" }}>Goldens Done</b>
            <Stack style={{ gridArea: "1 / 2" }} direction="horizontal" gap={1}>
              {playerGeneralStats.maps_goldened ?? 0}
              <GoldberryIcon />
            </Stack>
          </div>

          <div style={{ display: "contents" }}>
            <Stack direction="horizontal" gap={1} style={{ gridArea: "3 / 1", justifySelf: "end" }}>
              <b>Golden</b>
              <TimeIcon />
            </Stack>
            <span style={{ gridArea: "3 / 2" }}>
              {formatClearDuration(playerGeneralStats.total_golden_time ?? 0)}
            </span>
          </div>

          <div style={{ display: "contents" }}>
            <Stack direction="horizontal" gap={1} style={{ gridArea: "4 / 1", justifySelf: "end" }}>
              <b>Golden</b>
              <DeathsIcon />
            </Stack>
            <span style={{ gridArea: "4 / 2" }}>
              {numberWithCommas(playerGeneralStats.total_golden_deaths ?? 0)}
            </span>
          </div>
        </div>
        <div style={{ ...separatorStyle }} />
        <div className="general-stats-inner-grid">
          <div style={{ display: "contents" }}>
            <Stack direction="horizontal" gap={1} style={{ gridArea: "3 / 1", justifySelf: "end" }}>
              <b>Total</b>
              <TimeIcon />
            </Stack>
            <span style={{ gridArea: "3 / 2" }}>
              {formatClearDuration(
                (playerGeneralStats.total_clear_time ?? 0) + (playerGeneralStats.total_golden_time ?? 0)
              )}
            </span>
          </div>

          <div style={{ display: "contents" }}>
            <Stack direction="horizontal" gap={1} style={{ gridArea: "4 / 1", justifySelf: "end" }}>
              <b>Total</b>
              <DeathsIcon />
            </Stack>
            <span style={{ gridArea: "4 / 2" }}>
              {numberWithCommas(
                (playerGeneralStats.total_clear_deaths ?? 0) + (playerGeneralStats.total_golden_deaths ?? 0)
              )}
            </span>
          </div>
        </div>
      </div>
      <PlayerProfileNavbar playerName={playerName} activePage={activePage} />
    </Container>
  );
}

function PlayerDataTable({
  playerName,
  sort,
  playerMapData,
  playerMapRanks,
  playerAllGoldenRuns,
  isOwnPage,
  deleteMapModalRef,
  editMapModalRef,
}) {
  function sortToSortColumnAndOrder(sort) {
    let sortColumn = sort;
    let sortOrder = "asc";
    if (sort.startsWith("-")) {
      sortColumn = sort.substring(1);
      sortOrder = "desc";
    }
    return { sortColumn, sortOrder };
  }

  let { sortColumn, sortOrder } = sortToSortColumnAndOrder(sort);
  let playerMapDataSorted = playerMapData.sort((a, b) => {
    let valueA = a[sortColumn];
    let valueB = b[sortColumn];

    if (sortColumn === "name") {
      valueA = a["campaign_name"] ?? a["name"];
      valueB = b["campaign_name"] ?? b["name"];

      if (valueA === valueB) {
        valueA = a["name"];
        valueB = b["name"];
      }
      if (valueA === valueB) {
        valueA = a["side_name"];
        valueB = b["side_name"];
      }

      valueA = valueA ? (valueA + "").toLowerCase() : valueA;
      valueB = valueB ? (valueB + "").toLowerCase() : valueB;
    }

    if ((sortOrder === "asc" && valueA > valueB) || (sortOrder === "desc" && valueA < valueB)) {
      return 1;
    }
    if ((sortOrder === "asc" && valueA < valueB) || (sortOrder === "desc" && valueA > valueB)) {
      return -1;
    }

    if (sortColumn === "difficulty") {
      valueA = playerMapRanks.find((rank) => rank.id === a.id)["difficulty_rank"];
      valueB = playerMapRanks.find((rank) => rank.id === b.id)["difficulty_rank"];

      if ((sortOrder === "asc" && valueA > valueB) || (sortOrder === "desc" && valueA < valueB)) {
        return -1;
      }
      if ((sortOrder === "asc" && valueA < valueB) || (sortOrder === "desc" && valueA > valueB)) {
        return 1;
      }
    }
    if (sortColumn === "enjoyment") {
      valueA = playerMapRanks.find((rank) => rank.id === a.id)["enjoyment_rank"];
      valueB = playerMapRanks.find((rank) => rank.id === b.id)["enjoyment_rank"];

      if ((sortOrder === "asc" && valueA > valueB) || (sortOrder === "desc" && valueA < valueB)) {
        return -1;
      }
      if ((sortOrder === "asc" && valueA < valueB) || (sortOrder === "desc" && valueA > valueB)) {
        return 1;
      }
    }

    return 0;
  });

  return (
    <Container fluid>
      <Row>
        <Col>
          <Table bordered striped hover id="player-data-table">
            <thead>
              <tr>
                <th style={{ width: "2%" }} className="text-center">
                  #
                </th>
                <th style={{ width: "15%" }}>Map</th>
                <th style={{ width: "1%" }} className="text-center">
                  Cleared <ClearIcon />
                </th>
                <th style={{ width: "1%" }} className="text-center">
                  Clear <TimeIcon />
                </th>
                <th style={{ width: "1%" }} className="text-center">
                  Clear <DeathsIcon />
                </th>
                <th style={{ width: "13%" }} className="text-center">
                  Difficulty
                </th>
                <th style={{ width: "13%" }} className="text-center">
                  Enjoyment
                </th>
                <th style={{ width: "1%" }} className="text-center">
                  Golden <GoldberryIcon />
                </th>
                {/* <th style={{ width: "1%" }} className="text-center">
                  Golden <TimeIcon />
                </th>
                <th style={{ width: "1%" }} className="text-center">
                  Golden <DeathsIcon />
                </th>
                <th style={{ width: "1%" }} className="text-center">
                  Golden Tier
                </th> */}
                <th>
                  Notes <NoteIcon />
                </th>
                {isOwnPage ? (
                  <th style={{ width: "5%" }} className="text-center">
                    Actions <ActionIcon />
                  </th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {playerMapDataSorted.map((mapData, index) => {
                let mapRanks = playerMapRanks.find((mapRank) => mapRank.id === mapData.id);
                let mapGoldenRuns = playerAllGoldenRuns.filter(
                  (goldenRun) => goldenRun.map_id === mapData.id
                );
                return (
                  <PlayerDataRow
                    key={mapData.id}
                    index={index}
                    playerName={playerName}
                    mapData={mapData}
                    mapRanks={mapRanks}
                    mapGoldenRuns={mapGoldenRuns}
                    isOwnPage={isOwnPage}
                    deleteMapModalRef={deleteMapModalRef}
                    editMapModalRef={editMapModalRef}
                  />
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
function PlayerDataRow({
  index,
  playerName,
  mapData,
  mapRanks,
  mapGoldenRuns,
  isOwnPage,
  deleteMapModalRef,
  editMapModalRef,
}) {
  let clearTime = formatClearDuration(mapData.clear_duration);
  // let goldenTime = formatClearDuration(mapData.golden_duration);

  let mapName = getMapName(mapData);

  if (mapData.id === 49 || mapGoldenRuns === undefined || mapGoldenRuns === null) {
    console.log("Golden runs (map id: " + mapData.id + "): ", mapGoldenRuns);
  }

  // let goldenTier = mapData.golden_one_tier ?? "";
  // let goldenTierColor = mapData.golden_one_tier_color ?? "rgba(0, 0, 0, 0)";

  return (
    <tr>
      <td className="text-center">{index + 1}</td>
      <td>
        <Stack direction="vertical" gap={0}>
          <Stack direction="horizontal" gap={1}>
            <Link to={"/player/" + playerName + "/map/" + mapData.id}>{mapName}</Link>
            {mapData.url !== "" && mapData.url != null ? (
              <a href={mapData.url} target="_blank" rel="noreferrer" style={{ color: "lightgray" }}>
                <ExternalLinkIcon />
              </a>
            ) : null}
          </Stack>
          <Stack direction="horizontal" gap={1}>
            <Link
              to={"/player/" + playerName + "/campaign/" + mapData.campaign_id}
              className="text-secondary"
              style={{ textDecoration: "none" }}
            >
              {mapData.campaign_name}
            </Link>
            {mapData.campaign_url !== "" && mapData.campaign_url != null ? (
              <a href={mapData.campaign_url} target="_blank" rel="noreferrer" style={{ color: "lightgray" }}>
                <ExternalLinkIcon />
              </a>
            ) : null}
          </Stack>
        </Stack>
      </td>
      <td className="text-center">
        <Stack direction="horizontal" gap={1} className="justify-content-center">
          <input type="checkbox" checked={mapData.cleared} readOnly />
          {mapData.cleared && mapData.proof_url !== "" && mapData.proof_url !== null ? (
            <a href={mapData.proof_url} target="_blank" rel="noreferrer" style={{ color: "lightgray" }}>
              <ExternalLinkIcon />
            </a>
          ) : null}
        </Stack>
      </td>
      <td className="text-center">{clearTime}</td>
      <td className="text-center">{numberWithCommas(mapData.clear_deaths)}</td>
      <td className="text-center">
        {mapData.difficulty !== null ? (
          <DifficultyDisplay difficulty={mapData.difficulty} rank={mapRanks?.difficulty_rank} />
        ) : null}
      </td>
      <td className="text-center">
        {mapData.enjoyment !== null ? (
          <EnjoymentDisplay enjoyment={mapData.enjoyment} rank={mapRanks?.enjoyment_rank} />
        ) : null}
      </td>

      <td className="text-center">
        <GoldenRunsDropdown goldens={mapGoldenRuns} />
      </td>
      {/* <td className="text-center">
        <Stack direction="horizontal" gap={1} className="justify-content-center">
          <input
            type="checkbox"
            checked={mapData.golden_cleared === null ? 0 : mapData.golden_cleared}
            readOnly
          />
          {mapData.golden_cleared && mapData.golden_one_proof !== "" && mapData.golden_one_proof != null ? (
            <a
              href={mapData.golden_one_proof}
              target="_blank"
              rel="noreferrer"
              style={{ color: "lightgray" }}
            >
              <ExternalLinkIcon />
            </a>
          ) : null}
        </Stack>
      </td>
      <td className="text-center">{goldenTime}</td>
      <td className="text-center">{numberWithCommas(mapData.golden_deaths)}</td>
      <td className="text-center" style={{ backgroundColor: goldenTierColor }}>
        {goldenTier}
      </td> */}

      <td>{mapData.notes}</td>
      {isOwnPage ? (
        <td className="text-center actions-cell">
          <Stack direction="horizontal" gap={1}>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => {
                editMapModalRef.current(mapData.id);
              }}
            >
              <EditIcon style={{ fontSize: "110%" }} />
            </Button>
            <Button
              variant="outline-danger"
              className="no-border"
              size="sm"
              onClick={() => deleteMapModalRef.current(mapData)}
            >
              <DeleteIcon style={{ fontSize: "110%" }} />
            </Button>
          </Stack>
        </td>
      ) : null}
    </tr>
  );
}
